import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, update, set, get, remove} from "firebase/database";
import '../styles/GeneralDrilldownTable.css'
import LineGraph from './LineGraph';
import {getUniqueValues, getShade, arrToJSON, formatDollars, getFirebaseData, expandDataWithYears} from '../utils'

// This function creates an expandable hierarchical table and expects the following from the data:
//
//   1) It is an array of JSON objects of the form [{'rowKey': value, 'columnKey': value, 'valueLabel': value, 'PARENT': value, 'LEVEL': value}]
//   2) The values can be numerical or strings for the rowKey, columnKey, and valueLabel. PARENT is expected to be a string...
//          ...corresponding to the parent object with rowKey === PARENT. LEVEL is an integer describing the level in the hierarchy...
//          ...of the LABEL. For example, if the rowKey's PARENT === 'Self', its level should be 0 indicating it is the top-most...
//          ...level in the hierarchy. The children of that rowKey should have level 1, the children of that level 2, etc.
//   3) Sometimes we only want to show a subset of the data in the table. For that, we can apply tableDataFilters...
//          ... that way we preserve all of the data for other functionality like graphs.
//   4) The table values may need formatted for the table but not for the graph. This is common in the case of...
//          ... dollar amounts. For this tableDataFormats can be specified like {'TOTAL_ALL_FUNDS': 'dollars'}

const GeneralDrilldownTable = ({data, tableDataFilters, tableDataFormats, rowKey, columnKey, valueLabel, rowLevelFunctions, fiscalYear, version, deseCodeMap, title, graphOptions}) => {
  
    const graphAvailable = rowLevelFunctions['graph'];
    const editAvailable = rowLevelFunctions['edit'];

    const [graphData, setGraphData] = useState(data);
    const [tableData, setTableData] = useState([]);

    // By default we expand only rows where PARENT == 'Self'
    const filterFunction = new Function('item', `return ${tableDataFilters};`);
    let tableData_raw = tableDataFilters? data.filter(filterFunction) : graphData;
    const defaultExpandedRows = getUniqueValues(tableData_raw.filter(item => item.PARENT==='Self'), rowKey);
    const availableObjectDescriptions = Object.values(deseCodeMap['object']).map(item=>item.DETAIL_DESCRIPTION);

    const [expandedRows, setExpandedRows] = useState(defaultExpandedRows);
    const [areChildrenExpanded, setAreChildrenExpanded] = useState(arrToJSON(defaultExpandedRows))
    const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);
    const [selectedTableLabel, setSelectedTableLabel] = useState(null);
    const [viewMode, setViewMode] = useState('graph');
    const [editedValues, setEditedValues] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalRow, setModalRow] = useState({});
    const [itemExists, setItemExists] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [objectSuggestions, setObjectSuggestions] = useState([]);
    const [searchDescription, setSearchDescription] = useState('');
    const [searchObject, setSearchObject] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [lineItemTableData, setLineItemTableData] = useState([]);

    useEffect(() => {
      setGraphData(data);
    }, [data])

    useEffect(() => {
      // filter the tableData if the user has specified tableDataFilters
      const filterFunction = new Function('item', `return ${tableDataFilters};`);
      let tableData_raw = tableDataFilters? graphData.filter(filterFunction) : graphData;
      
      // format the table data if the user has specified tableDataFormats
      let formattedTableData;
      if(tableDataFormats){
        switch(tableDataFormats[valueLabel]) {
          case 'dollars':
            formattedTableData = tableData_raw.map(item => ({
              ...item,
              [valueLabel]: formatDollars(item[valueLabel]),
            }));
          break;
        }
      }

      setTableData(formattedTableData)

    }, [graphData])

    ////////////////////////// FUNCTIONS /////////////////////////////

    const handleShowGraphClick = (label) => {
      setSelectedGraphLabel((prevLabel) => (prevLabel === label ? null : label));
    };

    const handleShowTableClick = (label) => {
      setSelectedTableLabel((prevLabel) => (prevLabel === label ? null : label));
    };

    // Handle view mode toggle (graph or raw data)
    const toggleViewMode = () => {
      setViewMode(viewMode === 'graph' ? 'spreadsheet' : 'graph');
    };


    const handleAddDescription = (description) => {
      
      const objectCode = Object.entries(deseCodeMap['object'])
        .find(([key, value]) => value.DETAIL_DESCRIPTION === description)?.[0];
      
      setModalRow(prev => ({
        ...prev,
        DESCRIPTION: description,
        object: objectCode || "", // Set OBJECT if found, otherwise empty
      }));
      
      setSearchDescription(description);
      setSearchObject(objectCode)
      setSuggestions([]);
      setEditMode(false);
    };
    
    const handleAddObject = (code) => {
      console.log('adding code: ', code,  deseCodeMap['object'][code].DETAIL_DESCRIPTION)
      setModalRow(prev => ({
        ...prev,
        object: code,
        DESCRIPTION: deseCodeMap['object'][code].DETAIL_DESCRIPTION || "", // Set DESCRIPTION from deseCodeMap
      }));
      
      setSearchObject(code);
      setSearchDescription(deseCodeMap['object'][code].DETAIL_DESCRIPTION);
      setObjectSuggestions([]);
    };
  

    const handleFundChange = (e) => {
      setModalRow(prev => ({
        ...prev, fund: e.target.value}))
    }

    
    const handleSearchChange = (type, e) => {
      const value = e.target.value.toLowerCase();
      
      if (type === "description") {
        setSearchDescription(value);
        setSuggestions(
          value ? availableObjectDescriptions.filter(d => d.toLowerCase().includes(value)) : []
        );
      }
    
      if (type === "code") {
        console.log('!! value: ', value)
        setSearchObject(value);
        setObjectSuggestions(
          value ? Object.keys(deseCodeMap['object']).filter(code => code.startsWith(value)) : []
        );
      }
    };

    const handleEditRow = (row) => {
      setEditMode(true);
      setModalRow(row);
      setModalOpen(true);
    }

    // Function to handle row deletion
    const handleDeleteRow = (row) => {
      console.log('user wants to delete row: ', row)

      const keyTotalRef = ref(
        database,
        `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${row['accountCode']}/budget/${version}`
      );
      
      remove(keyTotalRef)
        .then(() => console.log('deleted from firebase'))
        .catch(() => console.log('update error'));

      // Update the state or data source to remove the row
      const updatedData = graphData.filter(obj => obj !== row);
      
      setGraphData(updatedData); // Assuming you have a state setter `setData`
    };

    // Handler for input blur (when user clicks out)
    async function handleInputBlur(row, col) {
      
      // first save the new value of the object that was edited
      if(Object.keys(editedValues).length>0){
        const key = `${row['PARENT'].substring(0,4)}-${row['LABEL']}-${col}`
        
        const functionCode = row['PARENT'].substring(0,4);
        const location = row['LABEL'].split('-')[0];
        const objectCode = row['LABEL'].split('-')[1];
        const source = row['LABEL'].split('-')[2];
        const project = row['LABEL'].split('-')[3];

        // check if editing sub salaries to calculate FICA and Medicare
        if(['6121', '6153'].includes(objectCode)){
          const keyTotalRef = ref(
            database,
            `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${functionCode}/${row['LABEL']}/certified`
          );
        
          update(keyTotalRef, { [col]: parseFloat(editedValues[key]) || 0 })
            .then(() => console.log('update complete'))
            .catch(() => console.log('update error'));

          const subFICA = parseFloat(editedValues[key])*0.062;
          const subMedicare = parseFloat(editedValues[key])*0.0145;
          
          const ficaAcctCode = `${location}-6231-${source}-${project}`;
          const subFICAref = ref(
            database,
            `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${functionCode}/${ficaAcctCode}/certified`
          );
          update(subFICAref, { ['SPECIAL_REVENUE']: subFICA || 0 })
          .then(() => console.log('sub FICA update complete'))
          .catch(() => console.log('update error'));
          setEditedValues(prev => ({
            ...prev,
            [`${functionCode}-${ficaAcctCode}-SPECIAL_REVENUE`]: formatDollars(parseFloat(subFICA)) || 0,
            [`${functionCode}-${ficaAcctCode}-TOTAL_ALL_FUNDS`]: formatDollars(parseFloat(subFICA)) || 0
          }));
          
          const medicareAcctCode = `${location}-6232-${source}-${project}`;
          const subMedicareref = ref(
            database,
            `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${functionCode}/${medicareAcctCode}/certified`
          );
          update(subMedicareref, { ['SPECIAL_REVENUE_SUBS']: subMedicare || 0 })
          .then(() => console.log('Sub Medicare update complete'))
          .catch(() => console.log('update error'));

          const certifiedMedicareref = ref(
            database,
            `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${functionCode}/${medicareAcctCode}/certified/SPECIAL_REVENUE_CERTIFIED`
          );
          get(certifiedMedicareref)
          .then(result => {
            let medicareSum;
            if(result.val()){
              medicareSum = result.val()+subMedicare;
            } else {
              medicareSum = subMedicare;
            }
            update(subMedicareref, { ['SPECIAL_REVENUE']: medicareSum || 0 })
            .then(() => console.log('Sub Medicare update complete'))
            .catch(() => console.log('update error'));
            setEditedValues(prev => ({
              ...prev,
              [`${functionCode}-${medicareAcctCode}-SPECIAL_REVENUE`]: formatDollars(parseFloat(medicareSum)) || 0,
              [`${functionCode}-${medicareAcctCode}-TOTAL_ALL_FUNDS`]: formatDollars(parseFloat(medicareSum)) || 0
            }));
            
            console.log('editedValues: ', editedValues)
          })
          .catch(() => console.log('update error'));
        } else {
          const keyTotalRef = ref(
            database,
            `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${row['PARENT'].substring(0,4)}/${row['LABEL']}/other`
          );
        
          update(keyTotalRef, { [col]: parseFloat(editedValues[key]) || 0 })
            .then(() => console.log('update complete'))
            .catch(() => console.log('update error'));
        }
      }
    };

    const showExpenseModal = (row) => (
      <div className="add-row-modal">
        <div className="add-row-modal-content">
          <h4>Edit Line Item</h4>
          <label>Description:
            {editMode? (<input
              type="text"
              value={row.DESCRIPTION}
              readonly
            />) : (<input
              type="text"
              value={searchDescription || row.DESCRIPTION || ""}
              onChange={(e) => handleSearchChange("description", e)}
              placeholder="Search"
            />)}
            {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((name, index) => (
                <li key={index} onClick={() => handleAddDescription(name)}>
                  {name}
                </li>
              ))}
            </ul>
          )}
          </label>
          <label>
            Building:
            <input
              type="text"
              value={row.location}
              onChange={(e) => setModalRow(prev => ({
                ...prev, LOCATION: e.target.value}))}
            />
          </label>
          <label>Object code:
            {editMode ? (
              <input type="text" value={row.object} readOnly />
            ) : (
              <input
                type="text"
                value={searchObject || row.object  || ""}
                onChange={(e) => handleSearchChange("code", e)}
                placeholder="Search by code"
              />
            )}
            {objectSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {objectSuggestions.map((code, index) => (
                  <li key={index} onClick={() => handleAddObject(code)}>
                    {code} - {deseCodeMap[code]}
                  </li>
                ))}
              </ul>
            )}
          </label>
          <label>Source:
            <input
              type="number"
              value={row.source}
              onChange={(e) => setModalRow(prev => ({
                ...prev, SOURCE: e.target.value}))}
            />
          </label>
          <label>
            Project:
            <input
              type="text"
              value={row.project}
              maxLength="5"
              onChange={(e) => setModalRow(prev => ({
                ...prev, project: e.target.value}))}
            />
          </label>
          <label>
            Fund:
            <select id="fund-select" value={row.fund} onChange={handleFundChange}>
              <option value="" disabled>Select Fund</option>
              {['10','20','30','40'].map((option, index) => (
                  <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </label>
          <label>
            Amount:
            <input
              type="text"
              value={row.budget}
              onChange={(e) => setModalRow(prev => ({
                ...prev, budget: parseFloat(e.target.value)}))}
            />
          </label>
          
          {itemExists && !editMode && (
            <font color='#FF0000'> Error: {itemExists} already exists. Edit existing item instead.</font>
          )}
          
          <div className="add-row-modal-actions">
            <button onClick={() => {
              // when user clicks save, save to firebase
              
              let label = `${row.fund}-${row.function}-${row.object}-${row.location}-${row.source}-${row.project}`
             
              const keyTotalRef = ref(
                database,
                `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${label}/budget/${version}`
              );

              console.log('saving row: ', row, `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${label}/budget/${version}`)

              // Check if label already exists. If it does, print error to user, otherwise create the record.
              get(keyTotalRef).then(result => {
                if(result.val() && !editMode){
                  console.log('item already exists')
                  setItemExists(label);
                } else {
                  setItemExists(false);
                  // only want to save a select number of columns to firebase
                  let row_to_save =  {budget: row.budget,
                    function: row.function,
                    fund: row.fund,
                    location: row.location,
                    object: row.object,
                    program: row.program,
                    project: row.project,
                    source: row.source,
                    editable: true
                    }

                    set(keyTotalRef, row_to_save)
                    .then(() => {
                    console.log('saved to firebase')
                    })
                    .catch(() => console.log('update error'));

                    const updatedRow = {
                      ...row,
                      LABEL: `${row.object}-${row.location}-${row.source}-${row.project}`,
                      accountCode: `${row.fund}-${row.function}-${row.object}-${row.location}-${row.source}-${row.project}`,
                      TOTAL_ALL_FUNDS: parseFloat(row.budget),
                      GENERAL_FUND: 0,
                      SPECIAL_REVENUE: 0,
                      DEBT_SERVICE: 0,
                      CAPITAL_PROJECTS: 0
                    };

                    switch(row.fund){
                      case '10':
                        updatedRow.GENERAL_FUND+=parseFloat(row.budget);
                        break;
                      case '20':
                        updatedRow.SPECIAL_REVENUE+=parseFloat(row.budget);
                        break;
                      case '30':
                        updatedRow.DEBT_SERVICE+=parseFloat(row.budget);
                        break;
                      case '40':
                        updatedRow.CAPITAL_PROJECTS+=parseFloat(row.budget);
                        break;
                    }
                    // Find the index of the existing entry with the same groupKey
                    const updatedData = [...graphData];
                    const index = updatedData.findIndex(entry => entry.groupKey === row.groupKey);
                    console.log('index: ', index)
                    if (index !== -1) {
                      // Replace the existing entry
                      updatedData[index] = updatedRow;
                    } else {
                      // Append the new entry
                      updatedData.push(updatedRow);
                    }
                    console.log('updatedData: ', updatedData)
                    setGraphData(updatedData)

                    setEditMode(false);
                    setModalOpen(false);
                }
              })
              }}>Save</button>
            <button onClick={() => {setModalOpen(false); setEditMode(false); setSearchDescription(''); setSearchObject(''); setSuggestions([]); setObjectSuggestions([])}}>Cancel</button>
          </div>
        </div>
      </div>
    );

    const showRevenueModal = (row) => (
      <div className="add-row-modal">
        <div className="add-row-modal-content">
          <h4>Edit Line Item</h4>
          <label>Description:
            <input
              type="text"
              value={row.DESCRIPTION}
              onChange={(e) => setModalRow(prev => ({
                ...prev, DESCRIPTION: e.target.value}))}
            />
          </label>
          <label>
            Building:
            <input
              type="text"
              value={row.location}
              onChange={(e) => setModalRow(prev => ({
                ...prev, LOCATION: e.target.value}))}
            />
          </label>
          <label>
            Project:
            <input
              type="text"
              value={row.project}
              maxLength="5"
              onChange={(e) => setModalRow(prev => ({
                ...prev, project: e.target.value}))}
            />
          </label>
          <label>
            Fund:
            <select id="fund-select" value={row.fund} onChange={handleFundChange}>
              <option value="" disabled>Select Fund</option>
              {['10','20','30','40'].map((option, index) => (
                  <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </label>
          <label>
            Amount:
            <input
              type="text"
              value={row.budget}
              onChange={(e) => setModalRow(prev => ({
                ...prev, budget: parseFloat(e.target.value)}))}
            />
          </label>
          
          {itemExists && !editMode && (
            <font color='#FF0000'> Error: {itemExists} already exists. Edit existing item instead.</font>
          )}
          
          <div className="add-row-modal-actions">
            <button onClick={() => {
              // when user clicks save, save to firebase
              
              let label = `${row.fund}-${row.function}-${row.location}-${row.project}`
             
              const keyTotalRef = ref(
                database,
                `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${label}/budget/${version}`
              );

              console.log('saving row: ', row, `budget/${row['ID']}/${row['YEAR']}/${row['GROUP']}/${label}/budget/${version}`)

              // Check if label already exists. If it does, print error to user, otherwise create the record.
              get(keyTotalRef).then(result => {
                if(result.val() && !editMode){
                  console.log('item already exists')
                  setItemExists(label);
                } else {
                  setItemExists(false);
                  // only want to save a select number of columns to firebase
                  let row_to_save =  {budget: row.budget,
                    function: row.function,
                    fund: row.fund,
                    location: row.location,
                    program: row.program,
                    project: row.project,
                    editable: true
                    }

                    set(keyTotalRef, row_to_save)
                    .then(() => {
                    console.log('saved to firebase')
                    })
                    .catch(() => console.log('update error'));

                    const updatedRow = {
                      ...row,
                      LABEL: `${row.location}-${row.project}`,
                      accountCode: `${row.fund}-${row.function}-${row.location}-${row.project}`,
                      TOTAL_ALL_FUNDS: parseFloat(row.budget),
                      GENERAL_FUND: 0,
                      SPECIAL_REVENUE: 0,
                      DEBT_SERVICE: 0,
                      CAPITAL_PROJECTS: 0
                    };

                    switch(row.fund){
                      case '10':
                        updatedRow.GENERAL_FUND+=parseFloat(row.budget);
                        break;
                      case '20':
                        updatedRow.SPECIAL_REVENUE+=parseFloat(row.budget);
                        break;
                      case '30':
                        updatedRow.DEBT_SERVICE+=parseFloat(row.budget);
                        break;
                      case '40':
                        updatedRow.CAPITAL_PROJECTS+=parseFloat(row.budget);
                        break;
                    }
                    // Find the index of the existing entry with the same groupKey
                    const updatedData = [...graphData];
                    const index = updatedData.findIndex(entry => entry.groupKey === row.groupKey);
                    console.log('index: ', index)
                    if (index !== -1) {
                      // Replace the existing entry
                      updatedData[index] = updatedRow;
                    } else {
                      // Append the new entry
                      updatedData.push(updatedRow);
                    }
                    console.log('updatedData: ', updatedData)
                    setGraphData(updatedData)

                    setEditMode(false);
                    setModalOpen(false);
                }
              })
              }}>Save</button>
            <button onClick={() => {setModalOpen(false); setEditMode(false); setSearchDescription(''); setSuggestions([])}}>Cancel</button>
          </div>
        </div>
      </div>
    );

    const showTable = (data, columns, formatted_column_names, year) => (
      <React.Fragment>
      <tr>
        <td colSpan={tableHeaders.length} style={{ padding: '20px', position: 'relative' }}>
        <div className="line-item-table-container">
          <table className="line-item-table"
          >
            <thead>
              <tr>
                {formatted_column_names.map((col) => (
                  <th key={col}>
                    {col}
                  </th>
                ))}
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.filter((item) => (item.YEAR === year)&&(item.budget!=0)).map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                <tr>
                  
                  {columns.map((col) => (
                    <td
                      key={col}
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      
                      {typeof row[col] === "number" ? formatDollars(row[col]) : row[col]}
                        {col==='LABEL' &&
                        <span
                          role="img"
                          aria-label="Show Graph"
                          style={{ cursor: 'pointer', marginLeft: '3px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowGraphClick(row[col]);
                          }}
                        >
                         📈
                        </span>
                        }
                    </td>
                   
                  ))}
                  
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      textAlign: "center",
                    }}
                  >
                    {row.editable && (<button
                      onClick={() => {
                        handleEditRow(row);
                      }}
                      style={{
                        backgroundColor: "#6695ED",
                        width: "60px",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px",
                        marginBottom: "2px",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </button>)}
                    {row.editable && (<button
                      onClick={() => {
                        if (window.confirm("Are you sure you want to delete this row?")) {
                          handleDeleteRow(row);
                        }
                      }}
                      style={{
                        backgroundColor: "#ee6b6e",
                        width: "60px",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        cursor: "pointer",
                        margin: "2px"
                      }}
                    >
                      Delete
                    </button>)}
                    
                  </td>
                  
                </tr>
                {/* Render graph row conditionally */}
                {selectedGraphLabel === row[rowKey] && showGraph(graphData.filter(item => item[rowKey] === row[rowKey]), row[rowKey], 8)}
                </React.Fragment>
              ))}
              
              <tr>
                <td colSpan={8} style={{padding: "10px"}}>
                  <span
                    role="img"
                    aria-label="Show Graph"
                    style= {{cursor: "pointer"}}
                    onClick={async (e) => {
                      e.stopPropagation();
                      let template = data[data.length-1];
                      console.log('template: ', template, `budget/${template['ID']}/${template['YEAR']}/${template['GROUP']}/${template['accountCode']}/budget/${version}`)
                      const getRef = ref(
                        database,
                        `budget/${template['ID']}/${template['YEAR']}/${template['GROUP']}/${template['accountCode']}/budget/${version}`
                      );
                      let templateDataFB = await getFirebaseData(getRef);
                      
                      templateDataFB.PARENT = template['PARENT'];
                      templateDataFB.GROUP = template['GROUP'];
                      templateDataFB.GGGPARENT = template['GGGPARENT'];
                      templateDataFB.GGPARENT = template['GGPARENT'];
                      templateDataFB.GPARENT = template['GPARENT'];
                      templateDataFB.ID = template['ID'];
                      templateDataFB.YEAR = fiscalYear;
                      templateDataFB.DISTRICT_NAME = template['DISTRICT_NAME'];
                      templateDataFB.project = '00000';
                      templateDataFB.object = '';
                      templateDataFB.budget = '';
                      templateDataFB.fund = '10';
                      templateDataFB.version = version;
                      templateDataFB.editable = true;

                      setModalRow(templateDataFB);
                      setModalOpen(true);
                    }}
                  >
                    ➕ Add row
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </td>
      </tr>
      </React.Fragment>
    );
    
    
    
    const showGraph = (graphData, label, span) => (
      <tr>
        <td colSpan={span} style={{ padding: '20px', position: 'relative' }}>
          <div style={{ width: '100%', height: '400px', overflow: 'hidden', position: 'relative' }}>
            {viewMode === 'graph' ? (
              <div style={{ width: '100%', height: '90%' }}>
                <LineGraph
                  data={graphData}
                  yAxis="TOTAL_ALL_FUNDS"
                  xAxis="YEAR"
                  groupLabel="DISTRICT_NAME"
                  yAxisLabel="Dollars ($)"
                  showLegend={graphOptions['showLegend']}
                  graphTitle={['expense', 'revenue'].includes(label)? String(label[0]).toUpperCase() + String(label).slice(1) : label}
                />
              </div>
            ) : (
              <div>
                <table style={{ backgroundColor: '#eee', border: '10px solid black', margin: '10px auto' }}>
                  <thead>
                    <tr key={label}>
                      <th style={{ textAlign: 'center', padding: '10px' }}>Year</th>
                      {tableHeaders.filter(label => label!='').map((column) => (
                        <th key={column} style={{ textAlign: 'center', padding: '10px' }}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  {/* Make body of the table that displays the graph data */}
                  <tbody>
                    {getUniqueValues(graphData, 'YEAR').map((row_item, row_index) => (
                      <tr key={row_index}>
                        <td key={'year_label'} align="center">
                          {row_item}
                        </td>
                        {graphData.filter(rows => rows['YEAR'].includes(row_item)).map((row_item, index) => (
                          <td key={index}
                              align="center">
                            {formatDollars(row_item[valueLabel])}
                          </td>
                          ))
                        } 
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <button onClick={toggleViewMode} style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
            {viewMode === 'graph' ? 'View Raw Data' : 'View Graph'}
          </button>
        </td>
      </tr>      
    )
  
    const toggleExpand = (label) => {
      const children = getUniqueValues(tableData.filter(item => item.PARENT === label), rowKey);
      const childrenG2 = getUniqueValues(tableData.filter(item => children.includes(item.PARENT)), rowKey);
      const childrenG3 = getUniqueValues(tableData.filter(item => childrenG2.includes(item.PARENT)), rowKey);
      // if the children are not already in the list of expandedRows, add them, otherwise remove them
      setExpandedRows((prev) => {
        const parentIndex = prev.indexOf(label);
        if (!children.every(element => prev.includes(element))) {
          // Insert children immediately after the parent
          const updatedRows = [...prev];
          updatedRows.splice(parentIndex + 1, 0, ...children);

          // update this JSON object to note that this element's children are expanded
          setAreChildrenExpanded((prev) => {
            const prevCopy = {...prev};
            prevCopy[label] = true;
            return prevCopy
          })
          
          return updatedRows;

        } else {
          // update this JSON object to note that this element's children are no longer expanded
          setAreChildrenExpanded((prev) => {
              const prevCopy = {...prev};
              prevCopy[label] = false;
              children.forEach(item => prevCopy[item] = false);
              childrenG2.forEach(item => prevCopy[item] = false);
              return prevCopy
            })


          let currentExpandedRows = prev.filter(row => (!children.includes(row))&&(!childrenG2.includes(row))&&(!childrenG3.includes(row)));
          // Remove children from expandedRows
          return currentExpandedRows;
        }
      });
    };

    function renderRow(tableData, graphData, label, columnNames, row_index){
        let rowData = tableData.filter(data_item => (data_item[rowKey] === label)&(columnNames.includes(data_item[columnKey])));
        const isExpandable = tableData.filter(entry => entry.PARENT === label).length > 0; // if item has children, it's expandable
        const isExpanded = areChildrenExpanded[label];

        // If there are missing values, fill in with zero
        if(getUniqueValues(rowData,columnKey).length!=columnNames.length-1){
          rowData = expandDataWithYears(rowData, columnNames.slice(1,columnNames.length), "YEAR", "TOTAL_ALL_FUNDS")
        }

        return (
          <React.Fragment key={row_index}>
            <tr>
            {/* First column is a text label that may or may not have an expansion arrow */}
            <td
                style={{
                  cursor: 'pointer',
                  backgroundColor: getShade(rowData[0].LEVEL),
                  paddingLeft: `${rowData[0].LEVEL * 20}px`,
                  maxWidth: '200px'
                }}>
            {isExpandable ? ( // if the label is expandable, put the arrow
              <span onClick={() => toggleExpand(label)}
                    style={{display: 'inline-block',
                            transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.15s',
                            marginRight: '5px',
                            marginLeft: '5px'}}> ▶ </span>
            ) : ( // otherwise, put a dash
              <span> - </span>
            )}
            <span onClick={() => toggleExpand(label)}>{['expense', 'revenue'].includes(label)? String(label[0]).toUpperCase() + String(label).slice(1) : label}</span>
            {graphAvailable && (<span
                  role="img"
                  aria-label="Show Graph"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowGraphClick(label);
                  }}
                >
                  📈
            </span>)}
            {editAvailable && !isExpandable && (<span
                  role="img"
                  aria-label="Show Table"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowTableClick(label);
                  }}
                >
                  ✎
            </span>)}
            </td>
            
            {/* All other columns are data */}
            {rowData.map((item, index)=> (
                <td key={index}
                    align="center"
                    style={{
                      backgroundColor: getShade(rowData[0].LEVEL)
                    }}>
                  {item[valueLabel]}
                </td>
            ))}
            </tr>
            {/* Show the graph if the graph button has been clicked */}
            {selectedGraphLabel === label && showGraph(graphData.filter(item => item[rowKey] === label), label, 5)}
            {isModalOpen && modalRow.GROUP === 'expense' && showExpenseModal(modalRow)}
            {isModalOpen && modalRow.GROUP === 'revenue' && showRevenueModal(modalRow)}
            {selectedTableLabel === label && showTable(graphData.filter(item => (item.PARENT === label) && (item.version === version)).sort((a, b) => Number(a.object) - Number(b.object)), 
                                                       ["LABEL", "DESCRIPTION", "GENERAL_FUND", "SPECIAL_REVENUE", "DEBT_SERVICE", "CAPITAL_PROJECTS", "TOTAL_ALL_FUNDS"],
                                                       ["Label", "Description", "General Fund", "Special Revenue", "Debt Service", "Capital Projects", "Total All Funds"],
                                                       fiscalYear)}
            </React.Fragment>
        )
    }
    ///////////////////////////////////////////////////////////////

    // get the labels for the columns of the table
    const tableHeaders = getUniqueValues(tableData, columnKey);

    tableHeaders.unshift('') // Add header for the first column
    
    return (
      <div className='high-level-table-container'>
        <h2>{title}</h2>
          <table className="high-level-table">
            <thead>
              {/* column labels */}
              <tr>
                  {tableHeaders.map((header, index) => (
                      <th key={index}>{header}</th>
                  ))}
              </tr>
            </thead>
            {/* table body */}
            <tbody>
              {/* Check if tableData is populated */}
              {tableData && tableData.length > 0 ? (
                expandedRows.map((label, index) =>
                  renderRow(tableData, graphData, label, tableHeaders, index)
                )
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length}>Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
    </div>
    )
};

export default GeneralDrilldownTable;