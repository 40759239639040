import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";

const formatDollars = (num) => `$${Math.round(num).toLocaleString('en-US')}`;

const OBJECT_CODES = {
  6151:	"Classified Salaries-Regular",
  6152:	"Instructional Aide Salaries",
  6153:	"Classified Substitute Salaries",
  6161:	"Classified Salaries - Part-Time",
  6171:	"Classified Employees Unused Leave and/or Severance Pay"
};

const ClassifiedEmployeeTable = ({
  districtCode,
  fiscalYear,
  version,
  functionCode,
  buildingCode,
  ppoMonthly,
  lifeMonthly,
  medicareRate,
  socialSecurityRate,
  peers,
  onDataUpdate
}) => {
  const [Rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [objectCode, setObjectCode] = useState("");
  const [fund, setFund] = useState("");
  const [availableSalarySchedules, setAvailableSalarySchedules] = useState([]);

  const salarySchedulePath = `salarySchedules/${districtCode}/${fiscalYear}/${version}/classified`;
  const staffPath = `staff/${districtCode}/${fiscalYear}/${version}`;

  const determineSource = (func_code) => {
    let default_source
    if(['1281'].includes(func_code)){
      default_source = 3;
    } else {
    default_source = 1;
    }
    return default_source
  }

  const determineFundCode = (fund) => {
    let fund_code = 10;
      switch(fund){
        case 'GENERAL_FUND':
          fund_code = 10;
          break;
        case 'SPECIAL_REVENUE':
          fund_code = 20;
          break;
        case 'DEBT_SERVICE_FUND':
          fund_code = 30;
          break;
        case 'CAPITAL_FUND':
          fund_code = 40;
          break;
      }
      return fund_code
  }

  const handleDeleteEmployee = async (row) => {
    const updatedRows = Rows.filter((r) => r.id !== row.id);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (!confirmDelete) return;

    // Remove the specific row from Firebase
    const rowRef = ref(
      database,
      `${staffPath}/${functionCode}/${buildingCode}/classified/staff/${row.id}`
    );
    await set(rowRef, null);

    // Initialize aggregated totals
    let aggregated = {
      fte: 0,
      extra: 0,
      salary: 0,
      totalCost: 0,
      hours: 0,
      ppoCost: 0,
      lifeCost: 0,
      medicare: 0,
      fica: 0,
      retirement: 0,
    };

    // If no rows remain, remove the totals node
    if (updatedRows.length === 0) {
      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/classified/totals`
      );
      await set(aggregateRef, null);
      // Clear all keyTotals when no rows remain
      //await saveKeyTotals([], districtCode, fiscalYear, functionCode, buildingCode);
    } else {
      // Recalculate totals
      aggregated = updatedRows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.extra += row.extra || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          acc.hours += row.hours || 0;
          acc.ppoCost += row.ppoCost || 0;
          acc.lifeCost += lifeMonthly * 12 * row.fte * (row.life || 0);
          acc.medicare = row.salary * medicareRate;
          acc.fica += row.salary * socialSecurityRate;
          acc.retirement += (row.salary + row.ppoCost) * peers * row.retire;
          
          return acc;
        },
        {
          fte: 0,
          extra: 0,
          salary: 0,
          totalCost: 0,
          hours: 0,
          ppoCost: 0,
          lifeCost: 0,
          medicare: 0,
          fica: 0,
          retirement: 0,
        }
      );

      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/classified/totals`
      );
      await set(aggregateRef, {
        ...aggregated
      });
    }

    // Update local state
    setRows(updatedRows);
  }

  const determineObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6152", condition: [1111, 1131, 1151, 1191, 1221, 1281, 2222, 3512, 3812 ] },
        { object: "6161", condition: [2552, 1191] },
        { object: "6151"}, //default
      ],
      health: [
        { object: "6240", condition: [2222] },
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6221" }], // Default
      fica: [{ object: "6231" }], // Default
      medicare: [{ object: "6232" }], // Default
    };
  
    const typeConditions = conditions[type];
    if (!typeConditions) return null;
  
    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }
  
    return null;
  };

  const [notification, setNotification] = useState("");

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setObjectCode(row.objectCode || determineObjectCode("salary", functionCode)); // Default object code for Classified Staff
    setFund(row.fund || "GENERAL_FUND");
    setModalOpen(true);
  };
  
  const fetchBaseHourlyPay = async (scheduleToUse, category, step) => {
    if (!category || step === null || step === undefined) return 0;

    try {
      const hourlyRateRef = ref(
        database,
        `${salarySchedulePath}/${scheduleToUse}/salaryTable/${category}/${step}/hourlyRate`
      );
      const snapshot = await get(hourlyRateRef);
      if (snapshot.exists()) return Number(snapshot.val()) || 0;
      console.error("No hourly rate found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching hourly rate: ", error);
      return 0;
    }
  };

  const addRow = () => {
    const newRow = {
      id: Date.now(),
      last: "",
      first: "",
      fte: 1,
      life: 0,
      health: 0,
      retire: 0,
      position: "",
      category: "",
      step: 1,
      hourlyRate: null,
      extra: 0,
      hours: 0,
      salary: 0,
      totalCost: 0,
      project: '00000',
      source: determineSource(functionCode),
      scheduleToUse: Object.keys(availableSalarySchedules)[0] || ""
    };
    setRows([...Rows, newRow]);
  };

  const updateRow = async (id, key, value) => {
    
    const updatedRows = [...Rows];
    const rowIndex = updatedRows.findIndex((row) => row.id === id);

    const row = updatedRows[rowIndex];
    const updated_row = {
      ...row,
      [key]: value
    }

    let hourlyRate;
    // If user is entering hourlyRate, calculate based on that, otherwise get it from a salary schedule
    if(updated_row.category==='manualEntry'){
      hourlyRate = updated_row.hourlyRate;
    } else {
      hourlyRate = await fetchBaseHourlyPay(updated_row.scheduleToUse,
                                            updated_row.category,
                                            updated_row.step)
    }
    
    const salary = (updated_row.hours || 0) * hourlyRate * (updated_row.fte || 0) + row.extra;
    const ppoCost = ppoMonthly * 12 * (updated_row.fte || 0) * (updated_row.health || 0);
    const lifeCost = lifeMonthly * 12 * (updated_row.fte || 0) * (updated_row.life || 0);
    const fica = salary * socialSecurityRate;
    const medicare = salary * medicareRate;
    const retirement = (salary + ppoCost) * peers * updated_row.retire;
    const totalCost = salary + ppoCost + lifeCost + fica + medicare + retirement;

    updatedRows[rowIndex] = {
      ...updated_row,
      hourlyRate,
      salary,
      ppoCost,
      lifeCost,
      fica,
      medicare,
      retirement,
      totalCost,
      hasChanged: true // Mark for saving to firebase
    };

    setRows(updatedRows)
  }
  

  const saveKeyTotals = async (Rows, districtCode, fiscalYear, functionCode, buildingCode) => {
        try {
          if (Rows.length === 0) {
            console.log("No rows to process for key totals.");
            return;
          }
    
          console.log('saving key totals: ', Rows, districtCode, fiscalYear, functionCode, buildingCode)
        
          // Initialize an object to store aggregated totals by dynamicKey, fund, and component
          const aggregatedKeyFundTotals = {};
      
          // Step 2: Loop through rows to calculate totals
          Rows.forEach((row) => {
            // Define the components and their respective costs
            const components = {
              salary: row.salary || 0,
              health: ppoMonthly * 12 * (row.fte || 0) * (row.health || 0) +
                      lifeMonthly * 12 * (row.fte || 0) * (row.life || 0),
              retirement: row.retirement,
              medicare: row.medicare,
              fica: row.fica
            };
      
            // Process each component
            Object.entries(components).forEach(([key, value]) => {
              // Determine the object code for the component
              const objectCode =
                key === "salary"
                  ? row.objectCode || determineObjectCode(key, functionCode) // Use modal-overwritten value for salary
                  : determineObjectCode(key, functionCode); // Use dynamic determination for other components
      
              // Generate the dynamic key
              const dynamicKey = `${determineFundCode(row.fund)}-${functionCode}-${objectCode}-${buildingCode}-${row.source || 3}-${
                row.project || "00000"
              }`;
      
              // Initialize the structure for this dynamic key and fund if not already present
              if (!aggregatedKeyFundTotals[dynamicKey]) {
                aggregatedKeyFundTotals[dynamicKey] = {};
              }
              if (!aggregatedKeyFundTotals[dynamicKey]['budget']) {
                aggregatedKeyFundTotals[dynamicKey]['budget'] = 0;
              }
      
              // Add the component's cost to the correct dynamic key and fund
              aggregatedKeyFundTotals[dynamicKey]['budget'] += value || 0;
            });
          });
    
          console.log('saving to budget: ', aggregatedKeyFundTotals)
      
          // Step 3: Write the aggregated totals to Firebase
          const writePromises = Object.entries(aggregatedKeyFundTotals).map(
            async ([dynamicKey, budgetData]) => {
                const keyTotalRef = ref(
                    database,
                    `budget/${districtCode}/${fiscalYear}/expense/${dynamicKey}/budget/${version}`
                );
                await update(keyTotalRef, {...budgetData, editable: false});
            }
        );
    
        await Promise.all(writePromises);
        console.log("Key totals saved successfully for each component.");
    } catch (error) {
        console.error("Error saving key totals: ", error);
    }
    };
  
      
  
const saveDataToFirebase = async (rows = Rows) => {
  try {
    console.log('@@!!', Rows)
      // 1. Save individual staff data
      const saveRowPromises = rows.filter((row) => row.hasChanged).map((row) => {
        const rowRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/classified/staff/${row.id}`
        );
        return update(rowRef, row); // Save only changed rows
      });
      await Promise.all(saveRowPromises);    
      // Reset `hasChanged` flag for all rows
      setRows((prevRows) =>
        prevRows.map((row) => ({ ...row, hasChanged: false }))
      );  
      console.log("Saved Staff");
      // 2. Calculate aggregated totals
      const aggregated = rows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.extra += row.extra || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          acc.hours += row.hours || 0;
          acc.ppoCost += row.ppoCost; //ppoMonthly * 12 * (row.fte || 0) * (row.health || 0);
          acc.lifeCost += row.lifeCost;//lifeMonthly * 12 * (row.fte || 0) * (row.life || 0);
          acc.medicare += row.medicare;//(row.salary || 0) * medicareRate;
          acc.fica += row.fica;//(row.salary || 0) * socialSecurityRate;
          acc.retirement += row.retirement;//(row.salary || 0 + row.ppoCost ||0) * peers * row.retire;
          return acc;
        },
        {
          fte: 0,
          extra: 0,
          salary: 0,
          totalCost: 0,
          hours: 0,
          ppoCost: 0,
          lifeCost: 0,
          medicare: 0,
          fica: 0,
          retirement: 0,
        }
      );

      // Save aggregated totals
      const totalsRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/classified/totals`
      );
      await set(totalsRef, { ...aggregated, key: "Totals" });
      // 3. Save keyTotals with dynamic keys
      // Call the dynamic key writing function
      await saveKeyTotals(rows, districtCode, fiscalYear, functionCode, buildingCode);
      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving classified data: ", error);
    }
  };

  const saveDetails = (selectedRow, modalFields) => {

    modalFields.forEach(item=> {
      updateRow(selectedRow.id, item, selectedRow[item])
    })

    setModalOpen(false);
  };

  useEffect(() => {
      if (Rows.some(row => row.hasChanged)) {
        saveDataToFirebase();
      }
    }, [Rows]);
  
  
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        // Fetch individual staff rows
        const rowsRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/classified/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const rows = Object.values(data).map((row) => ({
            ...row,
            objectCode: row.objectCode || "",
            fund: row.fund || "GENERAL_FUND",
            project: row.project || "00000",
            source: row.source || 1,
          }));
          setRows(rows);          
        }
      } catch (error) {
        console.error("Error loading staff data: ", error);
      }
    };
    setTimeout(() => {
      fetchStaffData();
    }, 0);
  }, [districtCode, fiscalYear, functionCode, buildingCode, staffPath]);
  
  useEffect(() => {
      const aggregated = Rows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );
    
      onDataUpdate(aggregated); // Notify parent
    }, [Rows]); // Only re-run when `Rows` changes

  useEffect(() => {
  
      // Get available names of salary schedules for details drop down
      get(ref(database, `${salarySchedulePath}/`)).then(result=>{
        setAvailableSalarySchedules(result.val())
      });
  
    }, [districtCode, fiscalYear, salarySchedulePath]);

  useEffect(() => {
      const aggregated = Rows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );
    
      onDataUpdate(aggregated); // Notify parent
    }, [Rows]); // Only re-run when `Rows` changes
  
  return (
    <div className="staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Classified Roles</h3>
      <table className="staff-management-table">
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>FTE</th>
            <th>Life</th>
            <th>Health</th>
            <th>Retire</th>
            <th>Position</th>
            <th>Category</th>
            <th>Category Step</th>
            <th>Hourly Rate</th>
            <th>Hours</th>
            <th>Extra</th>
            <th>Salary</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.id}>
              <td>
                <input className="tableInput"
                  type="text"
                  value={row.last}
                  onChange={(e) => updateRow(row.id, "last", e.target.value)}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="text"
                  value={row.first}
                  onChange={(e) => updateRow(row.id, "first", e.target.value)}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.fte}
                  onChange={(e) =>
                    updateRow(row.id, "fte", parseFloat(e.target.value))
                  }
                />
              </td>
              <td>
                <input className="tableInput"
                  type="checkbox"
                  checked={!!row.life}
                  onChange={(e) =>
                    updateRow(row.id, "life", e.target.checked ? 1 : 0)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!row.health}
                  onChange={(e) =>
                    updateRow(row.id, "health", e.target.checked ? 1 : 0)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!row.retire}
                  onChange={(e) =>
                    updateRow(row.id, "retire", e.target.checked ? 1 : 0)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) => updateRow(row.id, "position", e.target.value)}
                />
              </td>
              <td>
              <select
                value={row.category} className="tableInput"
                onChange={(e) => updateRow(row.id, "category", e.target.value)}
              >
                <option value="">Select</option>
                {(availableSalarySchedules[row.scheduleToUse]?.['salaryTable'] || {}) &&
                    Object.keys(availableSalarySchedules[row.scheduleToUse]?.['salaryTable'] || {}).map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                ))}
                <option key={'manualEntry'} value={'manualEntry'}>{'Manual Entry'}</option>
              </select>
              </td>
              <td>
              {row.category === 'manualEntry' ? ("") : (<input
                  type="number"
                  value={row.step}
                  onChange={(e) =>
                    updateRow(row.id, "step", parseInt(e.target.value, 10))
                  }
                />)}
              </td>
              <td>
              {row.category === 'manualEntry' ? (
                <input
                  className="tableInput"
                  type="number"
                  value={row.hourlyRate || ""}
                  onChange={(e) =>
                    updateRow(row.id, "hourlyRate", parseFloat(e.target.value))
                  }
                />
              ) : (
                row.hourlyRate
              )}
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.hours}
                  onChange={(e) =>
                    updateRow(row.id, "hours", parseFloat(e.target.value))
                  }
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.extra}
                  onChange={(e) =>
                    updateRow(row.id, "extra", parseFloat(e.target.value))
                  }
                />
              </td>
              <td>{formatDollars(row.salary)}</td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
              <button
                onClick={async () => {
                  handleDeleteEmployee(row)
                }}
              >
                Delete
              </button>
              <button onClick={() => openDetailsModal(row)} className="details-btn" >Details</button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
    <tr>
      <td colSpan={2}>Totals:</td>
      <td>{Rows.reduce((acc, row) => acc + (row.fte || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.life || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.health || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.retire || 0), 0)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{Rows.reduce((acc, row) => acc + (row.hours || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.extra || 0), 0)}</td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.salary || 0), 0))}</td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.totalCost || 0), 0))}</td>
      <td></td>
    </tr>
  </tfoot>
      </table>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
          <h4>Edit Object Code & Fund</h4>
            <label>
              Project:
              <input
                type="text"
                value={selectedRow?.project || "00000"}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    project: e.target.value,
                  }))
                }
              />
            </label>
            <label>
              Source:
              <input
                type="number"
                value={selectedRow?.source || 1}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    source: parseInt(e.target.value, 10),
                  }))
                }
              />
            </label>
            <label>
              Object Code For Salary:
              <select
                value={objectCode}
                onChange={(e) => setObjectCode(e.target.value)}
              >
                {Object.entries(OBJECT_CODES).map(([code, label]) => (
                  <option key={code} value={code}>
                    {code} - {label}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fund For Total Compensation:
              <select value={fund} onChange={(e) => setFund(e.target.value)}>
                <option value="GENERAL_FUND">GENERAL_FUND</option>
                <option value="SPECIAL_REVENUE">SPECIAL_REVENUE</option>
                <option value="CAPITAL_FUND">CAPITAL_FUND</option>
                <option value="DEBT_SERVICE_FUND">DEBT_SERVICE_FUND</option>
              </select>
            </label>
            <label>
              Salary Schedule:
              <select
                value={selectedRow?.scheduleToUse}
                onChange={(e) => {
                  setSelectedRow((prev) => ({
                    ...prev,
                    scheduleToUse: e.target.value,
                  }));
                }
                }
              >
                {Object.keys(availableSalarySchedules).map((schedule, index) => (
                  <option key={index} value={schedule}>
                    {schedule}
                  </option>
                ))}
              </select>
            </label>
            <button onClick={() => saveDetails(selectedRow, ['project', 'source', 'objectCode', 'fund', 'scheduleToUse'])}>Save</button>
            <button onClick={() => setModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
      <button onClick={addRow} className ="add-row-btn" >Add Classified Role</button>

    </div>
  );
};

export default React.memo(ClassifiedEmployeeTable);

