import React, { useState, useEffect } from 'react';
import GeneralDrilldownTable from './GeneralDrilldownTable';
import '../styles/BudgetBuilder.css';
import { database } from '../firebaseConfig';
import { ref, get } from "firebase/database";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import BudgetSummary from './BudgetSummary';
import {assignLevels, getUniqueValues, sumFieldByGroup, findClosestMatch} from '../utils'

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title,annotationPlugin);

function subtractArraysByYear(array1, array2) {
    const FUND_TYPES = [
        'GENERAL_FUND',
        'CAPITAL_PROJECTS',
        'DEBT_SERVICE',
        'SPECIAL_REVENUE'
    ];
    
    // Create a map of YEAR to full object for the second array
    const array2Map = new Map(
        array2.map(item => [item.YEAR, item])
    );
    
    // Map over first array and subtract corresponding values for each fund
    return array1.map(item => {
        const matchingItem = array2Map.get(item.YEAR) || {};
        
        // Create result object starting with the YEAR
        const result = { YEAR: item.YEAR };
        
        // Subtract each fund type
        FUND_TYPES.forEach(fund => {
            result[fund] = (item[fund] || 0) - (matchingItem[fund] || 0);
        });
        
        return result;
    });
}

const LineChart = ({ datasets, group_labels, value_labels, yaxisLabel , yMin, yMax}) => {
    // Get all unique years from all datasets
    const years = [...new Set(datasets.flatMap(data => data.map(item => item.YEAR)))].sort();

    const chartData = {
      labels: years, // Use sorted unique years
      datasets: datasets.map((data, index) => ({
        label: `${group_labels[index]}`, // Label for each dataset
        data: years.map(year => {
            const found = data.find(item => item.YEAR === year);
            // Sum up all the values specified in value_labels
            return found 
              ? value_labels.reduce((sum, label) => sum + (found[label] || 0), 0)
              : null;
          }),
        borderColor: index === 0 ? "blue" : index === 1 ? "green" : "red", // Different colors per dataset
        backgroundColor: index === 0 ? "rgba(0, 0, 255, 0.2)" : index === 1 ? "rgba(0, 255, 0, 0.2)" : "rgba(255, 0, 0, 0.2)",
        tension: 0.3,
      })),
    };
  
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: { 
                title: { 
                    display: true, 
                    text: "Year", 
                    font: { size: 16 } // Increase font size
                },
                ticks: {
                    font: { size: 18 } // Adjust tick label size
                }
            },
            y: { 
                min: yMin,
                max: yMax,
                title: { 
                    display: true, 
                    text: `${yaxisLabel}`, 
                    font: { size: 16 } // Increase font size
                },
                ticks: {
                    font: { size: 18 } // Adjust tick label size
                },
                beginAtZero: true 
            }
        },
        plugins: {
            tooltip: {
                enabled: true
            },
            legend: {
                display: true,
                labels: {
                    font: { size: 14 } // Adjust legend font size
                }
            },
            datalabels: {
                display: false
            }
        }
    };
    
  
    return <Line data={chartData} options={options} />;
  };

const BudgetBuilder = ({ selectedDistrictInfo, deseCodeMap, fiscalYear, version }) => {

    const [currentBudget, setCurrentBudget] = useState(null);
    const [expenseData, setExpenseData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [fundBalanceData, setFundBalanceData] = useState([]);
    const [isGraphVisible, setIsGraphVisible] = useState(false);

    useEffect(() => {
        const loadBudget = async () => {
        
            const currentBudgetPromises = [selectedDistrictInfo['DISTRICT_COUNTY_CODE']].map(async (ID) => {
                            const snapshot = await get(ref(database, `/budget/${ID}`));
                            return [ID, snapshot.exists() ? snapshot.val() : null];
                        });
                        
            const currentResults = await Promise.all(currentBudgetPromises);
            const currentBudgetJSON = Object.fromEntries(currentResults.filter(([_, data]) => data !== null));

            
            const currentBudgetArray = Object.entries(currentBudgetJSON ?? {}).flatMap(([ID, years]) =>
                Object.entries(years ?? {}).flatMap(([YEAR, categories]) =>
                    Object.entries(categories ?? {}).flatMap(([GROUP, accounts]) =>
                        Object.entries(accounts ?? {}).flatMap(([accountCode, budgetData]) => {
                            // Early return empty array if budgetData or budget is null/undefined
                            if (!budgetData?.budget) return [];
                            
                            return Object.entries(budgetData.budget)
                                .filter(([ver]) => ver === version)
                                .map(([ver, details]) => ({
                                    ID: ID || '',
                                    YEAR: YEAR || '',
                                    GROUP: GROUP || '',
                                    accountCode: accountCode || '',
                                    version: ver || '',
                                    // Spread details with default empty object to prevent undefined
                                    ...(details || {})
                                }));
                        })
                    )
                )
            );
            
            for (let i = 0; i < currentBudgetArray.length; i++) {
                currentBudgetArray[i]['DISTRICT_NAME'] = selectedDistrictInfo['DISTRICT_NAME'];
            }

            const fundMapping = {
                "10": "GENERAL_FUND",
                "20": "SPECIAL_REVENUE",
                "30": "DEBT_SERVICE",
                "40": "CAPITAL_PROJECTS"
                };

            const groupedData = {};

            currentBudgetArray.forEach(item => {

                let key = parseInt(item.accountCode.split('-')[1]);
                const groupData = deseCodeMap[item.GROUP] || {};
                // if the key doesn't exist, it is a custom function code 
                let customFuncCode = false;
                if(!groupData[key]){customFuncCode = true;}

                if(customFuncCode){
                    console.log('custom function code detected: ', key, item.YEAR, item.accountCode)
                    console.log('closest match: ', findClosestMatch(Object.keys(groupData), key))
                }

                const keyData = (!customFuncCode? groupData[key] : groupData[findClosestMatch(Object.keys(groupData), key)]) || {};
                const objectCodeData = deseCodeMap['object'][parseInt(item.accountCode.split('-')[2])] || {};
                const fundKey = fundMapping[item.fund];
                const groupKey = `${item.YEAR}-${item.accountCode}-${item.version}`;
                
                if (!groupedData[groupKey]) {
                    groupedData[groupKey] = {
                        ...item,
                        LABEL: item.accountCode.slice(8,item.accountCode.length),
                        FUNC_CODE_DESCRIPTION: !customFuncCode ? keyData.DETAIL_DESCRIPTION : "Unknown",
                        DESCRIPTION: objectCodeData.DETAIL_DESCRIPTION ? objectCodeData.DETAIL_DESCRIPTION : "Unknown",
                        IS_EDITABLE: keyData.IS_EDITABLE ? keyData.IS_EDITABLE : false,
                        PARENT: !customFuncCode ? `${key} - ${keyData.DETAIL_DESCRIPTION}` : `${key} - Unknown`,
                        GPARENT: keyData.SUBCLASS ? `${keyData.SUBCLASS} - ${keyData.SUBCLASS_DESCRIPTION}` : 'Unknown',
                        GGPARENT: keyData.CLASS ? `${keyData.CLASS} - ${keyData.CLASS_DESCRIPTION}` : 'Unknown',
                        GGGPARENT: keyData.GROUP? `${keyData.GROUP}` : item.GROUP,
                        GENERAL_FUND: 0,
                        SPECIAL_REVENUE: 0,
                        DEBT_SERVICE: 0,
                        CAPITAL_PROJECTS: 0,
                        TOTAL_ALL_FUNDS: 0,
                        AGGREGATE: false
                    }
                }
                if (fundKey) {
                    groupedData[groupKey][fundKey] = item.budget;
                    groupedData[groupKey]['TOTAL_ALL_FUNDS'] += item.budget;
                }
                
            })

            const current_budget_children = Object.entries(groupedData).map(([key, value]) => ({
                groupKey: key,
                ...value
                }));

            const parentSum = sumFieldByGroup(current_budget_children, 
                ['ID', 'YEAR', 'version', 'PARENT'], 
                ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE'
                ]);

            let parent_result = parentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GPARENT,
            AGGREGATE: true
            }))

            const gparentSum = sumFieldByGroup(parent_result, 
            ['ID', 'YEAR', 'version', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

            let gparent_result = gparentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GGPARENT,
            AGGREGATE: true
            }))

            const ggparentSum = sumFieldByGroup(gparent_result, 
            ['ID', 'YEAR', 'version', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

            let ggparent_result = ggparentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GGGPARENT,
            AGGREGATE: true
            }))

            const gggparentSum = sumFieldByGroup(ggparent_result, 
                ['ID', 'YEAR', 'version', 'PARENT'], 
                ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

            let gggparent_result = gggparentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: 'Self',
            AGGREGATE: true
            }))

            const finalBudget = [current_budget_children, parent_result, gparent_result, ggparent_result, gggparent_result].flat();

            // calculate the LEVEL of each LABEL. i.e. PARENT == 'Self' is level 0, its children level 1, etc.
            // this will help us indent the table properly according to the level

            let levelDict = assignLevels(finalBudget, 'LABEL');
            finalBudget.forEach(item => item['LEVEL'] = levelDict[item.LABEL]);

            setCurrentBudget(finalBudget);
            setExpenseData(finalBudget?.filter(item=>(item.LEVEL===0) && (item.LABEL==='expense') && (item.YEAR>2024)))
            setRevenueData(finalBudget?.filter(item=>(item.LEVEL===0) && (item.LABEL==='revenue') && (item.YEAR>2024)))

            const budgetSummaryPath = `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/`;
            const summaryRef = ref(database, budgetSummaryPath);
            const summarySnapshot = await get(summaryRef);
            let FB_list = []
            getUniqueValues(finalBudget?.filter(item=>(item.LEVEL===0) && (item.LABEL==='expense') && (item.YEAR>2024)), "YEAR").forEach(year=>{
                FB_list.push({"YEAR": year, "Operating" : parseFloat(summarySnapshot.val()[year]['summary'][version]['% Unrestricted Fund Balance']['Operating'])})
            })
            setFundBalanceData(FB_list)
        }

    loadBudget();
    

    }, [selectedDistrictInfo, version, fiscalYear])
    

    
    return (
        <div className='budget-builder-container'>
            
            {currentBudget ? (
                <GeneralDrilldownTable
                    data = {currentBudget}
                    tableDataFilters={`item.AGGREGATE && item.YEAR > 2021 && !isNaN(item.TOTAL_ALL_FUNDS)`}
                    tableDataFormats= {{'TOTAL_ALL_FUNDS': 'dollars'}}
                    rowKey = 'LABEL'
                    columnKey = 'YEAR'
                    valueLabel = 'TOTAL_ALL_FUNDS'
                    rowLevelFunctions = {{'graph' : true, 'edit' : true}}
                    fiscalYear = {fiscalYear}
                    version = {version}
                    deseCodeMap={deseCodeMap}
                    title = {`Budget Builder ${parseInt(fiscalYear)-1}-${fiscalYear}`}
                    graphOptions = {{showLegend : false}}
                />
            ) : (
                <p>Loading data...</p>
            )}
            
            
            <div 
                className='toggle-arrow' 
                onClick={() => setIsGraphVisible(!isGraphVisible)} 
            >
                {isGraphVisible ? '▼ Hide Graphs' : '► Show Graphs'}
            </div>
            
            {isGraphVisible && (
                (expenseData?.length>0 && revenueData?.length>0 && fundBalanceData?.length>0) ? (
                <div className='graphs-container'>
                <div className='revenue-expenses-graph-container'>
                    <LineChart datasets={[expenseData, revenueData]}
                               group_labels={['expense', 'revenue']}
                               value_labels={['GENERAL_FUND', 'SPECIAL_REVENUE']}
                               yaxisLabel={'$'} 
                               yMin={0}
                               yMax={12000000}/>
                </div>
                
                <div className='fund-balance-graph-container'>
                    <LineChart datasets={[fundBalanceData]}
                                group_labels={['fund_balance']}
                                value_labels={['Operating']}
                                yaxisLabel={'%'}
                                yMin={0}
                                yMax={150}/>
                </div>
                
                </div>
            ) : (
                <p>Loading graphs...</p>
            )
            )}
            
            {currentBudget?.filter(item=>item.YEAR===fiscalYear) ? (
            <BudgetSummary currentBudget={currentBudget}
                           districtCode={selectedDistrictInfo['DISTRICT_COUNTY_CODE']}
                           fiscalYear={fiscalYear}
                           version={version}
            />) : (
                <p>Loading budget summary...</p>
            )}
        </div>
        
    );
};

export default BudgetBuilder;