import React, { useState, useEffect } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get, set, update } from "firebase/database";
import '../../styles/SalaryScheduleCertified.css';

const balanceTheme = {
    backgroundColor: '#e7f4fc',
    inputColor: '#ffffff',
    textColor: '#333333'
};

const initialBaseSalary = 38500;
const initialStepDowns = Array(40).fill(125);
const initialEscalators = Array(40).fill(0.0);

const SalarySchedule = ({ selectedDistrictInfo, fiscalYear, version }) => {

    const [baseSalary, setBaseSalary] = useState(initialBaseSalary);
    const [stepDowns, setStepDowns] = useState(initialStepDowns);
    const [escalators, setEscalators] = useState(initialEscalators);
    const [careerLadders, setCareerLadders] = useState([]);
    const [yearsToDisplay, setYearsToDisplay] = useState(40);
    const [salaryTable, setSalaryTable] = useState([]);
    const [selectedOption, setSelectedOption] = useState("New Ladder Name");
    const [newLadderName, setNewLadderName] = useState("");
    const [editLadderName, setEditLadderName] = useState("");
    const [degreeType, setDegreeType] = useState("BS");
    const [cpi, setCpi] = useState(2.5);
    const [projectToFiveYears, setProjectToFiveYears] = useState(0);
    const [displayMode, setDisplayMode] = useState('Salary');
    const [localAddOn, setLocalAddOn] = useState(0);
    const [stateAide, setStateAide] = useState(100); // Percentage, max 100
    const [showStateGrantView, setShowStateGrantView] = useState(true);
    const [defaultStepDown, setDefaultStepDown] = useState(125);
    const [defaultEscalator, setDefaultEscalator] = useState(0.0);
    const [modifiedRows, setModifiedRows] = useState(new Set());
    const [selectedSchedule, setSelectedSchedule] = useState("defaultCertified");
    const [availableScheduleNames, setAvailableScheduleNames] = useState([]);

    const salarySchedulePath = `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const staffPath = `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const benefitsPath = `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;

    useEffect(() => {
        get(ref(database, `${salarySchedulePath}/certified/`)).then(result=>setAvailableScheduleNames(Object.keys(result.val())));
    }, [selectedSchedule])
    

    useEffect(() => {
        const loadSavedSchedule = async (scheduleName) => {
            const scheduleRef = ref(database, `${salarySchedulePath}/certified/${scheduleName}`);
            
            try {
                const snapshot = await get(scheduleRef);
                if (snapshot.exists()) {
                    const savedData = snapshot.val();
                    setBaseSalary(savedData.baseSalary);
                    setStepDowns(savedData.stepDowns);
                    setEscalators(savedData.escalators);
                    setDefaultStepDown(savedData.defaultStepDown || initialStepDowns[0]); // Load default stepDown
                    setDefaultEscalator(savedData.defaultEscalator || initialEscalators[0]); // Load default escalator
                    setCareerLadders(savedData.careerLadders.map(ladder => ({
                        name: ladder.name,
                        value: ladder.stepAcross // Ensure the stepAcross value is loaded into the career ladder's 'value'
                    })));
                    setLocalAddOn(savedData.localAddOn || 0);
                    setStateAide(savedData.stateAide || 100);
                }
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };
        

        loadSavedSchedule(selectedSchedule);
        createSalaryTable();
    }, [selectedDistrictInfo, fiscalYear, version, selectedSchedule]);

    useEffect(() => {
        createSalaryTable();
    }, [baseSalary, stepDowns, escalators, careerLadders, yearsToDisplay, projectToFiveYears, cpi, displayMode, localAddOn, stateAide,showStateGrantView]);
    
    const handleSelectedScheduleChange = (e) => {
        setSelectedSchedule(e.target.value)
    }
    const determineObjectCode = (type, functionCode) => {
        const conditions = {
          salary: [
            { object: "6112", condition: [2211, 2411] },
            { object: "6131", condition: [1411, 1421] },
            { object: "6111" }, // Default
          ],
          health: [
            { object: "6240", condition: [2222] },
            { object: "6241" }, // Default
          ],
          retirement: [{ object: "6211" }], // Default
          medicare: [{ object: "6232" }], // Default
        };
    
        const typeConditions = conditions[type];
        if (!typeConditions) return null;
    
        for (const { object, condition } of typeConditions) {
          if (!condition || condition.includes(functionCode)) {
            return object;
          }
        }
    
        return null;
      };

    async function updateStaff () {
        
        const staffPromise = await get(ref(database, `${staffPath}`));
        const schedulePromise = await get(ref(database, `${salarySchedulePath}/certified/${selectedSchedule}/salarySchedule/`))
        const benefitRatesPromise = await(get(ref(database, `${benefitsPath}`)))
        const salarySchedule = schedulePromise.val()
        const staffData = staffPromise.val();
        const benefitRates = benefitRatesPromise.val();

        const funcCodes = Object.keys(staffData).filter(item=>item.length===4); // only get 4 digit function codes
        
        let buildings = []; let people = [];
        let lineItems = {};
        funcCodes.forEach(fc => {
            buildings = Object.keys(staffData[fc]);
            buildings.forEach(bldg => {
                if(staffData[fc][bldg]['certified']){
                    staffData[fc][bldg]['certified']['totals']['salary'] = 0;
                    staffData[fc][bldg]['certified']['totals']['totalCost'] = 0;
                    staffData[fc][bldg]['certified']['totals']['retirement'] = 0;
                    staffData[fc][bldg]['certified']['totals']['ppoCost'] = 0;
                    staffData[fc][bldg]['certified']['totals']['lifeCost'] = 0;
                    staffData[fc][bldg]['certified']['totals']['medicare'] = 0;
                    staffData[fc][bldg]['certified']['totals']['fte'] = 0;
                    people = Object.keys(staffData[fc][bldg]['certified']['staff'] || {});
                    people.forEach(person_id => {
                        
                        const person = staffData[fc][bldg]['certified']['staff'][person_id];

                        const salary_line_item = `20-${fc}-${determineObjectCode("salary", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                        const retirement_line_item = `20-${fc}-${determineObjectCode("retirement", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                        const medicare_line_item = `20-${fc}-${determineObjectCode("medicare", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                        const health_line_item = `20-${fc}-${determineObjectCode("health", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;

                        if(!lineItems[salary_line_item]){
                            lineItems[salary_line_item] = 0;
                            lineItems[retirement_line_item] = 0;
                            lineItems[medicare_line_item] = 0;
                            lineItems[health_line_item] = 0;
                        }
                        
                        // if the salary was manually entered, don't use the salary schedule
                        let effectiveSalary;
                        if(person.educationalStatus!='manualEntry'){
                            effectiveSalary = salarySchedule[person.educationalStatus][person.yearsInEducation-1]['salary'] * (person.fte || 1); // Calculate effective salary
                        } else {
                            effectiveSalary = person.salary
                        }
                        const contract = effectiveSalary + parseFloat(person.extendedContract || 0) + parseFloat(person.careerLadder || 0);
                        
                        const ppoCost = benefitRates.ppoMonthly * 12 * person.fte * (person.health || 0);
                        const lifeCost = benefitRates.lifeMonthly * 12 * person.fte * (person.life || 0);
                        const medicare = contract * benefitRates.medicareRate;
                        const retirement = (contract + ppoCost) * benefitRates.certifiedRetirement;
                        const totalCost = contract + ppoCost + lifeCost + medicare + retirement;
                        
                        staffData[fc][bldg]['certified']['staff'][person_id]['salary'] =  effectiveSalary || 0;
                        staffData[fc][bldg]['certified']['staff'][person_id]['totalCost'] = totalCost;
                        staffData[fc][bldg]['certified']['staff'][person_id]['retirement'] = retirement;
                        staffData[fc][bldg]['certified']['staff'][person_id]['ppoCost'] = ppoCost;
                        staffData[fc][bldg]['certified']['staff'][person_id]['lifeCost'] = lifeCost;
                        staffData[fc][bldg]['certified']['staff'][person_id]['medicare'] = medicare;

                        staffData[fc][bldg]['certified']['totals']['salary'] += effectiveSalary || 0;
                        staffData[fc][bldg]['certified']['totals']['totalCost'] += totalCost;
                        staffData[fc][bldg]['certified']['totals']['retirement'] += retirement;
                        staffData[fc][bldg]['certified']['totals']['ppoCost'] += ppoCost;
                        staffData[fc][bldg]['certified']['totals']['lifeCost'] += lifeCost;
                        staffData[fc][bldg]['certified']['totals']['medicare'] += medicare;
                        staffData[fc][bldg]['certified']['totals']['fte'] += person.fte;

                        lineItems[salary_line_item] += contract;
                        lineItems[retirement_line_item] += retirement;
                        lineItems[health_line_item] += ppoCost + lifeCost;
                        lineItems[medicare_line_item] += medicare;
                    })
                }
            })
        })

        Object.keys(lineItems).forEach(acct_code => {
            if(acct_code.split('-').slice(4,5)!='undefined'){
                const budgetRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/expense/${acct_code}/budget/${version}`);
                update(budgetRef, {budget: lineItems[acct_code], editable: false}).then(console.log(`updated ${acct_code} value: ${lineItems[acct_code]}`))
            }
        })
        
        
        await set(ref(database, `${staffPath}`), staffData);
    }

    const createSalaryTable = () => {
        const tableData = [];
        const displayData = [];
        
        // Define Table Data
        for (let year = 1; year <= yearsToDisplay; year++) {
            const row = { year, stepDown: stepDowns[year - 1], escalator: escalators[year - 1] };
    
            careerLadders.forEach((ladder, index) => {
                let currentSalary;
                let previousSalary = index === 0 
                    ? baseSalary 
                    : parseFloat(row[careerLadders[index - 1].name].replace(/,/g, ''));
    
                if (year === 1) {
                    currentSalary = index === 0 ? baseSalary : previousSalary + ladder.value;
                } else {
                    previousSalary = parseFloat((tableData[year - 2][ladder.name] || '0').replace(/,/g, ''));
                    currentSalary = previousSalary * (1 + escalators[year - 1] / 100) + stepDowns[year - 1];
                }
    
                row[ladder.name] = (currentSalary).toLocaleString();
            });
            
            tableData.push(row);
        }
    
        // Adjust Display Data based on Display Mode
        for (let year = 1; year <= yearsToDisplay; year++) {
            const row = { year, stepDown: stepDowns[year - 1], escalator: escalators[year - 1] };
    
            careerLadders.forEach((ladder, index) => {
                let currentSalary = parseFloat((tableData[year - 1][ladder.name] || '0').replace(/,/g, ''));
                
                let previousSalary = year > 1 
                    ? parseFloat((tableData[year - 2][ladder.name] || '0').replace(/,/g, ''))
                    : 0;
                
                let previousLadderSalary = index > 0
                    ? parseFloat((tableData[year - 1][careerLadders[index - 1].name] || '0').replace(/,/g, ''))
                    : 0;
                const floor = 40000 - localAddOn
                if (showStateGrantView && currentSalary < floor) {
                        currentSalary += ((floor - currentSalary)*(stateAide/100));
                        previousSalary += ((floor - previousSalary)*(stateAide/100));
                    }
                if (showStateGrantView && previousLadderSalary < floor) {
                    previousLadderSalary += ((floor - previousLadderSalary)*(stateAide/100));
                    }
                
    
                switch (displayMode) {
                    case 'Change by Years':
                        row[ladder.name] = year > 1 ? (currentSalary - previousSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-';
                        break;
                    case 'Change by Level':
                        row[ladder.name] = index > 0 ? Math.round(currentSalary - previousLadderSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-';
                        break;
                    case '% Change by Years':
                        row[ladder.name] = year > 1 ? ((currentSalary - previousSalary) / previousSalary * 100).toFixed(2) + '%' : '-';
                        break;
                    case '% Change by Level':
                        row[ladder.name] = index > 0 ? ((currentSalary - previousLadderSalary) / previousLadderSalary * 100).toFixed(2) + '%' : '-';
                        break;
                    default: // Salary
                        row[ladder.name] = Math.round(currentSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                }
            });
            displayData.push(row);
        }
    
        setSalaryTable(displayData);
    };

    const handleDefaultChange = (field, value) => {
    const newValue = parseFloat(value) || 0;
    let customValues = [];

    // Identify custom values based on the field being changed
    if (field === "stepDown") {
        stepDowns.forEach((stepValue, index) => {
            if (modifiedRows.has(index)) {
                customValues.push(`Step Down at Step ${index + 1}`);
            }
        });
    } else if (field === "escalator") {
        escalators.forEach((escValue, index) => {
            if (modifiedRows.has(index)) {
                customValues.push(`Escalator at Step ${index + 1}`);
            }
        });
    }

    // Display confirmation popup if there are custom values
    if (customValues.length > 0) {
        const confirmMessage = `You are about to overwrite custom values, are you sure you want to continue? Custom values at:\n${customValues.join('\n')}`;
        if (!window.confirm(confirmMessage)) {
            return; // Exit if the user cancels
        }
    }

    // Apply new defaults and clear modified rows
    if (field === "stepDown") {
        setDefaultStepDown(newValue);
        const newStepDowns = Array(stepDowns.length).fill(newValue);
        setStepDowns(newStepDowns);
    } else if (field === "escalator") {
        setDefaultEscalator(newValue);
        const newEscalators = Array(escalators.length).fill(newValue);
        setEscalators(newEscalators);
    }

    setModifiedRows(new Set()); // Reset modified rows after overwriting
};


    const handleInputChange = (index, field, value) => {
        const newValue = parseFloat(value) || 0;
    
        if (field === 'stepDown') {
            const newStepDowns = [...stepDowns];
            newStepDowns[index] = newValue;
            setStepDowns(newStepDowns);
        } else if (field === 'escalator') {
            const newEscalators = [...escalators];
            newEscalators[index] = newValue;
            setEscalators(newEscalators);
        }
    
        // Mark the row as modified
        setModifiedRows((prev) => new Set(prev).add(index));
    };

    const handleCareerLadderChange = (index, value) => {
        const updatedLadders = [...careerLadders];
        updatedLadders[index].value = parseFloat(value) || 0;
        setCareerLadders(updatedLadders);
    };

    const addCareerLadder = () => {
        if (newLadderName && !careerLadders.some(ladder => ladder.name === `${degreeType}${newLadderName}`)) {
            setCareerLadders([...careerLadders, { name: `${degreeType}${newLadderName}`, value: 0 }]);
            setNewLadderName("");
            setDegreeType("BS");
            setSelectedOption("New Ladder Name");
        }
    };

    const removeSelectedLadder = () => {
        setCareerLadders(careerLadders.filter((ladder) => ladder.name !== selectedOption));
        setSelectedOption("New Ladder Name");
    };

    const updateLadderName = () => {
        setCareerLadders(careerLadders.map(ladder =>
            ladder.name === selectedOption ? { ...ladder, name: editLadderName } : ladder
        ));
        setSelectedOption(editLadderName);
        setEditLadderName("");
    };

    const shouldHighlight = (value, ladderName) => {
        if (!value || displayMode !== 'Salary') return false;
    
        const numericValue = parseFloat(value.replace(/[\$,]/g, ''));
        if (isNaN(numericValue)) {
            console.warn(`Invalid numeric value for ${value}`);
            return false;
        }
    
        const currentYear = fiscalYear;
        if (currentYear === 2025) return false; // No highlighting for 2025
    
        const threshold = ladderName.startsWith("BS")
            ? calculateComplianceThreshold(40000, currentYear, projectToFiveYears)
            : calculateComplianceThreshold(45000, currentYear, projectToFiveYears);
    
        return numericValue < threshold;
    };

    const calculateComplianceThreshold = (baseThreshold, currentYear, projectYears) => {
        const startYear = Math.max(currentYear, 2026); // Salary floor starts in 2026
        const floorYear = Math.min(startYear + projectYears, 2031);
        const cpiStartYear = 2032;
        const yearsFrom2025 = floorYear - 2026; // Increment calculation starts after 2025
        const incrementalIncrease = yearsFrom2025 > 0 ? yearsFrom2025 * 1000 : 0;
        const baseFloor = baseThreshold + incrementalIncrease;
    
        // Apply CPI for years beyond 2030
        if (startYear + projectYears >= cpiStartYear) {
            const cpiYears = Math.max(startYear + projectYears - cpiStartYear + 1, 0);
            return Math.round(baseFloor * Math.pow(1 + cpi / 100, cpiYears));
        }
    
        return currentYear < 2026 ? 0 : baseFloor; // No floor for 2025
    };
    
    

    const handleSaveToFirebase = async () => {
        // Prepare the salary schedule data with both 'salary' and 'baseSalary'
        const calculateBaseSalary = (ladder, index, ladderIndex) => {
            if (index === 0) {
                // First row calculations
                return ladderIndex === 0 ? baseSalary : baseSalarySchedule[careerLadders[ladderIndex - 1].name][0] + ladder.value;
            }
            
            // For subsequent rows, always calculate from the previous row of the same ladder
            const previousRowValue = baseSalarySchedule[ladder.name][index - 1];
            return previousRowValue * (1 + (escalators[index - 1] / 100)) + stepDowns[index - 1];
        };
    
        const calculateDisplaySalary = (baseSalaryValue) => {
            if (!showStateGrantView) return baseSalaryValue;
            
            const floor = 40000 - localAddOn;
            if (baseSalaryValue < floor) {
                return baseSalaryValue + ((floor - baseSalaryValue) * (stateAide/100));
            }
            return baseSalaryValue;
        };
    
        const baseSalarySchedule = {};
        careerLadders.forEach((ladder, ladderIndex) => {
            baseSalarySchedule[ladder.name] = Array(yearsToDisplay).fill(0);
            for (let i = 0; i < yearsToDisplay; i++) {
                baseSalarySchedule[ladder.name][i] = calculateBaseSalary(ladder, i, ladderIndex);
            }
        });
    
        const salarySchedule = {};
        careerLadders.forEach(ladder => {
            salarySchedule[ladder.name] = Array.from({ length: yearsToDisplay }, (_, index) => ({
                baseSalary: baseSalarySchedule[ladder.name][index],
                salary: calculateDisplaySalary(baseSalarySchedule[ladder.name][index])
            }));
        });

        const dataToSave = {
            baseSalary,
            stepDowns,
            escalators,
            defaultStepDown,
            defaultEscalator,
            careerLadders: careerLadders.map(ladder => ({ name: ladder.name, stepAcross: ladder.value })),
            salarySchedule
        };

        try {
            await set(ref(database, `${salarySchedulePath}/certified/${selectedSchedule}`), dataToSave);
            alert("Salary schedule saved successfully!");

            updateStaff();

        } catch (error) {
            console.error("Error saving data:", error);
            alert("Failed to save salary schedule. Please try again.");
        }
    };

    return (
            <div className = 'salary-schedule-certified-main'>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>Schedule Name:</label>
                    <select
                        id="schedule-select"
                        value={selectedSchedule}
                        onChange={(event) => handleSelectedScheduleChange(event)}
                    >
                        <option value="" disabled>
                        Select Schedule Name
                        </option>
                        {availableScheduleNames?.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                        ))}
                    </select>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>Base Salary:</label>
                    <input
                        type="number"
                        value={baseSalary}
                        onChange={(e) => setBaseSalary(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                        step = "50"
                    />
                    <label>Years to Display:</label>
                    <input
                        type="number"
                        value={yearsToDisplay}
                        onChange={(e) => setYearsToDisplay(parseInt(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <label>Default Step Down ($):</label>
                    <input
                        type="number"
                        value={defaultStepDown}
                        onChange={(e) => handleDefaultChange('stepDown', e.target.value)}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>Default Escalator (%):</label>
                    <input
                        type="number"
                        value={defaultEscalator}
                        onChange={(e) => handleDefaultChange('escalator', e.target.value)}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                </div>

                
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', color: balanceTheme.textColor }}>
                    <label>CPI (%):</label>
                    <input
                        type="number"
                        value={cpi}
                        onChange={(e) => setCpi(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>Project Years Out:</label>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        step="1"
                        value={projectToFiveYears ? projectToFiveYears : 0}
                        onChange={(e) => setProjectToFiveYears(parseInt(e.target.value))}
                        style={{ marginLeft: '10px', width: '100px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                    />
                    <span style={{ marginLeft: '10px' }}>{projectToFiveYears}</span>
                </div>
                {/* Local Add On and State Aide Input Boxes */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px', color: balanceTheme.textColor }}>
                    <label>Local Add On:</label>
                    <input
                        type="number"
                        value={localAddOn}
                        onChange={(e) => setLocalAddOn(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>State Aide (%):</label>
                    <input
                        type="number"
                        value={stateAide}
                        onChange={(e) => setStateAide(Math.min(parseFloat(e.target.value), 100))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                </div>

                {/* State Grant View Toggle */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>State & Local Aide:</label>
                    <input
                        type="checkbox"
                        checked={showStateGrantView}
                        onChange={() => setShowStateGrantView(!showStateGrantView)}
                        style={{ marginLeft: '10px' }}
                    />
                </div>
                <h3>Manage Levels</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <select
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            setEditLadderName(e.target.value); 
                        }}
                        value={selectedOption}
                        style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '150px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                    >
                        <option value="New Ladder Name">New Ladder Name</option>
                        {careerLadders.map((ladder, index) => (
                            <option key={index} value={ladder.name}>{ladder.name}</option>
                        ))}
                    </select>
                    {selectedOption === "New Ladder Name" ? (
                        <>
                            <select
                                onChange={(e) => setDegreeType(e.target.value)}
                                value={degreeType}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '80px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                            >
                                <option value="BS">BS</option>
                                <option value="MS">MS</option>
                                <option value="Spec">Spec</option>
                            </select>
                            <input
                                type="text"
                                placeholder="Years Experience"
                                value={newLadderName}
                                onChange={(e) => setNewLadderName(e.target.value)}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '120px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                            />
                            <button onClick={addCareerLadder}>Add Ladder</button>
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                value={editLadderName}
                                onChange={(e) => setEditLadderName(e.target.value)}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '120px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                            />
                            <button onClick={updateLadderName}>Update Name</button>
                            <button onClick={removeSelectedLadder} style={{ marginLeft: '10px',border: '1px solid #b3c7d6', borderRadius: '4px' }}>Remove Selected Ladder</button>
                        </>
                    )}
                </div>
                
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    {['Salary', 'Change by Years', '% Change by Years', 'Change by Level', '% Change by Level'].map((mode, index) => (
                        <button
                            key={index}
                            onClick={() => setDisplayMode(mode)}
                            style={{
                                backgroundColor: displayMode === mode ? '#007BFF' : '#e7f4fc',
                                color: displayMode === mode ? '#fff' : '#333',
                                border: '1px solid #b3c7d6',
                                borderRadius: '4px',
                                padding: '5px',
                                cursor: 'pointer',
                                margin: '0 5px'
                            }}
                        >
                            {mode}
                        </button>
                    ))}
                </div>


                <button onClick={handleSaveToFirebase} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Save Salary Schedule
                </button>
                
                <table className="salary-table" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Step Down ($)</th>
                            <th>Step Down (%)</th>
                            <th>Year</th>
                            {careerLadders.map((ladder) => (
                                <th key={ladder.name}>{ladder.name}</th>
                            ))}
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            {careerLadders.map((ladder, index) => (
                                <td key={index}>
                                    <input
                                        type="number"
                                        value={ladder.value}
                                        onChange={(e) => handleCareerLadderChange(index, e.target.value)}
                                        style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        placeholder="Step Across"
                                    />
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {salaryTable.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="number"
                                        value={row.stepDown}
                                        style={{ backgroundColor: balanceTheme.inputColor, border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        onChange={(e) => handleInputChange(index, 'stepDown', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={row.escalator}
                                        style={{ backgroundColor: balanceTheme.inputColor, border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        onChange={(e) => handleInputChange(index, 'escalator', e.target.value)}
                                    />
                                </td>
                                <td>{row.year}</td>
                                {careerLadders.map((ladder) => (
                                    <td 
                                        key={ladder.name} 
                                        style={{ backgroundColor: shouldHighlight(row[ladder.name], ladder.name) ? '#ffcccc' : 'transparent' }}
                                    >
                                        {row[ladder.name]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
    );
};

export default SalarySchedule;

