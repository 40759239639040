import React, { useState } from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({setActiveComponent, activeComponent, boardView, userPermissions, sidebarMinimized, toggleSidebar}) => {

    const [isOverviewOpen, setIsOverviewOpen] = useState(false);
    const [isHistoricalOpen, setIsHistoricalOpen] = useState(false);
    const [isCurrentOpen, setIsCurrentOpen] = useState(false);
    const [isPlanningOpen, setIsPlanningOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const toggleOverview = () => setIsOverviewOpen(!isOverviewOpen);
    const toggleHistorical = () => setIsHistoricalOpen(!isHistoricalOpen);
    const toggleCurrent = () => setIsCurrentOpen(!isCurrentOpen);
    const togglePlanning = () => setIsPlanningOpen(!isPlanningOpen);
    const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen);

    const compareAccess = userPermissions.permissions.modules.compare;
    const trackAccess = userPermissions.permissions.modules.track;
    const buildAccess = userPermissions.permissions.modules.build;

    return (
        <div className={`sidebar ${sidebarMinimized ? 'minimized' : ''}`}>
            <div className="toggle-arrow" onClick={toggleSidebar}>
                {sidebarMinimized ? '+' : '-'}
            </div>
            {!sidebarMinimized && (
            <ul>
            {compareAccess && (
                <>
                
                    {/* Overview Category */}
                    <li className="category-title" onClick={toggleOverview}>
                        Overview {isOverviewOpen ? '-' : '+'}
                    </li>
                    {isOverviewOpen && (
                        <li
                            onClick={() => setActiveComponent('Overview')}
                            className={`sub-category ${activeComponent === 'Overview' ? 'active' : ''}`}
                        >
                            Overview
                        </li>
                    )}

                    {/* Historical Category */}
                    <li className="category-title" onClick={toggleHistorical}>
                        Compare {isHistoricalOpen ? '-' : '+'}
                    </li>
                    {isHistoricalOpen && (
                        <>
                            <li
                                onClick={() => setActiveComponent('Budget')}
                                className={`sub-category ${activeComponent === 'Budget' ? 'active' : ''}`}
                            >
                                Budget
                            </li>
                            <li
                                onClick={() => setActiveComponent('Faculty')}
                                className={`sub-category ${activeComponent === 'Faculty' ? 'active' : ''}`}
                            >
                                Faculty
                            </li>
                            <li
                                onClick={() => setActiveComponent('Students')}
                                className={`sub-category ${activeComponent === 'Students' ? 'active' : ''}`}
                            >
                                Students
                            </li>
                            <li
                                onClick={() => setActiveComponent('Academics')}
                                className={`sub-category ${activeComponent === 'Academics' ? 'active' : ''}`}
                            >
                                Academics
                            </li>
                            <li
                                onClick={() => setActiveComponent('Revenue')}
                                className={`sub-category ${activeComponent === 'Revenue' ? 'active' : ''}`}
                            >
                                Revenue
                            </li>
                            <li
                                onClick={() => setActiveComponent('Debt')}
                                className={`sub-category ${activeComponent === 'Debt' ? 'active' : ''}`}
                            >
                                Debt & Capital
                            </li>
                        </>
                    )}
                    </>
                )}

                    {/* Current Category - Visible only for superAdmin or Washington or Fayette R-III */}
                    {trackAccess && (
                        <>
                            <li className="category-title" onClick={toggleCurrent}>
                                Track {isCurrentOpen ? '-' : '+'}
                            </li>
                            {isCurrentOpen && (
                                <>
                                <li
                                    onClick={() => setActiveComponent('Monthly')}
                                    className={`sub-category ${activeComponent === 'Monthly' ? 'active' : ''}`}
                                >
                                    Report
                                </li>
                                <li
                                    onClick={() => setActiveComponent('Review')}
                                    className={`sub-category ${activeComponent === 'Review' ? 'active' : ''}`}
                                >
                                    Review
                                </li>
                                </>
                            )}
                        </>
                    )}

                    {/* Build */}
                    {buildAccess && (
                        <>
                            <li className="category-title" onClick={togglePlanning}>
                                Build {isPlanningOpen ? '-' : '+'}
                            </li>
                            {isPlanningOpen && (
                                <>
                                <li
                                    onClick={() => setActiveComponent('SalaryCertified')}
                                    className={`sub-category ${activeComponent === 'SalaryCertified' ? 'active' : ''}`}
                                >
                                    Certified Schedule
                                </li>
                                <li
                                    onClick={() => setActiveComponent('SalaryClassified')}
                                    className={`sub-category ${activeComponent === 'SalaryClassified' ? 'active' : ''}`}
                                >
                                    Classified Schedule
                                </li>
                                <li
                                    onClick={() => setActiveComponent('Staff')}
                                    className={`sub-category ${activeComponent === 'Staff' ? 'active' : ''}`}
                                >
                                    Staff
                                </li>
                                <li
                                    onClick={() => setActiveComponent('RevenueInput')}
                                    className={`sub-category ${activeComponent === 'RevenueInput' ? 'active' : ''}`}
                                >
                                    Revenue
                                </li>
                                <li
                                    onClick={() => setActiveComponent('BuildBudget')}
                                    className={`sub-category ${activeComponent === 'BuildBudget' ? 'active' : ''}`}
                                >
                                    Budget
                                </li>
                                </>
                            )}
                        </>
                    )}
                    {/* Settings Category */}
                    {!boardView && (
                        <>
                            <li className="category-title" onClick={toggleSettings}>
                                Settings {isSettingsOpen ? '-' : '+'}
                            </li>
                            {isSettingsOpen && (
                                <li
                                    onClick={() => setActiveComponent('Peers')}
                                    className={`sub-category ${activeComponent === 'Peers' ? 'active' : ''}`}
                                >
                                    Peer Group
                                </li>
                            )}
                        </>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Sidebar;
