import React, { useState, useEffect } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get, set, update } from "firebase/database";
import '../../styles/Dashboard.css';
import '../../styles/SalaryScheduleClassified.css';

const initialStepDowns = Array(60).fill(0);
const initialEscalators = Array(60).fill(1.0);

const SalaryScheduleClassified = ({ selectedDistrictInfo, fiscalYear, version}) => {
    const [stepDowns, setStepDowns] = useState(initialStepDowns);
    const [escalators, setEscalators] = useState(initialEscalators);
    const [categories, setCategories] = useState([]);
    const [stepsToDisplay, setStepsToDisplay] = useState(60);
    const [defaultStepDown, setDefaultStepDown] = useState(0);
    const [defaultEscalator, setDefaultEscalator] = useState(1.0);
    const [salaryTable, setSalaryTable] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState("defaultClassified");
    const [availableScheduleNames, setAvailableScheduleNames] = useState([]);

    const salarySchedulePath = `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const staffPath = `staff/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;
    const benefitsPath = `benefitRates/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;

    useEffect(() => {
        get(ref(database, `${salarySchedulePath}/classified/`)).then(result=>setAvailableScheduleNames(Object.keys(result.val())));
     }, [selectedSchedule])

    useEffect(() => {
        loadSavedSchedule(selectedSchedule);
    }, [selectedDistrictInfo, fiscalYear, selectedSchedule]);

    useEffect(() => {
        createSalaryTable();
    }, [stepDowns, escalators, categories, stepsToDisplay]);

    const handleSelectedScheduleChange = (e) => {
        setSelectedSchedule(e.target.value)
    }

    const determineObjectCode = (type, functionCode) => {
        const conditions = {
          salary: [
            { object: "6152", condition: [1111, 1131, 1151, 1191, 1221, 1281, 3512, 3812 ] },
            { object: "6161", condition: [2552, 1191] },
            { object: "6151"}, //default
          ],
          health: [
            { object: "6240", condition: [2222] },
            { object: "6241" }, // Default
          ],
          retirement: [{ object: "6221" }], // Default
          fica: [{ object: "6231"}],
          medicare: [{ object: "6232" }], // Default
        };
    
        const typeConditions = conditions[type];
        if (!typeConditions) return null;
    
        for (const { object, condition } of typeConditions) {
          if (!condition || condition.includes(functionCode)) {
            return object;
          }
        }
    
        return null;
      };

    async function updateStaffTable () {
        
        const staffPromise = await get(ref(database, `${staffPath}`));
        const schedulePromise = await get(ref(database, `${salarySchedulePath}/classified/salaryTable`))
        const benefitRatesPromise = await(get(ref(database, `${benefitsPath}`)));
        const salarySchedule = schedulePromise.val()
        const staffData = staffPromise.val();
        const benefitRates = benefitRatesPromise.val();

        console.log('salarySchedule: ', salarySchedule)
        
        const funcCodes = Object.keys(staffData).filter(item=>item.length===4); // only get 4 digit function codes
        
        let buildings = []; let people = [];
        let lineItems = {};
        funcCodes.forEach(fc => {
          buildings = Object.keys(staffData[fc]);
          buildings.forEach(bldg => {
            if(staffData[fc][bldg]['classified']){
              staffData[fc][bldg]['classified']['totals']['salary'] = 0;
              staffData[fc][bldg]['classified']['totals']['totalCost'] = 0;
              staffData[fc][bldg]['classified']['totals']['retirement'] = 0;
              staffData[fc][bldg]['classified']['totals']['ppoCost'] = 0;
              staffData[fc][bldg]['classified']['totals']['lifeCost'] = 0;
              staffData[fc][bldg]['classified']['totals']['medicare'] = 0;
              staffData[fc][bldg]['classified']['totals']['fte'] = 0;
              people = Object.keys(staffData[fc][bldg]['classified']['staff'] || {});
              people.forEach(person_id => {

                const person = staffData[fc][bldg]['classified']['staff'][person_id];
                
                const salary_line_item = `10-${fc}-${determineObjectCode("salary", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                const retirement_line_item = `10-${fc}-${determineObjectCode("retirement", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                const medicare_line_item = `10-${fc}-${determineObjectCode("medicare", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                const health_line_item = `10-${fc}-${determineObjectCode("health", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                const fica_line_item = `10-${fc}-${determineObjectCode("fica", parseInt(fc))}-${bldg}-${person.source}-${person.project}`;
                
                if(!lineItems[salary_line_item]){
                    lineItems[salary_line_item] = 0;
                    lineItems[retirement_line_item] = 0;
                    lineItems[medicare_line_item] = 0;
                    lineItems[health_line_item] = 0;
                    lineItems[fica_line_item] = 0;
                }

                if(person.category!=''){
                  try {
                    const categoryIndex = parseInt(person.category);
                    if (salarySchedule[categoryIndex] === undefined) {
                      throw new Error(`Category ${categoryIndex} is undefined in salarySchedule`);
                    }

                    const hourlyRate = salarySchedule[parseInt(person.category)][person.step]['hourlyRate'];
                    const salary = hourlyRate*person.hours*person.fte;
                    const ppoCost = benefitRates.ppoMonthly * 12 * person.fte * (person.health || 0);
                    const lifeCost = benefitRates.lifeMonthly * 12 * person.fte * (person.life || 0);
                    const fica = salary * benefitRates.socialSecurityRate;
                    const medicare = salary * benefitRates.medicareRate;
                    const retirement = (salary + ppoCost) * benefitRates.peersRate * person.retire;
                    const totalCost = salary + ppoCost + lifeCost + medicare + retirement;
                    
                    staffData[fc][bldg]['classified']['staff'][person_id]['hourlyRate'] = hourlyRate;
                    staffData[fc][bldg]['classified']['staff'][person_id]['salary'] = salary;
                    staffData[fc][bldg]['classified']['totals']['salary'] += salary;
                    staffData[fc][bldg]['classified']['totals']['totalCost'] += totalCost;
                    staffData[fc][bldg]['classified']['totals']['retirement'] += retirement;
                    staffData[fc][bldg]['classified']['totals']['ppoCost'] += ppoCost;
                    staffData[fc][bldg]['classified']['totals']['lifeCost'] += lifeCost;
                    staffData[fc][bldg]['classified']['totals']['medicare'] += medicare;
                    staffData[fc][bldg]['classified']['totals']['fte'] += person.fte;
                    staffData[fc][bldg]['classified']['totals']['fica'] += fica;

                    lineItems[salary_line_item] += parseFloat(salary);
                    lineItems[retirement_line_item] += parseFloat(retirement);
                    lineItems[health_line_item] += parseFloat(ppoCost);
                    lineItems[medicare_line_item] += parseFloat(medicare);
                    lineItems[fica_line_item] += parseFloat(fica);

                  } catch (error) {
                    console.error(error.message);
                  }
                }
              })
            }
          })
        })

        console.log('lineItems', lineItems)
        console.log('saving to ', `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/expense/line_item/budget/${version}`)
        
        Object.keys(lineItems).forEach(acct_code => {
            if(acct_code.split('-').slice(4,5)!='undefined'){
                const budgetRef = ref(database, `budget/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/expense/${acct_code}/budget/${version}`);
                update(budgetRef, {budget: lineItems[acct_code], editable: false, fund: '10'}).then(console.log(`updated ${acct_code} value: ${lineItems[acct_code]}`))
            }
        })

      await set(ref(database, `${staffPath}`), staffData);
    }

    const loadSavedSchedule = async (scheduleName) => {
        const scheduleRef = ref(database, `${salarySchedulePath}/classified/${scheduleName}`);
    
        try {
            const snapshot = await get(scheduleRef);
            if (snapshot.exists()) {
                const savedData = snapshot.val();
                setStepDowns(savedData.stepDowns || initialStepDowns);
                setEscalators(savedData.escalators || initialEscalators);
                setDefaultStepDown(savedData.defaultStepDown || 0);
                setDefaultEscalator(savedData.defaultEscalator || 1.0);
                setCategories(savedData.categories || []);

            } else {
                alert("No schedule found for the selected fiscal year.");
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }
    };
    

    const createSalaryTable = () => {
        const tableData = [];
        for (let step = 1; step <= stepsToDisplay; step++) {
            const row = {
                step,
                stepDown: stepDowns[step - 1], //|| defaultStepDown,
                escalator: escalators[step - 1] //|| defaultEscalator,
            };

            categories.forEach((category) => {
                let currentPay = category.basePay;
                if (step > 1) {
                    const previousPay = parseFloat(tableData[step - 2][category.name] || 0);
                    currentPay = previousPay * (1 + row.escalator / 100) + row.stepDown;
                }
                row[category.name] = currentPay.toFixed(2);
            });

            tableData.push(row);
        }
        setSalaryTable(tableData);
    };

    const saveToFirebase = async () => {
        const scheduleRef = ref(database, `${salarySchedulePath}/classified/${selectedSchedule}`);

        // Transform salaryTable to the desired structure
        const transformedTable = {};
        categories.forEach((category) => {
            transformedTable[category.name] = {};
            salaryTable.forEach((row) => {
                transformedTable[category.name][`${row.step}`] = {
                    hourlyRate: row[category.name],
                    details: category.details || ""
                };
            });
        });

        const dataToSave = {
            stepDowns,
            escalators,
            defaultStepDown,
            defaultEscalator,
            categories,
            salaryTable: transformedTable,
        };

        try {
            await set(scheduleRef, dataToSave);
            alert("Salary schedule saved successfully!");

            updateStaffTable();
        } catch (error) {
            console.error("Error saving data:", error);
            alert("Failed to save salary schedule. Please try again.");
        }
    };

    const updateDefaultValues = (field, value) => {
        const numericValue = parseFloat(value) || 0;
        let customValues = [];
    
        if (field === "stepDown") {
            stepDowns.forEach((stepValue, index) => {
                if (stepValue !== defaultStepDown) {
                    customValues.push(`Step Down at Step ${index + 1}`);
                }
            });
        } else if (field === "escalator") {
            escalators.forEach((escValue, index) => {
                if (escValue !== defaultEscalator) {
                    customValues.push(`Escalator at Step ${index + 1}`);
                }
            });
        }
    
        if (customValues.length > 0) {
            const confirmMessage = `You are about to overwrite custom values, are you sure you want to continue? Custom values at:\n${customValues.join('\n')}`;
            if (!window.confirm(confirmMessage)) {
                return; // Exit if the user cancels
            }
        }
    
        if (field === "stepDown") {
            setDefaultStepDown(numericValue);
            setStepDowns(Array(stepsToDisplay).fill(numericValue));
        } else if (field === "escalator") {
            setDefaultEscalator(numericValue);
            setEscalators(Array(stepsToDisplay).fill(numericValue));
        }
    };
    

    const addCategory = () => {
        setCategories([...categories, { name: "", basePay: 0, details: "" }]);
    };

    const updateCategory = (index, field, value) => {
        const updatedCategories = [...categories];
        updatedCategories[index][field] = field === "basePay" ? parseFloat(value) || 0 : value;
        setCategories(updatedCategories);
    };

    const removeCategory = (index) => {
        if (window.confirm("Are you sure you want to delete this category?")) {
            setCategories(categories.filter((_, i) => i !== index));
        }
    };

    const handleInputChange = (index, field, value) => {
        const numericValue = value === "" ? null : parseFloat(value);
    
        if (field === "stepDown") {
            const updatedStepDowns = [...stepDowns];
            updatedStepDowns[index] = numericValue;
            setStepDowns(updatedStepDowns);
        } else if (field === "escalator") {
            const updatedEscalators = [...escalators];
            updatedEscalators[index] = numericValue;
            setEscalators(updatedEscalators);
        }
    };
    
    

    return (
        <div className = "classified-staff-container" style={{ padding: '20px' }}>
            <h3>Manage Classified Staff Categories</h3>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <label>Schedule Name:</label>
                    <select
                        id="schedule-select"
                        value={selectedSchedule}
                        onChange={(event) => handleSelectedScheduleChange(event)}
                    >
                        <option value="" disabled>
                        Select Schedule Name
                        </option>
                        {availableScheduleNames?.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                        ))}
                    </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

                <button onClick={addCategory} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>
                    Add Category
                </button>
                <button onClick={saveToFirebase} style={{ backgroundColor: '#007BFF', color: '#fff', borderRadius: '4px', border: 'none', padding: '10px', cursor: 'pointer' }}>
                    Save Schedule
                </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <label style={{ marginRight: '10px' }}>Default Step Down ($):</label>
                <input
                    type="number"
                    value={defaultStepDown}
                    onChange={(e) => updateDefaultValues("stepDown", e.target.value)}
                    style={{ marginRight: '20px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                />
                <label style={{ marginRight: '10px' }}>Default Step Down (%):</label>
                <input
                    type="number"
                    value={defaultEscalator}
                    onChange={(e) => updateDefaultValues("escalator", e.target.value)}
                    style={{ marginRight: '20px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                />
                <label style={{ marginRight: '10px' }}>Steps to Display:</label>
                <input
                    type="number"
                    value={stepsToDisplay}
                    onChange={(e) => setStepsToDisplay(parseInt(e.target.value))}
                    style={{ padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px', width: '60px' }}
                />
            </div>
            <table className="classified-staff-table" >
                <thead>
                    {/* First Header Row with Input Fields */}
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {categories.map((category, index) => (
                            <th key={index} style={{ border: '1px solid #b3c7d6', padding: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Category Name"
                                        value={category.name}
                                        onChange={(e) => updateCategory(index, "name", e.target.value)}
                                        style={{ marginBottom: '5px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Base Pay"
                                        value={category.basePay}
                                        onChange={(e) => updateCategory(index, "basePay", e.target.value)}
                                        style={{ marginBottom: '5px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                    />
                                    <textarea
                                        placeholder="Details & Notes"
                                        value={category.details}
                                        onChange={(e) => updateCategory(index, "details", e.target.value)}
                                        rows={5}
                                        style={{ resize: 'none', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                    />
                                    <button
                                        onClick={() => removeCategory(index)}
                                        style={{ marginTop: '5px', padding: '5px', backgroundColor: '#ff4d4d', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </th>
                        ))}
                    </tr>
                    {/* Second Header Row with Column Names */}
                    <tr>
                        <th style={{ border: '1px solid #b3c7d6', padding: '10px' }}>Step</th>
                        <th style={{ border: '1px solid #b3c7d6', padding: '10px' }}>Step Down ($)</th>
                        <th style={{ border: '1px solid #b3c7d6', padding: '10px' }}>Step Down (%)</th>
                        {categories.map((category, index) => (
                            <th key={index} style={{ border: '1px solid #b3c7d6', padding: '10px' }}>{category.name || "Category Name"}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {salaryTable.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #b3c7d6', padding: '10px' }}>{row.step}</td>
                            <td style={{ border: '1px solid #b3c7d6', padding: '10px' }}>
                                <input
                                    type="number"
                                    value={row.stepDown}
                                    onChange={(e) => handleInputChange(index, "stepDown", e.target.value)}
                                    style={{ padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                />
                            </td>
                            <td style={{ border: '1px solid #b3c7d6', padding: '10px' }}>
                                <input
                                    type="number"
                                    value={row.escalator ?? ""}
                                    onChange={(e) => handleInputChange(index, "escalator", e.target.value)}
                                    style={{ padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                />
                            </td>
                            {categories.map((category) => (
                                <td key={category.name} style={{ border: '1px solid #b3c7d6', padding: '10px' }}>{row[category.name]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SalaryScheduleClassified;
