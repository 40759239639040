import React, { useState, useEffect } from "react";
import NestedStaffManagementTable from "./NestedStaffManagementTable";
import "../../styles/StaffManagement.css";
import { database } from '../../firebaseConfig';
import { ref, set, get} from "firebase/database";

const StaffManagementTable = ({ selectedDistrictInfo, fiscalYear, version }) => {

  const medicareRate = 0.0145;
  const certifiedRetirement = 0.145;
  const socialSecurityRate = 0.062;
  const peersRate = 0.0686;
  const districtCode = selectedDistrictInfo["DISTRICT_COUNTY_CODE"];
  const [lifeMonthly, setLifeMonthly] = useState(null);
  const [ppoMonthly, setPpoMonthly] = useState(null);
  const [dentalMonthly, setDentalMonthly] = useState(null);
  const [HSAMonthly, setHSAMonthly] = useState(null);
  const [visionMonthly, setVisionMonthly] = useState(null);
  const [stepChange, setStepChange] = useState(0);

  const benefitRatesPath = `benefitRates/${districtCode}/${fiscalYear}/${version}`;

  useEffect(() => {
    async function fetchBenefitRates() {
      try {
        const benefitRatesSnapshot = await get(ref(database, benefitRatesPath));
        const benefitRates = benefitRatesSnapshot.val();

        if (benefitRates) {
          setLifeMonthly(benefitRates.lifeMonthly || null);
          setPpoMonthly(benefitRates.ppoMonthly || null);
          setDentalMonthly(benefitRates.dentalMonthly || null);
          setHSAMonthly(benefitRates.HSAMonthly || null);
          setVisionMonthly(benefitRates.visionMonthly || null);
        } else {
          console.warn('No benefit rates found for the given parameters.');
        }
      } catch (error) {
        console.error('Error fetching benefit rates:', error);
      }
    }

    fetchBenefitRates();
  }, [database, districtCode, fiscalYear, version]);


  const incrementSteps = () => {
    setStepChange((prev) => prev + 1);
  };

  const decrementSteps = () => {
    setStepChange((prev) => prev - 1);
  };

  async function saveBenefitsValues() {
    const benefitsRef = ref(
      database,
      benefitRatesPath
    );

    const benefitsData = {
      lifeMonthly, 
      ppoMonthly,
      dentalMonthly,
      HSAMonthly,
      visionMonthly,
      medicareRate,
      certifiedRetirement,
      socialSecurityRate,
      peersRate
    }
    await set(benefitsRef, benefitsData)
    return
  }
 
  return (
    
    <div className="staff-management-container">
    <h1>Staff Management</h1>
      <div className="inputs-card">
        <h3>Benefits</h3>
        <div className="inputs-container">
          <div>
            <label>Life Insurance Monthly:</label>
            <input
              type="number"
              value={lifeMonthly || ''}
              onChange={(e) => setLifeMonthly(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div>
            <label>Health Insurance Monthly:</label>
            <input
              type="number"
              value={ppoMonthly || ''}
              onChange={(e) => setPpoMonthly(parseFloat(e.target.value) || 0)}
            />
          </div>
          
        </div>
        <div className = 'benefit-values-actions'>
        <button onClick={() => {saveBenefitsValues()}}>save</button>
        </div>
        
      </div>
        <NestedStaffManagementTable
          fiscalYear={fiscalYear}
          districtCode={districtCode}
          version={version}
          lifeMonthly={lifeMonthly}
          ppoMonthly={ppoMonthly}
          medicareRate={medicareRate}
          certifiedRetirement={certifiedRetirement}
          socialSecurityRate={socialSecurityRate}
          peers={peersRate}
        />
    </div>
          )}

export default StaffManagementTable;
