import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";

const formatDollars = (num) => `$${Math.round(num).toLocaleString()}`;

const OBJECT_CODES = {
  6111: "Regular Cert Salaries",
  6112: "Administrator Salaries", 
  6121: "Substitute Salaries",
  6122: "Other Part-Time Cert Salaries",
  6131: "Cert Staff Supplemental Pay",
  6141: "Cert Employees Unused Leave and/or Severance Pay"
};

const CertifiedEmployeeTable = ({
    districtCode,
    fiscalYear,
    version,
    ppoMonthly,
    lifeMonthly,
    medicareRate,
    buildingCode,
    functionCode,
    certifiedRetirement,
    onDataUpdate,
  }) => {

  const salarySchedulePath = `salarySchedules/${districtCode}/${fiscalYear}/${version}/certified`;
  const staffPath = `staff/${districtCode}/${fiscalYear}/${version}`;

  const [Rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [objectCode, setObjectCode] = useState("");
  const [availableSalarySchedules, setAvailableSalarySchedules] = useState([]);
  const [fund, setFund] = useState("");

  const determineSource = (func_code) => {
    let default_source
    if([1311, 2122, 2132, 2211, 2222, 2321, 2411, 2329].includes(func_code)){
      default_source = 1;
    } else {
      default_source = 3;
    }
    return default_source
  }

  const determineFundCode = (fund) => {
    let fund_code = 10;
      switch(fund){
        case 'GENERAL_FUND':
          fund_code = 10;
          break;
        case 'SPECIAL_REVENUE':
          fund_code = 20;
          break;
        case 'DEBT_SERVICE_FUND':
          fund_code = 30;
          break;
        case 'CAPITAL_FUND':
          fund_code = 40;
          break;
      }
      return fund_code
  }

  const handleDeleteEmployee = async (row) => {
    const updatedRows = Rows.filter((r) => r.id !== row.id);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (!confirmDelete) return;

    // Remove the specific row from Firebase
    const rowRef = ref(
      database,
      `${staffPath}/${functionCode}/${buildingCode}/certified/staff/${row.id}`
    );
    await set(rowRef, null);

    // Initialize aggregated totals
    let aggregated = {
      fte: 0,
      salary: 0,
      extendedContract: 0,
      careerLadder: 0,
      totalCost: 0,
      retirement: 0,
      medicare: 0,
      benefits: 0,
    };

    // If no rows remain, remove the totals node
    if (updatedRows.length === 0) {
      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/certified/totals`
      );
      await set(aggregateRef, null);
      // Clear all keyTotals when no rows remain
      //await saveKeyTotals([], districtCode, fiscalYear, functionCode, buildingCode);
    } else {
      // Recalculate totals
      aggregated = updatedRows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.extendedContract += row.extendedContract || 0;
          acc.careerLadder += row.careerLadder || 0;
          acc.totalCost += row.totalCost || 0;
          acc.retirement += (row.salary + row.extendedContract + row.careerLadder) * certifiedRetirement;
          acc.medicare += (row.salary + row.extendedContract + row.careerLadder) * medicareRate;
          acc.benefits +=
            ppoMonthly * 12 * row.fte * (row.health || 0) +
            lifeMonthly * 12 * row.fte * (row.life || 0);
          return acc;
        },
        {
          fte: 0,
          salary: 0,
          extendedContract: 0,
          careerLadder: 0,
          totalCost: 0,
          retirement: 0,
          medicare: 0,
          benefits: 0,
        }
      );

      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/certified/totals`
      );
      await set(aggregateRef, {
        ...aggregated,
        salaryWithExtendedContract: aggregated.salary + aggregated.extendedContract + aggregated.careerLadder,
      });
    }

    // Update local state
    setRows(updatedRows);
  }

  const determineObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6112", condition: [2211, 2411, 2321] }, // administrators
        { object: "6131", condition: [1411, 1421] },
        { object: "6111" }, // Default
      ],
      health: [
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6211" }], // Default
      medicare: [{ object: "6232" }], // Default
    };

    const typeConditions = conditions[type];
    if (!typeConditions) return null;

    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }

    return null;
  };


  const [notification, setNotification] = useState("");

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setObjectCode(row.objectCode || determineObjectCode("salary", functionCode));
    setFund(row.fund || "SPECIAL_REVENUE");
    setModalOpen(true);
  };

  const saveDetails = (selectedRow, modalFields) => {

    modalFields.forEach(item=> {
      updateRow(selectedRow.id, item, selectedRow[item])
    })

    setModalOpen(false);
  };

  // Use useEffect to call saveDataToFirebase after Rows is updated
  useEffect(() => {
    if (Rows.some(row => row.hasChanged)) {
      saveDataToFirebase();
    }
  }, [Rows]);

  useEffect(() => {

    // Get available names of salary schedules for details drop down
    get(ref(database, `${salarySchedulePath}/`)).then(result=>{
      setAvailableSalarySchedules(result.val())
    });

  }, [districtCode, fiscalYear, salarySchedulePath]);

  const fetchSalary = async (scheduleToUse, educationalStatus, yearsInEducation) => {
    if (!educationalStatus || yearsInEducation === null || yearsInEducation === undefined)
      return 0;

    try {
      const salaryRef = ref(
        database,
        `${salarySchedulePath}/${scheduleToUse}/salarySchedule/${educationalStatus}/${yearsInEducation-1}/salary`
      );
      const snapshot = await get(salaryRef);
      if (snapshot.exists()) return Number(snapshot.val()) || 0;
      console.error("No salary found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching salary: ", error);
      return 0;
    }
  };

  const addRow = () => {
    console.log('addRow: ', determineSource(functionCode))
    const newRow = {
      id: Date.now(),
      last: "",
      first: "",
      fte: 1,
      life: 0,
      health: 0,
      position: "",
      educationalStatus: "",
      yearsInEducation: 1,
      salary: 0,
      extendedContract: 0,
      careerLadder: 0,
      totalCost: 0,
      project: '00000',
      source: determineSource(functionCode),
      fund: 'SPECIAL_REVENUE',
      object: 6111,
      manualSalary: false,
      scheduleToUse: Object.keys(availableSalarySchedules)[0] || ""
    };
    setRows([...Rows, newRow]);
  };

  const updateRow = async (id, key, value) => {
    let updatedRow = null;
  
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const updatedValue =
          key === "yearsInEducation" && value < 1
            ? 1
            : ["fte", "extendedContract", "careerLadder", "salary"].includes(key)
            ? !isNaN(value) ? parseFloat(value) : 0
            : value;
  
        updatedRows[rowIndex] = { 
          ...row, 
          [key]: updatedValue,
          hasChanged: true,
          manualSalary: 
            key === "educationalStatus" 
              ? value === "manualEntry" 
              : row.manualSalary || false,
        };
  
        updatedRow = updatedRows[rowIndex]; // Capture updated row immediately
      }
  
      return updatedRows;
    });
  
    // Use a timeout to wait for the state update
    setTimeout(async () => {
      if (updatedRow) {
        const baseSalary =
          ["educationalStatus", "yearsInEducation", "scheduleToUse"].includes(key)
            ? await fetchSalary(
                updatedRow.scheduleToUse,
                key === "educationalStatus" ? value : updatedRow.educationalStatus,
                key === "yearsInEducation" ? Math.max(value, 1) : updatedRow.yearsInEducation
              )
            : parseInt(updatedRow.salary) / (updatedRow.fte || 1);
  
        calculateFields(id, baseSalary);
      }
    }, 0);
  };
  

  const calculateFields = (id, baseSalary) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const effectiveSalary = baseSalary * (row.fte || 1); // Calculate effective salary
        const contract = effectiveSalary + parseFloat(row.extendedContract || 0) + parseFloat(row.careerLadder || 0);
        const ppoCost = ppoMonthly * 12 * row.fte * (row.health || 0);
        const lifeCost = lifeMonthly * 12 * row.fte * (row.life || 0);
        const medicare = contract * medicareRate;
        const retirement = (contract + ppoCost) * certifiedRetirement;
        const totalCost = contract + ppoCost + lifeCost + medicare + retirement;
  
        updatedRows[rowIndex] = {
          ...row,
          salary: effectiveSalary, // Update salary to reflect FTE
          totalCost,
          retirement,
          medicare,
          lifeCost,
          ppoCost
        };
      }
  
      return updatedRows;
    });
  };

  const saveKeyTotals = async (Rows, districtCode, fiscalYear, functionCode, buildingCode) => {
      try {
        if (Rows.length === 0) {
          console.log("No rows to process for key totals.");
          return;
        }
      
        // Initialize an object to store aggregated totals by dynamicKey, fund, and component
        const aggregatedKeyFundTotals = {};
    
        // Step 2: Loop through rows to calculate totals
        Rows.forEach((row) => {
          // Define the components and their respective costs
          const components = {
            salary: (row.contract || row.salary || 0) + row.extendedContract,
            retirement: row.retirement,
            medicare: row.medicare,
            health: row.ppoCost + row.lifeCost
          };
    
          // Process each component
          Object.entries(components).forEach(([key, value]) => {
            // Determine the object code for the component
            const objectCode =
              key === "salary"
                ? row.objectCode || determineObjectCode(key, functionCode) // Use modal-overwritten value for salary
                : determineObjectCode(key, functionCode); // Use dynamic determination for other components
    
            // Generate the dynamic key
            const dynamicKey = `${determineFundCode(row.fund)}-${functionCode}-${objectCode}-${buildingCode}-${row.source || 3}-${
              row.project || "00000"
            }`;
    
            // Initialize the structure for this dynamic key and fund if not already present
            if (!aggregatedKeyFundTotals[dynamicKey]) {
              aggregatedKeyFundTotals[dynamicKey] = {};
            }
            if (!aggregatedKeyFundTotals[dynamicKey]['budget']) {
              aggregatedKeyFundTotals[dynamicKey]['budget'] = 0;
            }
    
            // Add the component's cost to the correct dynamic key and fund
            aggregatedKeyFundTotals[dynamicKey]['budget'] += value || 0;
          });
        });

    
        // Step 3: Write the aggregated totals to Firebase
        const writePromises = Object.entries(aggregatedKeyFundTotals).map(
          async ([dynamicKey, budgetData]) => {
              const keyTotalRef = ref(
                  database,
                  `budget/${districtCode}/${fiscalYear}/expense/${dynamicKey}/budget/${version}`
              );
              await update(keyTotalRef, {...budgetData, editable: false});
          }
      );
  
      await Promise.all(writePromises);
  } catch (error) {
      console.error("Error saving key totals: ", error);
  }
  };
  
  // Main save function
  const saveDataToFirebase = async () => {
    try {
      // 1. Save individual staff data
      const saveRowPromises = Rows.filter((row) => row.hasChanged).map((row) => {
        const rowRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/certified/staff/${row.id}`
        );
        return update(rowRef, row); // Use update instead of set for partial updates
      });
      await Promise.all(saveRowPromises);

  
      // 2. Calculate aggregate totals
      const aggregated = Rows.reduce(
        (acc, row) => {
          const contract = (row.salary || 0) + (row.extendedContract || 0) + (row.careerLadder || 0);
          const ppoCost = ppoMonthly * 12 * row.fte * (row.health || 0);
          const lifeCost = lifeMonthly * 12 * row.fte * (row.life || 0);
          const medicare = contract * medicareRate;
          const retirement = (contract + ppoCost) * certifiedRetirement;
  
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.extendedContract += row.extendedContract || 0;
          acc.careerLadder += row.careerLadder || 0;
          acc.totalCost += contract + ppoCost + lifeCost + medicare + retirement;
          acc.ppoCost += ppoCost;
          acc.lifeCost += lifeCost;
          acc.medicare += medicare;
          acc.retirement += retirement;
          return acc;
        },
        {
          fte: 0,
          salary: 0,
          extendedContract: 0,
          careerLadder: 0,
          totalCost: 0,
          ppoCost: 0,
          lifeCost: 0,
          medicare: 0,
          retirement: 0,
        }
      );
  
      const aggregateRef = ref(
        database,
        `${staffPath}/${functionCode}/${buildingCode}/certified/totals`
      );
      await set(aggregateRef, aggregated);
  
      // 3. Save key totals
      await saveKeyTotals(Rows, districtCode, fiscalYear, functionCode, buildingCode);
      
      // Reset `hasChanged` for all rows
      setRows((prevRows) =>
        prevRows.map((row) => ({ ...row, hasChanged: false }))
      );

      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving certified data: ", error);
      console.log('error: ', error)
    }
  };
  
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        // Fetch individual staff rows
        const rowsRef = ref(
          database,
          `${staffPath}/${functionCode}/${buildingCode}/certified/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const rows = Object.values(data); // Convert the fetched object into an array
          setRows(rows); // Populate the table rows
        }
      } catch (error) {
        console.error("Error loading staff data: ", error);
      }
    };
    setTimeout(() => {
      fetchStaffData();
    }, 0);
  }, [districtCode, fiscalYear, functionCode, buildingCode, staffPath]);  

  useEffect(() => {
    const aggregated = Rows.reduce(
      (acc, row) => {
        acc.fte += row.fte || 0;
        acc.salary += row.salary || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { fte: 0, salary: 0, totalCost: 0 }
    );
  
    onDataUpdate(aggregated); // Notify parent
  }, [Rows]); // Only re-run when `Rows` changes
  

  return (
    <div className="certified-staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Certified Roles</h3>
      <table className="certified-staff-table">
      <colgroup>
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "10px" }} />
        <col style={{ width: "10px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
        <col style={{ width: "20px" }} />
      </colgroup>
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>FTE</th>
            <th>Benefits</th>
            <th>Position</th>
            <th>Education</th>
            <th>Step</th>
            <th>Salary</th>
            <th>Extended Contract</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  type="text"
                  value={row.last}
                  onChange={(e) =>
                    updateRow(row.id, "last", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.first}
                  onChange={(e) =>
                    updateRow(row.id, "first", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.fte}
                  min="0"
                  max="1"
                  step="0.01"
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);

                    // Auto-correct values outside the range [0, 1]
                    if (value > 1) value = 1;
                    if (value < 0) value = 0;

                    // Update the input field and application state
                    e.target.value = value; // Correct the displayed value
                    updateRow(row.id, "fte", value);
                  }}
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <div className = "benefits-checkboxes">
                  <div className = "life-checkbox">
                    <input
                      type="checkbox"
                      checked={!!row.life}
                      onChange={(e) => {
                        updateRow(row.id, "life", e.target.checked ? 1 : 0);
                      }}
                      onBlur={() => {
                        if (row.life && row.hasChanged) saveDataToFirebase();
                      }}
                    /> Life
                  </div>
                  <div className = "health-checkbox">
                    <input
                      type="checkbox"
                      checked={!!row.health}
                      onChange={(e) => {
                        updateRow(row.id, "health", e.target.checked ? 1 : 0);
                      }}
                      onBlur={() => {
                        if (row.health && row.hasChanged) saveDataToFirebase();
                      }}
                    /> Health
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) =>
                    updateRow(row.id, "position", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <select
                  value={row.educationalStatus}
                  onChange={(e) =>
                    updateRow(row.id, "educationalStatus", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                >
                  <option value="">Select</option>
                  {(availableSalarySchedules[row.scheduleToUse]?.['salarySchedule'] || {}) &&
                    Object.keys(availableSalarySchedules[row.scheduleToUse]?.['salarySchedule'] || {}).map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                  ))}
                  <option key={'manualEntry'} value={'manualEntry'}>{'Manual Entry'}</option>
                </select>
              </td>
              <td>
                {row.manualSalary ? ("") : (<input
                  type="number"
                  value={row.yearsInEducation}
                  onChange={(e) =>
                    updateRow(row.id, "yearsInEducation", parseInt(e.target.value, 10))
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />)}
              </td>
              
              <td>
                {row.manualSalary ? (
                  <input 
                    type="text"
                    value={row.salary}
                    onChange={(e) =>
                      updateRow(row.id, "salary", e.target.value)
                    }
                    onBlur={() => {
                      if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                    }}
                  />
                ) : (
                  formatDollars(row.salary)
                )}
              </td>
              <td>
                <input
                  type="number"
                  value={row.extendedContract}
                  onChange={(e) =>
                    updateRow(row.id, "extendedContract", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
              <button
                onClick={async () => {
                  handleDeleteEmployee(row)
                }}
              >
                Delete
              </button>
              <button
                  onClick={() => openDetailsModal(row)}
                  className="details-btn"
                >
                  Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">Totals</td>
          <td>{Rows.reduce((sum, row) => sum + (row.fte || 0), 0).toFixed(2)}</td>
          <td>{Rows.reduce((sum, row) => sum + (row.health + row.life || 0), 0)}</td>
          <td></td>
          <td></td>
          <td>{Rows.reduce((sum, row) => sum + (row.yearsInEducation || 0), 0)}</td>
          <td>{formatDollars(Rows.reduce((sum, row) => sum + (row.salary || 0), 0))}</td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.extendedContract || 0), 0)
            )}
          </td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.totalCost || 0), 0)
            )}
          </td>
          <td></td>
        </tr>
      </tfoot>
      </table>
      {/***** Employee details modal ******/}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h4>Edit Object Code & Fund</h4>
            <label>
              Project:
              <input
                type="text"
                value={selectedRow?.project || "00000"}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    project: e.target.value,
                  }))
                }
              />
            </label>
            <label>
              Source:
              <input
                type="number"
                value={selectedRow?.source || 3}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    source: parseInt(e.target.value, 10),
                  }))
                }
              />
            </label>
            <label>
              Object Code For Salary:
              <select
                value={objectCode}
                onChange={(e) => setObjectCode(e.target.value)}
              >
                {Object.entries(OBJECT_CODES).map(([code, label]) => (
                  <option key={code} value={code}>
                    {code} - {label}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fund For Total Compensation:
              <select
                value={fund}
                onChange={(e) => setFund(e.target.value)}
              >
                <option value="GENERAL_FUND">GENERAL_FUND</option>
                <option value="SPECIAL_REVENUE">SPECIAL_REVENUE</option>
                <option value="CAPITAL_FUND">CAPITAL_FUND</option>
                <option value="DEBT_SERVICE_FUND">DEBT_SERVICE_FUND</option>
              </select>
            </label>
            <label>
              Salary Schedule:
              <select
                value={selectedRow?.scheduleToUse}
                onChange={(e) => {
                  setSelectedRow((prev) => ({
                    ...prev,
                    scheduleToUse: e.target.value,
                  }));
                }
                }
              >
                {Object.keys(availableSalarySchedules).map((schedule, index) => (
                  <option key={index} value={schedule}>
                    {schedule}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Career Ladder:
                <input
                  type="number"
                  value={selectedRow.careerLadder}
                  onChange={(e) =>
                    updateRow(selectedRow.id, "careerLadder", parseFloat(e.target.value))
                  }
                  
                />
              </label>
            <div className="modal-actions">
              <button onClick={() => saveDetails(selectedRow, ['project', 'source', 'objectCode', 'fund', 'scheduleToUse'])}>Save</button>
              <button onClick={() => setModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <button onClick={addRow} className ="add-row-btn" >Add Certified Role</button>
      
    </div>
  );
};

export default React.memo(CertifiedEmployeeTable);