import React, { useState, useEffect } from 'react';
import "../../styles/BudgetTable.css";
import { database } from "../../firebaseConfig.js";
import { ref, get } from "firebase/database";
import Select from 'react-select';
import LineGraph from '../LineGraph.js'
import {sumFieldByGroup} from '../../utils'


const BudgetTable = ({ districtCode, year, month, currentMonth, currentYear, historicalData,showProjectFilter = false,showSourceFilter = false, showProgramFilter = false }) => {
  
  //const [historicalData, setHistoricalData] = useState({});  // Keyed by year
  const [labelCache, setLabelCache] = useState({});
  const [data, setData] = useState([]);
  const [net, setNet] = useState({ budget: 0, ytd: 0, value: 0, ytdPriorYear: 0 });
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [groupBy, setGroupBy] = useState('function');
  const [objectFilters, setObjectFilters] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);
  const [locationFilters, setLocationFilters] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [fundFilters, setFundFilters] = useState([]);
  const [availableFunds, setAvailableFunds] = useState([]);
  const [selectedGraphLabel, setSelectedGraphLabel] = useState("");
  const [functionCode, setFunctionCode] = useState("");
  const [historicalDataFlat, setHistoricalDataFlat] = useState([]);
  const [programFilters, setProgramFilters] = useState([]);
  const [availablePrograms, setAvailablePrograms] = useState([]);
  const [sourceFilters, setSourceFilters] = useState([]);
  const [availableSources, setAvailableSources] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [graphMetric, setGraphMetric] = useState('value');
  const monthIndexMap = {"July":1, "August":2, "September":3, "October":4, "November":5, "December":6, 
    "January":7, "February":8, "March":9, "April":10, "May":11, "June":12};

  const handleShowGraphClick = (label) => {
    setSelectedGraphLabel((prevLabel) => (prevLabel === label ? null : label));
  } 

  const flattenAndFilterData = (data, revenue_expense) => {
    let result = [];
  
    for (const year in data) {
      if (data[year][revenue_expense]) {
        for (const key in data[year][revenue_expense]) {
          const observed = data[year][revenue_expense][key]?.observed;
          if (observed) {
            for (const month in observed) {
              result.push({
                year: year,
                key: key,
                MONTH: month,
                ...observed[month]
              });
            }
          }
        }
      }
    }
  
    if (objectFilters.length > 0) {
      result = result.filter(item => objectFilters.includes(item.object));
    }
    if (locationFilters.length > 0) {
      result = result.filter(item => locationFilters.includes(item.location));
    }
    if (fundFilters.length > 0) {
      result = result.filter(item => fundFilters.includes(item.fund));
    }
    if (showProgramFilter && programFilters.length > 0) {
      result = result.filter(item => programFilters.includes(item.program));
    }
    if (showSourceFilter && sourceFilters.length > 0) {
      result = result.filter(item => sourceFilters.includes(item.source));
    }
    if (showProjectFilter && projectFilters.length > 0) {
      result = result.filter(item => projectFilters.includes(item.project));
    }

    return result;
  };

  const showGraph = (label, revenue_expense) => {

    const flattenedData = flattenAndFilterData(historicalData, revenue_expense.toLowerCase());

    //applyFilters(flattenedData)

    let lineGraphData = [];

    // If the label is not revenue or expense, filter the data to only the relevant function codes
    // If position 4 of the label is zero and position 3 is non-zero it is a number like 5110, get all account codes like 511x
    if(!['Revenue', 'Expense'].includes(label) && label.slice(3,4)=='0' && label.slice(2,3)!='0'){
      let filtData = flattenedData.filter(item => item.ACCOUNT_CODE.slice(3,6)===label.slice(0,3));
      lineGraphData = sumFieldByGroup(filtData, ['year', 'MONTH'], [graphMetric]);

    // Otherwise, if position 3&4 are zero and position 2 is non-zero it is a number like 11xx, get all account codes like 11xx
    } else if (!['Revenue', 'Expense'].includes(label) && label.slice(2,4)=='00' & label.slice(1,2)!='0') {
      let filtData = flattenedData.filter(item => item.ACCOUNT_CODE.slice(3,5)===label.slice(0,2));
      lineGraphData = sumFieldByGroup(filtData, ['year', 'MONTH'], [graphMetric]);

    // Otherwise, if position 3 is non-zero, get the specific 4 digit function code
    } else if (!['Revenue', 'Expense'].includes(label) && label.slice(3,4)!='0') {
      let filtData = flattenedData.filter(item => item.ACCOUNT_CODE.slice(3,7)===label.slice(0,4));
      lineGraphData = sumFieldByGroup(filtData, ['year', 'MONTH'], [graphMetric])

    }
    // Otherwise, it is just revenue/expense so no filtering required
    else {
      lineGraphData = sumFieldByGroup(flattenedData, ['year', 'MONTH'], [graphMetric]);
    }
    
    lineGraphData.sort((a, b) => {
      // Compare 'year' numerically in descending order
      if (a.year !== b.year) {
        return parseInt(b.year) - parseInt(a.year);
      }
      // If 'year' is the same, compare 'monthIndex' numerically in ascending order
      return monthIndexMap[a.MONTH] - monthIndexMap[b.MONTH];
    });
    

    lineGraphData = lineGraphData.filter((item) => {
      const itemYear = parseInt(item.year);
      const itemMonthIndex = monthIndexMap[item.MONTH];
      const currentMonthIndex = monthIndexMap[currentMonth];
  
      if (itemYear < currentYear) return true;
      if (itemYear === currentYear && itemMonthIndex <= currentMonthIndex) return true;
  
      return false;
      
    });
    console.log("lineGraphData",lineGraphData);

    return (
      <tr>
        <td colSpan={6} style={{ padding: '20px', position: 'relative' }}>
          <div style={{ width: '100%', height: '580px', overflow: 'hidden', position: 'relative' }}>
            <div className="flex justify-end items-center gap-4">
              <div className="inline-flex rounded-lg border border-gray-200">
                <button
                  className={`px-4 py-1 text-sm rounded-l-lg ${graphMetric === 'value' ? 'bg-blue-500 text-white' : 'bg-white text-gray-600'} hover:bg-blue-50`}
                  onClick={() => setGraphMetric('value')}
                >
                  Monthly
                </button>
                <button
                  className={`px-4 py-1 text-sm rounded-r-lg ${graphMetric === 'ytd' ? 'bg-blue-500 text-white' : 'bg-white text-gray-600'} hover:bg-blue-50`}
                  onClick={() => setGraphMetric('ytd')}
                >
                  YTD
                </button>
              </div>
            </div>
            <div style={{ width: '100%', height: '90%' }}>
              <LineGraph
                data={lineGraphData}
                yAxis={graphMetric}
                xAxis="MONTH"
                groupLabel="year"
                yAxisLabel="Dollars ($)"
                graphTitle={`${graphMetric === 'value' ? 'Monthly' : 'YTD'} ${label}`}
              />
            </div>
          </div>
        </td>
      </tr>      
    );
  };

  // Fetch labels only once when component mounts
  useEffect(() => {
    const preloadLabels = async () => {
      const paths = [
        ref(database, "deseCodeMap/expense"),
        ref(database, "deseCodeMap/revenue"),
        ref(database, "deseCodeMap/object")
      ];
      const [expenseSnapshot, revenueSnapshot, objectSnapshot] = await Promise.all(paths.map(get));

      const newLabelCache = {};
      const processLabels = (snapshot, prefix) => {
        const data = snapshot.val() || {};
        Object.entries(data).forEach(([code, details]) => {
          newLabelCache[`${prefix}/${code}`] = {
            description: `${code} - ${details.DETAIL_DESCRIPTION || "Unknown"}`,
            class: `${details.CLASS || "Unknown"} - ${details.CLASS_DESCRIPTION || "Unknown"}`,
            subclass: `${details.SUBCLASS || "Unknown"} - ${details.SUBCLASS_DESCRIPTION || "Unknown"}`
          };
        });
      };

      processLabels(expenseSnapshot, 'Function');
      processLabels(revenueSnapshot, 'Revenue');
      processLabels(objectSnapshot, 'Object');
      
      setLabelCache(newLabelCache);
    };

    preloadLabels();
  }, []);

  // Fetch historical data
  useEffect(() => {
    const fetchHistoricalData = async () => {
      
      if(Object.keys(historicalData).length>0){
        const newHistoricalData = {};
        Object.keys(historicalData).forEach(yr => {
          const yearData = historicalData[yr];
          const yearRecords = [];
          
          for (const group of ['revenue', 'expense']) {
            if (yearData[group]) {
              for (const [accountCode, accountDetails] of Object.entries(yearData[group])) {
                if (accountDetails.observed && accountDetails.observed[month]) {
                  yearRecords.push({
                    ...accountDetails.observed[month],
                    group,
                    year: yr
                  });
                }
              }
            }
          }
          
          newHistoricalData[yr] = yearRecords;
        });

        setHistoricalDataFlat(newHistoricalData);

      // Set available filters based on all historical data
      const allRecords = Object.values(newHistoricalData).flat();
      if (showProjectFilter) {
        const uniqueProjects = Array.from(new Set(allRecords.map(item => item.project)))
          .sort((a, b) => a - b)
          .map(src => ({ value: src, label: `Project ${src}` }));
        setAvailableProjects(uniqueProjects);
      }
      
      if (showSourceFilter) {
        const uniqueSources = Array.from(new Set(allRecords.map(item => item.source)))
          .sort((a, b) => a - b)
          .map(src => ({ value: src, label: `Source ${src}` }));
        setAvailableSources(uniqueSources);
      }

      if (showProgramFilter) {
        const uniquePrograms = Array.from(new Set(allRecords.map(item => item.program)))
          .sort((a, b) => a - b)
          .map(prog => ({ value: prog, label: `Program ${prog}` }));
        setAvailablePrograms(uniquePrograms);
      }
      
      const uniqueObjects = Array.from(new Set(allRecords.map(item => item.object)))
        .sort((a, b) => a - b)
        .map(obj => {
          const labelData = labelCache[`Object/${obj}`] || {};
          return { value: obj, label: labelData.description || `${obj} - Unknown` };
        });
      setAvailableObjects(uniqueObjects);

      const uniqueLocations = Array.from(new Set(allRecords.map(item => item.location)))
        .sort((a, b) => a - b)
        .map(loc => ({ value: loc, label: loc }));
      setAvailableLocations(uniqueLocations);

      const uniqueFunds = Array.from(new Set(allRecords.map(item => item.fund)))
        .sort((a, b) => a - b)
        .map(fund => ({ value: fund, label: `Fund ${fund}` }));
      setAvailableFunds(uniqueFunds);
      }

      
    };

    fetchHistoricalData();
    
  }, [districtCode, year, month]);

  useEffect(() => {

    if (Object.keys(historicalDataFlat).length>0){
      const applyFilters = (records) => {
        let filteredData = records;
        
        if (objectFilters.length > 0) {
          filteredData = filteredData.filter(item => objectFilters.includes(item.object));
        }
        if (locationFilters.length > 0) {
          filteredData = filteredData.filter(item => locationFilters.includes(item.location));
        }
        if (fundFilters.length > 0) {
          filteredData = filteredData.filter(item => fundFilters.includes(item.fund));
        }
        if (showProgramFilter && programFilters.length > 0) {
          filteredData = filteredData.filter(item => programFilters.includes(item.program));
        }
        if (showSourceFilter && sourceFilters.length > 0) {
          filteredData = filteredData.filter(item => sourceFilters.includes(item.source));
        }
        if (showProjectFilter && projectFilters.length > 0) {
          filteredData = filteredData.filter(item => projectFilters.includes(item.project));
        }

        return filteredData;
      };

      const getLabel = (group, code) => {
        const keyPrefix = group === 'revenue' ? 'Revenue' : groupBy === 'function' ? 'Function' : 'Object';
        const key = `${keyPrefix}/${code}`;
        const labelData = labelCache[key] || {};
        return {
          description: labelData.description || "Unknown",
          class: labelData.class || "Unknown",
          subclass: labelData.subclass || "Unknown",
          group: group === 'revenue' ? 'Revenue' : 'Expense',
        };
      };

      // Get current year and prior year data from historical data
      const currentYearData = historicalDataFlat[year] || [];
      const priorYearData = historicalDataFlat[year - 1] || [];



      // Apply filters to both sets
      const filteredCurrentYear = applyFilters(currentYearData);
      const filteredPriorYear = applyFilters(priorYearData);

      const groupedData = {};
      filteredCurrentYear.forEach(({ group, function: func, object, budget, ytd, value, ACCOUNT_CODE}) => {
        //console.log('forEach: ', group, func, object)
        const labelData = getLabel(group, groupBy === 'function' ? func : object);
        

        if (!groupedData[labelData.group]) {
          groupedData[labelData.group] = {
            totals: { budget: 0, ytd: 0, value: 0, ytdPriorYear: 0, budgetBalance: 0 },
            classes: {},
          };
        }

        // Update group totals
        const groupTotals = groupedData[labelData.group].totals;
        groupTotals.budget += parseFloat(budget) || 0;
        groupTotals.ytd += parseFloat(ytd) || 0;
        groupTotals.value += parseFloat(value) || 0;
        let ytdPY = sumFieldByGroup(filteredPriorYear, ["group"], ["ytd"]).filter(item=>item.group===group)
        groupTotals.ytdPriorYear = ytdPY.length>0? ytdPY[0].ytd : 0;
        groupTotals.budgetBalance = groupTotals.budget - groupTotals.ytd;

        // Update Net calculations
        const totalRevenue = groupedData["Revenue"]?.totals || { budget: 0, ytd: 0, value: 0, ytdPriorYear: 0 };
        const totalExpense = groupedData["Expense"]?.totals || { budget: 0, ytd: 0, value: 0, ytdPriorYear: 0 };
        
        setNet({
          budget: totalRevenue.budget - totalExpense.budget,
          ytd: totalRevenue.ytd - totalExpense.ytd,
          value: totalRevenue.value - totalExpense.value,
          ytdPriorYear: totalRevenue.ytdPriorYear - totalExpense.ytdPriorYear,
          budgetBalance: '-',
        });

        // Initialize and update class level
        if (!groupedData[labelData.group].classes[labelData.class]) {
          groupedData[labelData.group].classes[labelData.class] = {
            totals: { budget: 0, ytd: 0, value: 0, ytdPriorYear: 0, budgetBalance: 0 },
            subclasses: {},
          };
        }

        const classFunctionCodes = groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass] ? 
          Object.keys(groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass].functions) : [];

        const classTotals = groupedData[labelData.group].classes[labelData.class].totals;
        classTotals.budget += parseFloat(budget) || 0;
        classTotals.ytd += parseFloat(ytd) || 0;
        classTotals.value += parseFloat(value) || 0;
        ytdPY = filteredPriorYear.filter(item=>item.ACCOUNT_CODE===ACCOUNT_CODE);
        classTotals.ytdPriorYear += ytdPY.length>0 ? ytdPY[0].ytd : 0;
        classTotals.budgetBalance = classTotals.budget - classTotals.ytd;

        // Initialize and update subclass level
        if (!groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass]) {
          groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass] = {
            totals: { budget: 0, ytd: 0, value: 0, ytdPriorYear: 0, budgetBalance: 0 },
            functions: {},
          };
        }
        const subclassTotals = groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass].totals;
        subclassTotals.budget += parseFloat(budget) || 0;
        subclassTotals.ytd += parseFloat(ytd) || 0;
        subclassTotals.value += parseFloat(value) || 0;
        subclassTotals.ytdPriorYear += ytdPY.length>0 ? ytdPY[0].ytd : 0;
        subclassTotals.budgetBalance = subclassTotals.budget - subclassTotals.ytd;

        // Initialize and update function level
        if (!groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass].functions[func]) {
          groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass].functions[func] = {
            description: labelData.description,
            totals: { budget: 0, ytd: 0, value: 0, ytdPriorYear: 0, budgetBalance: 0 },
          };
        }
        const functionTotals = groupedData[labelData.group].classes[labelData.class].subclasses[labelData.subclass].functions[func].totals;
        functionTotals.budget += parseFloat(budget) || 0;
        functionTotals.ytd += parseFloat(ytd) || 0;
        functionTotals.value += parseFloat(value) || 0;
        functionTotals.ytdPriorYear += ytdPY.length>0 ? ytdPY[0].ytd : 0;
        functionTotals.budgetBalance = functionTotals.budget - functionTotals.ytd;
      });
  
    setData(groupedData);
    }
  }, [historicalDataFlat, objectFilters, locationFilters, fundFilters, programFilters, sourceFilters, projectFilters, labelCache, groupBy, year]);

  

  const toggleRowExpansion = (rowKey) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowKey)) {
        newExpandedRows.delete(rowKey);
      } else {
        newExpandedRows.add(rowKey);
      }
      return newExpandedRows;
    });
  };

  const formatCurrency = (value) => {
    return `$${Math.round(value).toLocaleString('en-US')}`;
  };

  const renderRows = (groupedData, level = 0) => {
    const backgroundColors = ['#e3f2fd', '#bbdefb', '#90caf9', '#64b5f6'];

    return Object.entries(groupedData).map(([groupKey, groupData]) => {
      const backgroundColor = backgroundColors[level % backgroundColors.length];
      return (
        <React.Fragment key={groupKey}>
          <tr
            style={{ backgroundColor, cursor: 'pointer', height: '50px' }}
            onClick={() => toggleRowExpansion(groupKey)}
          >
            <td style={{ paddingLeft: `${level * 20}px` }}>
              <span className="expand-arrow"
                style={{
                  display: 'inline-block',
                  transform: expandedRows.has(groupKey) ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.25s ease-in-out',
                  marginRight: '5px',
                  marginLeft: '5px'
                }}
              >
                ▶
              </span>
              {groupKey}
              <span
                  role="img"
                  aria-label="Show Graph"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowGraphClick(groupKey);
                  }}
                >
                  📈
            </span>
            </td>
            <td>{formatCurrency(groupData.totals.budget)}</td>
            <td>{formatCurrency(groupData.totals.value)}</td>
            <td>{formatCurrency(groupData.totals.ytd)}</td>
            <td>{formatCurrency(groupData.totals.ytdPriorYear)}</td>
            <td>{formatCurrency(groupData.totals.budgetBalance)}</td>
          </tr>
          {selectedGraphLabel === groupKey && showGraph(groupKey, groupKey)}

          {expandedRows.has(groupKey) &&
            Object.entries(groupData.classes).map(([classKey, classData]) => (
              <React.Fragment key={classKey}>
                <tr
                  style={{ backgroundColor: backgroundColors[(level + 1) % backgroundColors.length], cursor: 'pointer', height: '45px' }}
                  onClick={() => toggleRowExpansion(`${groupKey}-${classKey}`)}
                >
                  <td style={{ paddingLeft: `${(level + 1) * 20}px` }}>
                    <span className="expand-arrow"
                      style={{
                        display: 'inline-block',
                        transform: expandedRows.has(`${groupKey}-${classKey}`) ? 'rotate(90deg)' : 'rotate(0deg)',
                        transition: 'transform 0.25s ease-in-out',
                        marginRight: '5px',
                      }}
                    >
                      ▶
                    </span>
                    {classKey}
                    <span
                      role="img"
                      aria-label="Show Graph"
                      style={{ cursor: 'pointer', marginLeft: '3px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowGraphClick(classKey);
                      }}
                    >
                    📈
                    </span>
                  </td>
                  <td>{formatCurrency(classData.totals.budget)}</td>
                  <td>{formatCurrency(classData.totals.value)}</td>
                  <td>{formatCurrency(classData.totals.ytd)}</td>
                  <td>{formatCurrency(classData.totals.ytdPriorYear)}</td>
                  <td>{formatCurrency(classData.totals.budgetBalance)}</td>
                </tr>

                {selectedGraphLabel === classKey && showGraph(classKey, groupKey)}
                {expandedRows.has(`${groupKey}-${classKey}`) &&
                  Object.entries(classData.subclasses).map(([subclassKey, subclassData]) => (
                    <React.Fragment key={subclassKey}>
                      <tr
                        style={{ backgroundColor: backgroundColors[(level + 2) % backgroundColors.length], cursor: 'pointer', height: '40px' }}
                        onClick={() => toggleRowExpansion(`${groupKey}-${classKey}-${subclassKey}`)}
                      >
                        <td style={{ paddingLeft: `${(level + 2) * 20}px` }}>
                          <span
                            style={{
                              display: 'inline-block',
                              transform: expandedRows.has(`${groupKey}-${classKey}-${subclassKey}`)
                                ? 'rotate(90deg)'
                                : 'rotate(0deg)',
                              transition: 'transform 0.25s ease-in-out',
                              marginRight: '5px',
                            }}
                          >
                            ▶
                          </span>
                          {subclassKey}
                          <span
                            role="img"
                            aria-label="Show Graph"
                            style={{ cursor: 'pointer', marginLeft: '3px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShowGraphClick(subclassKey);
                            }}
                          >
                            📈
                          </span>
                        </td>
                        <td>{formatCurrency(subclassData.totals.budget)}</td>
                        <td>{formatCurrency(subclassData.totals.value)}</td>
                        <td>{formatCurrency(subclassData.totals.ytd)}</td>
                        <td>{formatCurrency(subclassData.totals.ytdPriorYear)}</td>
                        <td>{formatCurrency(subclassData.totals.budgetBalance)}</td>
                      </tr>
                      {selectedGraphLabel === subclassKey && showGraph(subclassKey, groupKey)}
                      {expandedRows.has(`${groupKey}-${classKey}-${subclassKey}`) &&
                        Object.entries(subclassData.functions || {}).map(([functionKey, functionData]) => {

                          return (
                            <React.Fragment key={functionKey}>
                          <tr
                            key={functionKey}
                            style={{ backgroundColor: '#ffffff', height: '35px' }}
                          >
                            <td style={{ paddingLeft: `${(level + 3) * 20}px` }}>
                              {functionData.description}
                              <span
                              role="img"
                              aria-label="Show Graph"
                              style={{ cursor: 'pointer', marginLeft: '3px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleShowGraphClick(functionData.description);
                                setFunctionCode(functionData.description);
                              }}
                            >
                              📈
                            </span>
                              </td>
                            
                            <td>{formatCurrency(functionData.totals.budget)}</td>
                            <td>{formatCurrency(functionData.totals.value)}</td>
                            <td>{formatCurrency(functionData.totals.ytd)}</td>
                            <td>{formatCurrency(functionData.totals.ytdPriorYear)}</td>
                            <td>{formatCurrency(functionData.totals.budgetBalance)}</td>
                          </tr>
                          {selectedGraphLabel === functionData.description && showGraph(functionData.description, groupKey)}
                          </React.Fragment>
                          )
                        }
                      )}
                        
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="budget-table-container">
      <div className="budget-table-filter" style={{ marginBottom: '20px' }}>
      <Select
          isMulti
          styles={{
              control: (provided) => ({ ...provided, textAlign: 'left' }),
              multiValue: (provided) => ({ ...provided, display: 'flex', alignItems: 'center' }),
              multiValueLabel: (provided) => ({ ...provided, textAlign: 'left' }),
              menu: (provided) => ({ ...provided, textAlign: 'left' }),
              option: (provided, state) => ({
                  ...provided,
                  textAlign: 'left',
                  backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                  color: 'black',
              }),
          }}
          options={availableFunds}
          onChange={(selectedOptions) => setFundFilters(selectedOptions.map((opt) => opt.value))}
          placeholder="Filter by Fund"
      />
      <Select
        isMulti
        styles={{
            control: (provided) => ({ ...provided, textAlign: 'left' }), // Aligns input text
            multiValue: (provided) => ({ ...provided, display: 'flex', alignItems: 'center' }),
            multiValueLabel: (provided) => ({ ...provided, textAlign: 'left' }),
            menu: (provided) => ({ ...provided, textAlign: 'left' }), // Aligns dropdown menu
            option: (provided, state) => ({
            ...provided,
            textAlign: 'left', // Aligns option text
            backgroundColor: state.isFocused ? '#f0f0f0' : 'white', // Optional: Highlighting on focus
            color: 'black', // Ensures proper text color
            }),
        }}
        options={availableObjects}
        onChange={(selectedOptions) => setObjectFilters(selectedOptions.map((opt) => opt.value))}
        placeholder="Filter by Object"
        />
        <Select
        isMulti
        styles={{
            control: (provided) => ({ ...provided, textAlign: 'left' }),
            multiValue: (provided) => ({ ...provided, display: 'flex', alignItems: 'center' }),
            multiValueLabel: (provided) => ({ ...provided, textAlign: 'left' }),
            menu: (provided) => ({ ...provided, textAlign: 'left' }),
            option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
            color: 'black',
            }),
        }}
        options={availableLocations}
        onChange={(selectedOptions) => setLocationFilters(selectedOptions.map((opt) => opt.value))}
        placeholder="Filter by Location"
        />
        {showProjectFilter && (
          <Select
            isMulti
            styles={{
              control: (provided) => ({ ...provided, textAlign: 'left' }),
              multiValue: (provided) => ({ ...provided, display: 'flex', alignItems: 'center' }),
              multiValueLabel: (provided) => ({ ...provided, textAlign: 'left' }),
              menu: (provided) => ({ ...provided, textAlign: 'left' }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'left',
                backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                color: 'black',
              }),
            }}
            options={availableProjects}
            onChange={(selectedOptions) => setProjectFilters(selectedOptions.map((opt) => opt.value))}
            placeholder="Filter by Project"
          />
        )}
        {showSourceFilter && (
          <Select
            isMulti
            styles={{
              control: (provided) => ({ ...provided, textAlign: 'left' }),
              multiValue: (provided) => ({ ...provided, display: 'flex', alignItems: 'center' }),
              multiValueLabel: (provided) => ({ ...provided, textAlign: 'left' }),
              menu: (provided) => ({ ...provided, textAlign: 'left' }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'left',
                backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                color: 'black',
              }),
            }}
            options={availableSources}
            onChange={(selectedOptions) => setSourceFilters(selectedOptions.map((opt) => opt.value))}
            placeholder="Filter by Source"
          />
        )}
        {showProgramFilter && (
          <Select
            isMulti
            styles={{
              control: (provided) => ({ ...provided, textAlign: 'left' }),
              multiValue: (provided) => ({ ...provided, display: 'flex', alignItems: 'center' }),
              multiValueLabel: (provided) => ({ ...provided, textAlign: 'left' }),
              menu: (provided) => ({ ...provided, textAlign: 'left' }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'left',
                backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                color: 'black',
              }),
            }}
            options={availablePrograms}
            onChange={(selectedOptions) => setProgramFilters(selectedOptions.map((opt) => opt.value))}
            placeholder="Filter by Program"
          />
        )}
      </div>
      <table className="budget-table">
        <thead>
          <tr>
            <th></th>
            <th>{year} Budget</th>
            <th>{month} {year}</th>
            <th>{month} {year} YTD</th>
            <th>{month} {year-1} YTD</th>
            <th>Budget Balance</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(data)}
          <tr style={{ backgroundColor: '#e0e0e0', fontWeight: 'bold' }}>
            <td style={{ textAlign: 'left', paddingLeft: '10px' }}>Net</td>
            <td>{formatCurrency(net.budget)}</td>
            <td>{formatCurrency(net.value)}</td>
            <td>{formatCurrency(net.ytd)}</td>
            <td>{formatCurrency(net.ytdPriorYear)}</td>
            <td>{net.budgetBalance}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BudgetTable;