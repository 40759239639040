import React, { useState, useEffect } from "react";
import { FaCalculator } from "react-icons/fa";
import { ref, get, update } from "firebase/database";
import { database } from "../firebaseConfig";
import "../styles/BudgetSummary.css"; // For custom styling

const BudgetSummary = ({currentBudget, districtCode, fiscalYear, version}) => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    WADA: "",
    SAT: "",
    transferPercent: ""
  });

  const budgetSummaryPath = `budget/${districtCode}/${fiscalYear}/summary/${version}`;
  const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
  const fiscalYearString = `${fiscalYear-1}-${fiscalYear}`;
  const estimated_revenue = currentBudget.filter(item => (item.YEAR===fiscalYear) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='revenue'))[0]
  const estimated_expense = currentBudget.filter(item => (item.YEAR===fiscalYear) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='expense'))[0]
  
  const propagateFundBalance = async () => {
    // Fetch budget summary data
    const futureYearSnapshot = await get(ref(database, `budget/${districtCode}`));
    const futureYears = Object.keys(futureYearSnapshot.val()).filter(year => (version in futureYearSnapshot.val()[year])&&(year>fiscalYear));
    
    for (let i=0; i<futureYears.length; i++){
      const currentYearSnapshot = await get(ref(database, `budget/${districtCode}/${parseInt(fiscalYear)+i}/summary/${version}`));
      const currentYearData = currentYearSnapshot.val();
      const nextYearSnapshot = await get(ref(database, `budget/${districtCode}/${parseInt(fiscalYear)+i+1}/summary/${version}`));
      const nextYearData = nextYearSnapshot.val();

      // Update beginning balance next year with Estimated Unrestricted Money of current year
      const balanceKey = `Balance June 30 ${parseInt(fiscalYear)+i}`;
      nextYearData[balanceKey] = nextYearData[balanceKey] ?? {};
      nextYearData[balanceKey] = currentYearData[`Estimated Unrestricted Money`];

      // Add revenue & expense rows for next year
      const next_year_revenue = currentBudget.filter(item => (item.YEAR===String(parseInt(fiscalYear)+i+1)) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='revenue'))[0]
      const next_year_expense = currentBudget.filter(item => (item.YEAR===String(parseInt(fiscalYear)+i+1)) && (item.version===version) && (item.LEVEL===0) && (item.LABEL==='expense'))[0]
      nextYearData['Estimated Revenue'] = {Total: parseInt(next_year_revenue.TOTAL_ALL_FUNDS),
                                           Operating: parseInt(next_year_revenue.GENERAL_FUND) + parseInt(next_year_revenue.SPECIAL_REVENUE),
                                           General: parseInt(next_year_revenue.GENERAL_FUND),
                                           Teacher: parseInt(next_year_revenue.SPECIAL_REVENUE),
                                           Debt: parseInt(next_year_revenue.DEBT_SERVICE),
                                           Capital: parseInt(next_year_revenue.CAPITAL_PROJECTS)}

      nextYearData['Estimated Expenditures'] = {Total: parseInt(next_year_expense.TOTAL_ALL_FUNDS),
                                                Operating: parseInt(next_year_expense.GENERAL_FUND) + parseInt(next_year_expense.SPECIAL_REVENUE),
                                                General: parseInt(next_year_expense.GENERAL_FUND),
                                                Teacher: parseInt(next_year_expense.SPECIAL_REVENUE),
                                                Debt: parseInt(next_year_expense.DEBT_SERVICE),
                                                Capital: parseInt(next_year_expense.CAPITAL_PROJECTS)}

      nextYearData['Revenue - Expenditures'] = {Total: parseInt(next_year_revenue.TOTAL_ALL_FUNDS) - parseInt(next_year_expense.TOTAL_ALL_FUNDS),
                                                Operating: (parseInt(next_year_revenue.GENERAL_FUND) + parseInt(next_year_revenue.SPECIAL_REVENUE))-(parseInt(next_year_expense.GENERAL_FUND) + parseInt(next_year_expense.SPECIAL_REVENUE)),
                                                General: parseInt(next_year_revenue.GENERAL_FUND) - parseInt(next_year_expense.GENERAL_FUND),
                                                Teacher: parseInt(next_year_revenue.SPECIAL_REVENUE) - parseInt(next_year_expense.SPECIAL_REVENUE),
                                                Debt: parseInt(next_year_revenue.DEBT_SERVICE) - parseInt(next_year_expense.DEBT_SERVICE),
                                                Capital: parseInt(next_year_revenue.CAPITAL_PROJECTS) - parseInt(next_year_expense.CAPITAL_PROJECTS)}

      nextYearData['Balance Prior to Transfer'] = {Total: nextYearData['Revenue - Expenditures'].Total + nextYearData[balanceKey].Total,
                                                   Operating: nextYearData['Revenue - Expenditures'].Operating + nextYearData[balanceKey].Operating,
                                                   General: nextYearData['Revenue - Expenditures'].General + nextYearData[balanceKey].General,
                                                   Teacher: nextYearData['Revenue - Expenditures'].Teacher + nextYearData[balanceKey].Teacher,
                                                   Debt: nextYearData['Revenue - Expenditures'].Debt + nextYearData[balanceKey].Debt,
                                                   Capital: nextYearData['Revenue - Expenditures'].Capital + nextYearData[balanceKey].Capital}

      await update(ref(database, `budget/${districtCode}/${parseInt(fiscalYear)+i+1}/summary/${version}`), nextYearData);
    }
    
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch budget summary data
        const summaryRef = ref(database, budgetSummaryPath);
        const summarySnapshot = await get(summaryRef);
        const summaryData = summarySnapshot.val();

        // Fetch revenue metadata
        const revenueRef = ref(database, revenueMetadataPath);
        const revenueSnapshot = await get(revenueRef);
        const revenueData = revenueSnapshot.val();
        const assessedValuation = {};
        ['residential','commercial','personal','agriculture'].forEach(prop_type=>{
          assessedValuation[prop_type] = 0;
          Object.keys(revenueData['estAssessedValue']).forEach(county => {
            assessedValuation[prop_type] += revenueData['estAssessedValue'][county][prop_type]
          })
        })

        const beg_fb_capital = parseInt(summaryData[`Balance June 30 ${parseInt(fiscalYear)-1}`]?.Capital);
        const beg_fb_debt = parseInt(summaryData[`Balance June 30 ${parseInt(fiscalYear)-1}`]?.Debt);
        const beg_fb_teacher = parseInt(summaryData[`Balance June 30 ${parseInt(fiscalYear)-1}`]?.Teacher);
        const beg_fb_general = parseInt(summaryData[`Balance June 30 ${parseInt(fiscalYear)-1}`]?.General);

        // Transform the data into the required format
        const transformedData = [
          {
            headers: ["", "Total", "Residential", "Commercial", "Personal", "Agriculture"],
            rows: [
              {
                label: "Estimated Assessed Valuation",
                values: [
                  (assessedValuation?.agriculture || 0)+(assessedValuation?.commercial || 0)+(assessedValuation?.personal || 0)+(assessedValuation?.residential || 0),
                  (assessedValuation?.residential || 0),
                  (assessedValuation?.commercial || 0),
                  (assessedValuation?.personal || 0),
                  (assessedValuation?.agriculture || 0)
                ]
              }
            ]
          },
          {
            headers: ["", "Total", "Operating", "Special", "Debt", "Capital"],
            rows: [
              {
                label: "Tax Levy",
                values: [
                  (revenueData?.levy?.GENERAL_FUND || 0) + (revenueData?.levy?.SPECIAL_REVENUE || 0) + (revenueData?.levy?.DEBT_SERVICE_FUND || 0) + (revenueData?.levy?.CAPITAL_FUND || 0),
                  (revenueData?.levy?.GENERAL_FUND || 0) + (revenueData?.levy?.SPECIAL_REVENUE || 0),
                  (revenueData?.levy?.SPECIAL_REVENUE || 0),
                  (revenueData?.levy?.DEBT_SERVICE_FUND || 0),
                  (revenueData?.levy?.CAPITAL_FUND || 0)
                ]
              }
            ]
          },
          {
            headers: ["", "Total", "Operating", "General", "Teacher", "Debt", "Capital"],
            rows: [
              {
                label: `Balance June 30 ${parseInt(fiscalYear)-1}`,
                values: [
                  (beg_fb_general || 0) + (beg_fb_teacher || 0) + (beg_fb_debt || 0) + (beg_fb_capital || 0),
                  (beg_fb_general || 0) + (beg_fb_teacher || 0),
                  (beg_fb_general || 0),
                  (beg_fb_teacher || 0),
                  (beg_fb_debt || 0),
                  (beg_fb_capital || 0),
                ]
              },
              {
                label: `Estimated Revenue`,
                values: [
                  parseInt(estimated_revenue.TOTAL_ALL_FUNDS),
                  parseInt(estimated_revenue.GENERAL_FUND) + parseInt(estimated_revenue.SPECIAL_REVENUE),
                  parseInt(estimated_revenue.GENERAL_FUND),
                  parseInt(estimated_revenue.SPECIAL_REVENUE),
                  parseInt(estimated_revenue.DEBT_SERVICE),
                  parseInt(estimated_revenue.CAPITAL_PROJECTS),
                ]
              },
              {
                label: `Estimated Receipts and Balances`,
                values: [
                  parseInt(estimated_revenue.TOTAL_ALL_FUNDS) + (beg_fb_general || 0) + (beg_fb_teacher || 0) + (beg_fb_debt || 0) + (beg_fb_capital || 0),
                  parseInt(estimated_revenue.GENERAL_FUND) + parseInt(estimated_revenue.SPECIAL_REVENUE) + (beg_fb_general || 0) + (beg_fb_teacher || 0),
                  parseInt(estimated_revenue.GENERAL_FUND) + (beg_fb_general || 0),
                  parseInt(estimated_revenue.SPECIAL_REVENUE) + (beg_fb_teacher || 0),
                  parseInt(estimated_revenue.DEBT_SERVICE) + (beg_fb_debt || 0),
                  parseInt(estimated_revenue.CAPITAL_PROJECTS) + (beg_fb_capital || 0),
                ]
              },
              {
                label: `Estimated Expenditures`,
                values: [
                  parseInt(estimated_expense.TOTAL_ALL_FUNDS),
                  parseInt(estimated_expense.GENERAL_FUND) + parseInt(estimated_expense.SPECIAL_REVENUE),
                  parseInt(estimated_expense.GENERAL_FUND),
                  parseInt(estimated_expense.SPECIAL_REVENUE),
                  parseInt(estimated_expense.DEBT_SERVICE),
                  parseInt(estimated_expense.CAPITAL_PROJECTS),
                ]
              },
              {
                label: `Revenue - Expenditures`,
                values: [
                  parseInt(estimated_revenue.TOTAL_ALL_FUNDS) - parseInt(estimated_expense.TOTAL_ALL_FUNDS),
                  (parseInt(estimated_revenue.GENERAL_FUND) + parseInt(estimated_revenue.SPECIAL_REVENUE)) - (parseInt(estimated_expense.GENERAL_FUND) + parseInt(estimated_expense.SPECIAL_REVENUE)),
                  parseInt(estimated_revenue.GENERAL_FUND) - parseInt(estimated_expense.GENERAL_FUND),
                  parseInt(estimated_revenue.SPECIAL_REVENUE) - parseInt(estimated_expense.SPECIAL_REVENUE),
                  parseInt(estimated_revenue.DEBT_SERVICE) - parseInt(estimated_expense.DEBT_SERVICE),
                  parseInt(estimated_revenue.CAPITAL_PROJECTS) - parseInt(estimated_expense.CAPITAL_PROJECTS),
                ]
              },
              {
                label: `Balance Prior to Transfer`,
                values: [
                  ((beg_fb_general || 0) + (beg_fb_teacher || 0) + (beg_fb_debt || 0) + (beg_fb_capital || 0)) + (parseInt(estimated_revenue.TOTAL_ALL_FUNDS) - parseInt(estimated_expense.TOTAL_ALL_FUNDS)),
                  ((beg_fb_general || 0) + (beg_fb_teacher || 0)) + (parseInt(estimated_revenue.GENERAL_FUND) + parseInt(estimated_revenue.SPECIAL_REVENUE)) - (parseInt(estimated_expense.GENERAL_FUND) + parseInt(estimated_expense.SPECIAL_REVENUE)),
                  (beg_fb_general || 0) + (parseInt(estimated_revenue.GENERAL_FUND) - parseInt(estimated_expense.GENERAL_FUND)),
                  (beg_fb_teacher || 0) + (parseInt(estimated_revenue.SPECIAL_REVENUE) - parseInt(estimated_expense.SPECIAL_REVENUE)),
                  (beg_fb_debt || 0) + (parseInt(estimated_revenue.DEBT_SERVICE) - parseInt(estimated_expense.DEBT_SERVICE)),
                  (beg_fb_capital || 0) + (parseInt(estimated_revenue.CAPITAL_PROJECTS) - parseInt(estimated_expense.CAPITAL_PROJECTS)),
                ]
              },
              {
                label: "Zero Teach Fund Balance",
                values: [
                  (parseInt(summaryData[`Zero Teach Fund Balance`]?.General) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Teacher) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Debt) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Capital)) || 0,
                  (parseInt(summaryData[`Zero Teach Fund Balance`]?.General) + parseInt(summaryData[`Zero Teach Fund Balance`]?.Teacher)) || 0,
                  parseInt(summaryData[`Zero Teach Fund Balance`]?.General) || 0,
                  parseInt(summaryData[`Zero Teach Fund Balance`]?.Teacher) || 0,
                  parseInt(summaryData[`Zero Teach Fund Balance`]?.Debt) || 0,
                  parseInt(summaryData[`Zero Teach Fund Balance`]?.Capital) || 0
                ]
              },
              {
                label: "Transportation Transfer",
                values: [
                  (parseInt(summaryData[`Transportation Transfer`]?.General) + parseInt(summaryData[`Transportation Transfer`]?.Teacher) + parseInt(summaryData[`Transportation Transfer`]?.Debt) + parseInt(summaryData[`Transportation Transfer`]?.Capital)) || 0,
                  (parseInt(summaryData[`Transportation Transfer`]?.General) + parseInt(summaryData[`Transportation Transfer`]?.Teacher)) || 0,
                  parseInt(summaryData[`Transportation Transfer`]?.General) || 0,
                  parseInt(summaryData[`Transportation Transfer`]?.Teacher) || 0,
                  parseInt(summaryData[`Transportation Transfer`]?.Debt) || 0,
                  parseInt(summaryData[`Transportation Transfer`]?.Capital) || 0
                ]
              },
              {
                label: "Grant Match Transfer",
                values: [
                  (parseInt(summaryData[`Grant Match Transfer`]?.General) + parseInt(summaryData[`Grant Match Transfer`]?.Teacher) + parseInt(summaryData[`Grant Match Transfer`]?.Debt) + parseInt(summaryData[`Grant Match Transfer`]?.Capital)) || 0,
                  (parseInt(summaryData[`Grant Match Transfer`]?.General) + parseInt(summaryData[`Grant Match Transfer`]?.Teacher)) || 0,
                  parseInt(summaryData[`Grant Match Transfer`]?.General) || 0,
                  parseInt(summaryData[`Grant Match Transfer`]?.Teacher) || 0,
                  parseInt(summaryData[`Grant Match Transfer`]?.Debt) || 0,
                  parseInt(summaryData[`Grant Match Transfer`]?.Capital) || 0
                ]
              },
              {
                label: "Capital Projects Transfer",
                values: [
                  (parseInt(summaryData[`Capital Projects Transfer`]?.General) + parseInt(summaryData[`Capital Projects Transfer`]?.Teacher) + parseInt(summaryData[`Capital Projects Transfer`]?.Debt) + parseInt(summaryData[`Capital Projects Transfer`]?.Capital)) || 0,
                  (parseInt(summaryData[`Capital Projects Transfer`]?.General) + parseInt(summaryData[`Capital Projects Transfer`]?.Teacher)) || 0,
                  parseInt(summaryData[`Capital Projects Transfer`]?.General) || 0,
                  parseInt(summaryData[`Capital Projects Transfer`]?.Teacher) || 0,
                  parseInt(summaryData[`Capital Projects Transfer`]?.Debt) || 0,
                  parseInt(summaryData[`Capital Projects Transfer`]?.Capital) || 0
                ]
              },
              {
                label: "Food Service Transfer",
                values: [
                  (parseInt(summaryData[`Food Service Transfer`]?.General) + parseInt(summaryData[`Food Service Transfer`]?.Teacher) + parseInt(summaryData[`Food Service Transfer`]?.Debt) + parseInt(summaryData[`Food Service Transfer`]?.Capital)) || 0,
                  (parseInt(summaryData[`Food Service Transfer`]?.General) + parseInt(summaryData[`Food Service Transfer`]?.Teacher)) || 0,
                  parseInt(summaryData[`Food Service Transfer`]?.General) || 0,
                  parseInt(summaryData[`Food Service Transfer`]?.Teacher) || 0,
                  parseInt(summaryData[`Food Service Transfer`]?.Debt) || 0,
                  parseInt(summaryData[`Food Service Transfer`]?.Capital) || 0
                ]
              },
              {
                label: "Student Activities Transfer",
                values: [
                  (parseInt(summaryData[`Student Activities Transfer`]?.General) + parseInt(summaryData[`Student Activities Transfer`]?.Teacher) + parseInt(summaryData[`Student Activities Transfer`]?.Debt) + parseInt(summaryData[`Student Activities Transfer`]?.Capital)) || 0,
                  (parseInt(summaryData[`Student Activities Transfer`]?.General) + parseInt(summaryData[`Student Activities Transfer`]?.Teacher)) || 0,
                  parseInt(summaryData[`Student Activities Transfer`]?.General) || 0,
                  parseInt(summaryData[`Student Activities Transfer`]?.Teacher) || 0,
                  parseInt(summaryData[`Student Activities Transfer`]?.Debt) || 0,
                  parseInt(summaryData[`Student Activities Transfer`]?.Capital) || 0
                ]
              },
              {
                label: "SB 287 Transfer",
                values: [
                  (parseInt(summaryData[`SB 287 Transfer`]?.General) + parseInt(summaryData[`SB 287 Transfer`]?.Teacher) + parseInt(summaryData[`SB 287 Transfer`]?.Debt) + parseInt(summaryData[`SB 287 Transfer`]?.Capital)) || 0,
                  (parseInt(summaryData[`SB 287 Transfer`]?.General) + parseInt(summaryData[`SB 287 Transfer`]?.Teacher)) || 0,
                  parseInt(summaryData[`SB 287 Transfer`]?.General) || 0,
                  parseInt(summaryData[`SB 287 Transfer`]?.Teacher) || 0,
                  parseInt(summaryData[`SB 287 Transfer`]?.Debt) || 0,
                  parseInt(summaryData[`SB 287 Transfer`]?.Capital) || 0
                ]
              },
              {
                label: "Estimated Restricted Money",
                values: [
                  (parseInt(summaryData[`Estimated Restricted Money`]?.General) + parseInt(summaryData[`Estimated Restricted Money`]?.Teacher) + parseInt(summaryData[`Estimated Restricted Money`]?.Debt) + parseInt(summaryData[`Estimated Restricted Money`]?.Capital)) || 0,
                  (parseInt(summaryData[`Estimated Restricted Money`]?.General) + parseInt(summaryData[`Estimated Restricted Money`]?.Teacher)) || 0,
                  parseInt(summaryData[`Estimated Restricted Money`]?.General) || 0,
                  parseInt(summaryData[`Estimated Restricted Money`]?.Teacher) || 0,
                  parseInt(summaryData[`Estimated Restricted Money`]?.Debt) || 0,
                  parseInt(summaryData[`Estimated Restricted Money`]?.Capital) || 0
                ]
              },
              {
                label: `Estimated Fund Balance ${fiscalYear}`,
                values: [
                  (parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.General) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Teacher) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Debt) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Capital)) || 0,
                  (parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.General) + parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Teacher)) || 0,
                  parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.General) || 0,
                  parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Teacher) || 0,
                  parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Debt) || 0,
                  parseInt(summaryData[`Estimated Fund Balance ${fiscalYear}`]?.Capital) || 0
                ]
              },
              {
                label: `Estimated Unrestricted Money`,
                values: [
                  (parseInt(summaryData[`Estimated Unrestricted Money`]?.General) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Teacher) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Debt) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Capital)) || 0,
                  (parseInt(summaryData[`Estimated Unrestricted Money`]?.General) + parseInt(summaryData[`Estimated Unrestricted Money`]?.Teacher)) || 0,
                  parseInt(summaryData[`Estimated Unrestricted Money`]?.General) || 0,
                  parseInt(summaryData[`Estimated Unrestricted Money`]?.Teacher) || 0,
                  parseInt(summaryData[`Estimated Unrestricted Money`]?.Debt) || 0,
                  parseInt(summaryData[`Estimated Unrestricted Money`]?.Capital) || 0
                ]
              },
              {
                label: `% Unrestricted Fund Balance`,
                values: [
                  (parseInt(summaryData[`% Unrestricted Fund Balance`]?.General) + parseInt(summaryData[`% Unrestricted Fund Balance`]?.Teacher) + parseInt(summaryData[`% Unrestricted Fund Balance`]?.Debt) + parseInt(summaryData[`% Unrestricted Fund Balance`]?.Capital)) || 0,
                  summaryData[`% Unrestricted Fund Balance`]?.Operating || 0,
                  parseInt(summaryData[`% Unrestricted Fund Balance`]?.General) || 0,
                  parseInt(summaryData[`% Unrestricted Fund Balance`]?.Teacher) || 0,
                  parseInt(summaryData[`% Unrestricted Fund Balance`]?.Debt) || 0,
                  parseInt(summaryData[`% Unrestricted Fund Balance`]?.Capital) || 0
                ]
              },
            ]  
          }
        ];

        const rows = transformedData[2].rows;
        calculateRows(rows)
        setData(transformedData)

        // Save to firebase
        const data_to_save = {};
        transformedData[2]['rows'].forEach(item=> {
          data_to_save[`${item.label}`] = {};
          item.values.forEach((value, index) => {
            const header = transformedData[2].headers[index+1];
            data_to_save[`${item.label}`][`${header}`] = value;
          });
        })

        await update(ref(database, budgetSummaryPath), data_to_save);
        await propagateFundBalance();
        
      } catch (error) {
        console.error("Error fetching budget data:", error);
      }
    };

    fetchData();
  }, [fiscalYear, version, districtCode, budgetSummaryPath, revenueMetadataPath, estimated_expense, estimated_revenue]);

  const calculateRows = (rows) => {
    // Calculate totals from the transfer rows
    const transferTotals = [0, 0, 0, 0, 0, 0];
    for (let i = 6; i <= 12; i++) {
      rows[i].values.forEach((value, colIndex) => {
        transferTotals[colIndex] += parseFloat(value) || 0;
      });
    }

    // Update Estimated Fund Balance with sum of Balance Prior to Transfer and transfer totals
    const balancePriorToTransfer = rows[5].values;
    const finalBalances = balancePriorToTransfer.map((value, index) => {
      return (parseFloat(value) || 0) + (transferTotals[index] || 0);
    });

    rows[14].values = finalBalances;

    // Calculate Estimated Unrestricted Money
    // Subtract Estimated Restricted Money (row 13) from Estimated Fund Balance (row 14)
    const unrestrictedMoney = finalBalances.map((value, index) => {
      const restrictedValue = parseFloat(rows[13].values[index]) || 0;
      return (parseFloat(value) || 0) - restrictedValue;
    });

    // Add or update the Estimated Unrestricted Money row (row 15)
    if (rows.length === 15) {
      // Add the new row if it doesn't exist
      rows.push({
        label: "Estimated Unrestricted Money",
        values: unrestrictedMoney
      });
    } else {
      // Update existing row
      rows[15].values = unrestrictedMoney;
    }

    // % Unrestricted fund balance
    rows[16].values = [0, (100*rows[15].values[1]/rows[3].values[1]).toFixed(2), 0, 0, 0, 0]

  }

  const handleCellChange = (sectionIndex, rowIndex, colIndex, value) => {
    const newData = [...data];
    newData[sectionIndex].rows[rowIndex].values[colIndex] = parseFloat(value) || 0;
  
    // If we're in the third section (index 2), recalculate totals
    if (sectionIndex === 2) {
      const rows = newData[2].rows;
      calculateRows(rows)
    }

    setData(newData);
    // Take the ending fund balance and make it the beginning balance for next year, etc.
    propagateFundBalance();
  };

  const handleBlur = async (row, headers) => {
    try {
      // Create an update object based on the row label
      const updates = {};
      updates[`${row.label}`] = {};
      row.values.forEach((value, index) => {
        const header = headers[index+1];
        updates[`${row.label}`][`${header}`] = value;
      });

      // Update Firebase
      await update(ref(database, budgetSummaryPath), updates);
      await propagateFundBalance();

    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setInputValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleModalSubmit = () => {
    const transferAmount = inputValues.SAT*inputValues.WADA*inputValues.transferPercent/100;
    setInputValues((prev) => ({ ... prev, transferAmount: transferAmount}))
    // Update the data variable
    setData((prevData) => {
      const updatedData = [...prevData];
      const section = updatedData[2]; // Third section
      const sb287RowIndex = section.rows.findIndex((row) => row.label === "SB 287 Transfer");
      const updatedRow = { ...section.rows[sb287RowIndex] };
      if (sb287RowIndex !== -1) {
        // Update the 'values' array for the row
        updatedRow.values = updatedRow.values.map((value, index) => 
          index === 1 ? -transferAmount : value // negative value to column 2
        );
        updatedRow.values = updatedRow.values.map((value, index) => 
          index === 2 ? -transferAmount : value // negative value to column 2
        );
        updatedRow.values = updatedRow.values.map((value, index) => 
          index === 5 ? transferAmount : value // positive value to column 5
        );
        section.rows[sb287RowIndex] = updatedRow;
      }
      return updatedData;
    });
    
    setIsModalOpen(false); // Close modal after submission
  };

  return (
    <div className="budget-summary-container">
      <h2>Budget Summary {fiscalYearString}</h2>
      {data.map((section, sectionIndex) => (
        <table key={sectionIndex} className="budget-summary-table">
          <thead>
            <tr>
              {section.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {section.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.label}
                {row.label === "SB 287 Transfer" && (
                    <FaCalculator
                      className="calculator-icon"
                      onClick={() => setIsModalOpen(true)}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  )}
                </td>
                {row.values.map((value, colIndex) => (
                  <td key={colIndex}>
                    <input
                      type="text"
                      value={value || 0}
                      onChange={(e) =>
                        handleCellChange(sectionIndex, rowIndex, colIndex, e.target.value)
                      }
                      onBlur={(e) => handleBlur(row, section.headers)}
                      //readOnly={['Estimated Assessed Valuation', 'Tax Levy', 
                      //           'Estimated Revenue', 'Estimated Receipts and Balances', 'Estimated Expenditures', 
                      //           'Revenue - Expenditures', 'Balance Prior to Transfer'].includes(row.label) || [0,1].includes(colIndex)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
      {isModalOpen && (
        <div className="sb287-modal-overlay">
          <div className="sb287-modal">
            <form>
              <div>
                <h3>SB 287 Transfer Calculator</h3>
                <label>WADA:</label>
                <input
                  type="number"
                  value={inputValues.WADA}
                  onChange={(e) => handleInputChange("WADA", e.target.value)}
                />
              </div>
              <div>
                <label>SAT:</label>
                <input
                  type="number"
                  value={inputValues.SAT}
                  onChange={(e) => handleInputChange("SAT", e.target.value)}
                />
              </div>
              <div>
                <label>Transfer Percent:</label>
                <input
                  type="number"
                  value={inputValues.transferPercent}
                  onChange={(e) => handleInputChange("transferPercent", e.target.value)}
                  max="7"
                />
              </div>
              <button type="button" onClick={handleModalSubmit}>
                Submit
              </button>
              <button type="button" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BudgetSummary;