import React, { useState, useEffect } from 'react';
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import '../../styles/RevenueCalc.css';
import axios from "axios";

const RevenueCalc = ({selectedDistrictInfo, version, fiscalYear}) => {

  const salarySchedulePath = `salarySchedules/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}/${fiscalYear}/${version}`;

  const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
  const [levy, setLevy] = useState({ operating: 0, teacher: 0, capital: 0, debt: 0 });
  const [assessedValues, setAssessedValues] = useState([{ county: '', values: { agriculture: 0, residential: 0, commercial: 0, vacant: 0, personal: 0, localUtilityReal:0, localUtilityPersonal: 0 } }]);
  const [taxCollection, setTaxCollection] = useState({ current: 0, delinquent: 0 });
  const [propC, setPropC] = useState({ rate: 0, wada: 0 });
  const [stateRev, setStateRev] = useState({ regYearADA: 0, summerSchoolADA: 0});
  const [vocationTuition, setVocationTuition] = useState({ tuition: 0, fullTime: 0, halfTime: 0, lessPerkins: 0, lessNonTraditional: 0, expectedExpense: 0 });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [baselineSalaryGrantTotal, setBaselineSalaryGrantTotal] = useState(0);
  const [projectCode, setProjectCode] = useState("");
  const [K8orFL, setK8orFL] = useState({})

  const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://app.balancehq.com' 
  : 'http://localhost:5001';

  const calculateBaselineSalaryGrant = async () => {
    if (projectCode.length !== 5) {
      console.error("Project code must be set and be 5 digits before calculating the grant.");
      return;
    }
  
    let totalGrant = 0;
    const employeesWithPartialFTE = [];
    const dynamicKey = `0000-3-${projectCode}`;

    try {
      const certifiedRef = ref(database, `staff/${districtCode}/${fiscalYear}`);
      const certifiedSnapshot = await get(certifiedRef);

      if (certifiedSnapshot.exists()) {
        const functionCodes = Object.keys(certifiedSnapshot.val());

        for (const functionCode of functionCodes) {
          const buildingsRef = ref(database, `staff/${districtCode}/${fiscalYear}/${functionCode}`);
          const buildingsSnapshot = await get(buildingsRef);

          if (buildingsSnapshot.exists()) {
            const buildingCodes = Object.keys(buildingsSnapshot.val());

            for (const buildingCode of buildingCodes) {
              const staffRef = ref(database, `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/staff`);
              const staffSnapshot = await get(staffRef);

              if (staffSnapshot.exists()) {
                const staffList = staffSnapshot.val();

                for (const [staffKey, staffData] of Object.entries(staffList)) {
                  const { first, last, baseSalary, salary, fte, educationalStatus, yearsInEducation } = staffData;
                  const fteValue = parseFloat(fte || 0);
                  
                  if (fteValue === 1 && salary <= 40000) {
                    const salaryRef = ref(database, `${salarySchedulePath}/certified/salarySchedule/${educationalStatus}/${yearsInEducation-1}/baseSalary`);
                    const salarySnapshot = await get(salaryRef);
                    
                    if (salarySnapshot.exists()) {
                      const trueBaseSalary = parseFloat(salarySnapshot.val() || 0);
                      const grantAmount = Math.max(0, 40000 - trueBaseSalary);
                      console.log(`${first} ${last}: ${grantAmount}`);
                      totalGrant += grantAmount;
                      console.log("totalGrant", totalGrant)
                    }
                  } else if (fteValue < 1) {
                    const salaryRef = ref(database, `${salarySchedulePath}/certified/salarySchedule/${educationalStatus}/${yearsInEducation-1}/baseSalary`);
                    const salarySnapshot = await get(salaryRef);
                    const trueBaseSalary = parseFloat(salarySnapshot.val() || 0)*fteValue;
                    console.log("baseSalary",`${trueBaseSalary} ${first} ${fiscalYear} ${last} ${educationalStatus} ${yearsInEducation}`)
                    employeesWithPartialFTE.push({ first, last, fteValue, trueBaseSalary });
                  }
                }
              }
            }
          }
        }

        console.log('employeesWithPartialFTE', employeesWithPartialFTE)
        // Handle partial FTE employees
        const combinedEmployees = employeesWithPartialFTE.reduce((acc, emp) => {
          const key = `${emp.first} ${emp.last}`;
          if (!acc[key]) acc[key] = { ...emp, totalFTE: 0, totalPay: 0 };
          acc[key].totalFTE += emp.fteValue;
          acc[key].totalPay += emp.trueBaseSalary;
          return acc;
        }, {});

        console.log('combinedEmployees', combinedEmployees);

        for (const [key, employee] of Object.entries(combinedEmployees)) {
          if (employee.totalFTE === 1 && employee.totalPay <= 40000) {
            const userConfirmed = window.confirm(`Is ${key} eligible for the Baseline Salary Grant?`);
            if (userConfirmed) {
              console.log("totalGrant",totalGrant);
              console.log("employee.totalPay",employee.totalPay);
              
              totalGrant += (40000 - employee.totalPay);
              console.log("totalGrantAfterConfirm",totalGrant);
            }
          }
        }
      }

      // Save total grant to Firebase
      const grantPath = `budget/${districtCode}/${fiscalYear}/revenue/20-5341-${dynamicKey}/${version}`;
      await set(ref(database, grantPath), totalGrant);
      setBaselineSalaryGrantTotal(totalGrant);
    } catch (error) {
      console.error("Error calculating Baseline Salary Grant:", error);
    }
  };

  const handleInputChange = (event, stateSetter, key, section) => {
    const value = parseFloat(event.target.value.replace(/,/g, '')) || 0;
    stateSetter(prevState => ({ ...prevState, [key]: value }));
  };

  const handleAssessedValueChange = (event, countyIndex, key) => {
    const value = parseFloat(event.target.value.replace(/,/g, '')) || 0;
    setAssessedValues((prevState) => {
      const newState = [...prevState];
      newState[countyIndex].values[key] = value;
      return newState;
    });
  };

  const addCounty = () => {
    setAssessedValues((prevState) => [...prevState, { county: '', values: { agriculture: 0, residential: 0, commercial: 0, vacant: 0, personal: 0, localUtilityReal: 0, localUtilityPersonal: 0 } }]);
  };

  const deleteCounty = (index) => {
    setAssessedValues((prevState) => prevState.filter((_, i) => i !== index));
  };

  const calculateTotal = (values) => Object.values(values).reduce((acc, val) => acc + val, 0);

  const calculateAssessedValueTotal = () => 
    assessedValues.reduce((total, county) => total + calculateTotal(county.values), 0);

  const calculatePropCTotal = (rate, wada) => rate * wada;

  const calculateVocationTuitionTotal = () => {
    const total = (vocationTuition.tuition * vocationTuition.fullTime) + (vocationTuition.tuition * vocationTuition.halfTime * 0.5);
    return total + vocationTuition.lessPerkins + vocationTuition.lessNonTraditional - vocationTuition.expectedExpense;
  };

  const formatCurrency = (value) => value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const formatPercentage = (value) => `${value.toFixed(2)}%`;

  const totalLevy = calculateTotal(levy);
  const totalAssessedValue = calculateAssessedValueTotal();
  const currentTaxRevenue = ((totalAssessedValue * totalLevy / 100) * (taxCollection.current / 100));
  const delinquentTaxRevenue = ((totalAssessedValue * totalLevy / 100) * (taxCollection.delinquent / 100));

  // Add new function to handle total calculations and saves
  const saveTotals = async () => {
    if (isInitialLoad) return;
  
    try {
      const updates = {
        [`budget/${districtCode}/${fiscalYear}/revenue/10-5111-0000-00000/budget/${version}`]: {
          budget: currentTaxRevenue
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/10-5112-0000-00000/budget/${version}`]: {
          budget: delinquentTaxRevenue
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/20-5113-0000-00000/budget/${version}`]: {
          budget: Math.round(parseFloat(stateRev.propCamount.replace(/[\$,]/g, "")))
        },
        [`budget/${districtCode}/${fiscalYear}/revenue/20-5311-0000-00000/budget/${version}`] : {
          budget: Math.round(parseFloat(stateRev.basicFormula.replace(/[\$,]/g, "")))
        }
      };
  
      const promises = Object.entries(updates).map(([path, value]) =>
        update(ref(database, path), value)
      );
  
      await Promise.all(promises);
    } catch (error) {
      console.error("Error saving totals:", error);
    }
  };  

  // Add useEffect to watch for changes that affect totals
  useEffect(() => {
    if (!isInitialLoad) {
      saveTotals();
    }
  }, [currentTaxRevenue, delinquentTaxRevenue, stateRev, isInitialLoad]);

  useEffect(() => {
    // Call API to see if this is a K8 or FL school
    async function fetchData() {
      const resp = await axios.post(`${API_BASE_URL}/api/process-spreadsheet`, 
      {districtCode: selectedDistrictInfo.DISTRICT_COUNTY_CODE})
      return resp.data
    }
    
    fetchData().then(result => setK8orFL(result.isDistrictK8orFL))

    const loadData = async () => {
      try {
        const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
        const levyRef = ref(database, `${revenueMetadataPath}/levy`);
        const assessedValueRef = ref(database, `${revenueMetadataPath}/estAssessedValue`);
        const propCRef = ref(database, `${revenueMetadataPath}/propC`);
        const taxCollectionRef = ref(database, `${revenueMetadataPath}/taxCollection`);
        const vocationRef = ref(database, `${revenueMetadataPath}/vocationTuition`);
        const stateRevRef = ref(database, `${revenueMetadataPath}/stateRev`);
        const baselineGrantRef = ref(database, `budget/${districtCode}/${fiscalYear}/20-5341-0000-34100/budget/${version}`);
        const [levySnapshot, assessedValueSnapshot, propCSnapshot, taxCollectionSnapshot, vocationSnapshot, stateRevSnapshot, baselineGrantSnapshot] = await Promise.all([

          get(levyRef),
          get(assessedValueRef),
          get(propCRef),
          get(taxCollectionRef),
          get(vocationRef),
          get(stateRevRef),
          get(baselineGrantRef),
        ]);

  
        if (stateRevSnapshot.exists()){
          const data = stateRevSnapshot.val();
          setStateRev({
            ... data
          })
        }

        if (levySnapshot.exists()) {
          const data = levySnapshot.val();
          setLevy({
            operating: data.GENERAL_FUND || 0,
            teacher: data.SPECIAL_REVENUE || 0,
            debt: data.DEBT_SERVICE_FUND || 0,
            capital: data.CAPITAL_FUND || 0
          });
        }
  
        if (assessedValueSnapshot.exists()) {
          const data = assessedValueSnapshot.val();
          setAssessedValues(Object.keys(data).map((county) => (
            {
            county: county || '',
            values: {
              agriculture: data[county].agriculture || 0,
              residential: data[county].residential || 0,
              commercial: data[county].commercial || 0,
              vacant: data[county].vacant || 0,
              personal: data[county].personal || 0,
              localUtilityReal: data[county].localUtilityReal || 0,
              localUtilityPersonal: data[county].localUtilityPersonal || 0
            }
          })));
        } else {
          setAssessedValues([{
            county: '',
            values: {
              agriculture: 0,
              residential: 0,
              commercial: 0,
              vacant: 0,
              personal: 0,
              localUtilityReal: 0,
              localUtilityPersonal: 0
            }
          }]);
        }

        if (taxCollectionSnapshot.exists()) {
          const data = taxCollectionSnapshot.val();
          setTaxCollection({
            current: data.current || 0,
            delinquent: data.delinquent || 0
          });
        }
  
        if (propCSnapshot.exists()) {
          const data = propCSnapshot.val();
          setPropC({
            rate: data.desePropRate || 0,
            wada: data.priorYearWADA || 0
          });
        }

        if (vocationSnapshot.exists()) {
          const data = vocationSnapshot.val();
          setVocationTuition({
            tuition: data.tuition || 0,
            fullTime: data.fullTime || 0,
            halfTime: data.halfTime || 0,
            lessPerkins: data.lessPerkins || 0,
            lessNonTraditional: data.lessNonTraditional || 0,
            expectedExpense: data.expectedExpense || 0
          });
         }

         if (baselineGrantSnapshot.exists()) {
          const data = baselineGrantSnapshot.val();
          console.log('baselineGrantSnapshot: ', data)
         }

      }
       catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setIsInitialLoad(false);
      }
    };
  
    loadData();
  }, [districtCode, fiscalYear]);
  
  const handleBlur = async (section, data) => {
    const revenueMetadataPath = `revenueMetadata/${districtCode}/${fiscalYear}/${version}`;
    try {
      switch(section) {
        case 'levy':
          await set(ref(database, `${revenueMetadataPath}/levy`), {
            GENERAL_FUND: data.operating,
            SPECIAL_REVENUE: data.teacher,
            DEBT_SERVICE_FUND: data.debt,
            CAPITAL_FUND: data.capital
          });
          break;
  
        case 'assessedValues':
          const assessedValueData = data.reduce((acc, county, index) => {
            console.log('acc, county, index', acc, county, index)
            acc[county.county] = {
              agriculture: county.values.agriculture,
              residential: county.values.residential,
              commercial: county.values.commercial,
              vacant: county.values.vacant,
              personal: county.values.personal,
              localUtilityPersonal: county.values.localUtilityPersonal,
              localUtilityReal: county.values.localUtilityReal
            };
            return acc;
          }, {});
          console.log('assessedValueData', assessedValueData)
          await set(ref(database, `${revenueMetadataPath}/estAssessedValue`), assessedValueData);
          break;

        case 'taxCollection':
            await set(ref(database, `${revenueMetadataPath}/taxCollection`), {
              current: data.current,
              delinquent: data.delinquent
            });
            break;

        case 'vocationTuition':
          await set(ref(database, `${revenueMetadataPath}/vocationTuition`), {
            tuition: data.tuition,
            fullTime: data.fullTime,
            halfTime: data.halfTime,
            lessPerkins: data.lessPerkins,
            lessNonTraditional: data.lessNonTraditional,
            expectedExpense: data.expectedExpense
          });
          break;

        case 'stateRevenue':
          const response = await axios.post("http://localhost:5001/api/process-spreadsheet", 
            {... data,
              districtCode: selectedDistrictInfo.DISTRICT_COUNTY_CODE})
              
          setStateRev(prev => ({...prev, 
                       basicFormula: response.data.basicFormula,
                       classroomTrust: response.data.classroomTrust,
                       propCamount: response.data.propCamount}))

          await set(ref(database, `${revenueMetadataPath}/stateRev`), {
            basicFormula: response.data.basicFormula,
            classroomTrust: response.data.classroomTrust,
            propCamount: response.data.propCamount,
            ... data
          });

          let share_general_fund = (data.CTF_GENERAL_FUND/100)*parseInt(response.data.classroomTrust.replace(/[^\d]/g, ''));
          let share_special_revenue = (data.CTF_SPECIAL_REVENUE/100)*parseInt(response.data.classroomTrust.replace(/[^\d]/g, ''));
          let share_capital_projects = (data.CTF_CAPITAL_PROJECTS/100)*parseInt(response.data.classroomTrust.replace(/[^\d]/g, ''));
          let share_debt_service = (data.CTF_DEBT_SERVICE/100)*parseInt(response.data.classroomTrust.replace(/[^\d]/g, ''));

          // Classroom trust general fund (10)
          await update(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/10-5319-0000-00000/budget/${version}`), {
            budget: share_general_fund
          });

          // Classroom trust special fund (20)
          await update(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/20-5319-0000-00000/budget/${version}`), {
            budget: share_special_revenue
          });

          // Classroom trust debt fund (30)
          await update(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/30-5319-0000-00000/budget/${version}`), {
            budget: share_debt_service
          });

          // Classroom trust capital fund (40)
          await update(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/40-5319-0000-00000/budget/${version}`), {
            budget: share_capital_projects
          });

          await update(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/20-5311-0000-00000/budget/${version}`), {
            budget: parseInt(response.data.basicFormula.replace(/[^\d]/g, ''))
          });

          await update(ref(database, `budget/${districtCode}/${fiscalYear}/revenue/20-5113-0000-00000/budget/${version}`), {
            budget: Math.round(parseFloat(response.data.propCamount.replace(/[^\d.]/g, ''))),
          });
          break;
        
      }
    } catch (error) {
      console.error(`Error saving ${section}:`, error);
    }
  };


  return (
    <div className="card-local-revenue-container">
      <div className="local-property-tax-section">
        <div className="card-local-revenue">
          <h3>Levy</h3>
          <label>Operating Levy</label>
            <input
              type="number"
              value={levy.operating}
              onChange={(e) => handleInputChange(e, setLevy, 'operating')}
              onBlur={() => handleBlur('levy', levy)}
            />
          <label>Teacher Levy</label>
            <input 
              type="number" 
              value={levy.teacher}
              onChange={(e) => handleInputChange(e, setLevy, 'teacher')}
              onBlur={() => handleBlur('levy', levy)}
            />
          <label>Capital Levy</label>
            <input 
              type="number" 
              value={levy.capital}
              onChange={(e) => handleInputChange(e, setLevy, 'capital')}
              onBlur={() => handleBlur('levy', levy)}
            />
          <label>Debt Levy</label>
          <input 
            type="number" 
            value={levy.debt}
            onChange={(e) => handleInputChange(e, setLevy, 'debt')} 
            onBlur={() => handleBlur('levy', levy)}
          />
          <div className="total-local-revenue">Total: {totalLevy.toFixed(4)}</div>
        </div>

        <div className="card-local-revenue">
          <h3>Assessed Value</h3>
          <div className="assessed-values-container">
            {assessedValues.map((county, index) => (
              <div key={index} className="assessed-value-column">
                <label>County Name</label>
                <input
                  type="text"
                  placeholder="County Name"
                  value={county.county}
                  onChange={(e) => {
                    const newState = [...assessedValues];
                    newState[index].county = e.target.value;
                    setAssessedValues(newState);
                  }}
                />
                {Object.keys(county.values).map((key) => (
                  <div key={key}>
                    <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                    <input
                      type="text"
                      placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                      value={county.values[key].toLocaleString('en-US')}
                      onChange={(e) => handleAssessedValueChange(e, index, key)}
                      onBlur={() => handleBlur('assessedValues', assessedValues)}
                    />
                  </div>
                ))}
                <div>Total for {county.county || 'County'}: {formatCurrency(calculateTotal(county.values))}</div>
                <button onClick={() => deleteCounty(index)}>Delete County</button>
              </div>
            ))}
          </div>
          <button style={{ width: 'auto' }} onClick={addCounty}>Add County</button>
          <div className="total-local-revenue">Total: {formatCurrency(totalAssessedValue)}</div>
        </div>

        <div className="card-local-revenue">
          <h3>Tax Collection Ratio</h3>
          <label>Current</label>
          <input
            type="number"
            placeholder="Current"
            value={taxCollection.current}
            onChange={(e) => handleInputChange(e, setTaxCollection, 'current')}
            onBlur={() => handleBlur('taxCollection', taxCollection)}
          />
          <label>Delinquent</label>
          <input
            type="number"
            placeholder="Delinquent"
            value={taxCollection.delinquent}
            onChange={(e) => handleInputChange(e, setTaxCollection, 'delinquent')}
            onBlur={() => handleBlur('taxCollection', taxCollection)}
          />
          <div className="total-local-revenue">Total: {formatPercentage(calculateTotal(taxCollection))}</div>
          <div className="total-local-revenue">Current Tax Revenue: {formatCurrency(currentTaxRevenue)}</div>
          <div className="total-local-revenue">Delinquent Tax Revenue: {formatCurrency(delinquentTaxRevenue)}</div>
        </div>
      </div>

      {K8orFL === 'No' && (
        
        <div className="state-revenue-container">
          <h2> State Revenue Calculator</h2>
          <table className="state-revenue-table">
            <thead>
              <tr>
                <th> </th>
                <th>2022-2023</th>
                <th>2023-2024</th>
                <th>2024-2025</th>
                <th>2025-2026</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Regular Year ADA (K-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearADA_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Summer School ADA (K-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchoolADA_curryear_p1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolADA_curryear_p1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
              </tr>
              <tr>
                <td>Regular Year PK ADA</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.regYearPKADA_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>September Membership (K-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberK12_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK12_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>September Membership (PreK)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.septMemberPreK_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>January Membership (K-12)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMem_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMem_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>January Membership (PreK)</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.januaryMemPreK_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'januaryMemPreK_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Summer School Membership</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.summerSchool_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>F&RL State FTE January Count</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.FRL_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'FRL_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Special Education December Count</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.specEdDec_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'specEdDec_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>LEP October Count</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.LEPOctober_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'LEPOctober_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Assessed Valuation Dec 31</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.assessedValue_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'assessedValue_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Fines, Escheats, and Overplus</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.finesOverplus_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'finesOverplus_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>State Adequacy Target</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.stateAdeq_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeq_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Adjustment Percentage</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.adjustPerc_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'adjustPerc_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.adjustPerc_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'adjustPerc_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.adjustPerc_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'adjustPerc_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Amount per ADA Classroom Trust Fund</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.amountADATrust_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'amountADATrust_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Amount per Combined WAM & WADA Prop C</td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear_m2}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear_m2')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear_m1}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear_m1')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={stateRev.WAMWADAPropC_curryear}
                    onChange={(e) => handleInputChange(e, setStateRev, 'WAMWADAPropC_curryear')}
                    onBlur={() => handleBlur('stateRevenue', stateRev)}
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div className="total-local-revenue">Classroom Trust Total: {stateRev.classroomTrust}</div>
          <div className="total-local-revenue">Basic Formula Total: {stateRev.basicFormula}</div>
          <div className="total-local-revenue">Prop C Total: {stateRev.propCamount}</div>
        </div>
      )}



        {K8orFL==='K8' &&
        <div className="state-revenue-container">
          <div key={0} className="state-revenue-column">
          <h3> ADA & Membership </h3>
            <label>Regular Year ADA (K-8)</label>
            <input
              type="number"
              value={stateRev.regYearADAK8}
              onChange={(e) => handleInputChange(e, setStateRev, 'regYearADAK8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Regular Year ADA (9-12)</label>
            <input
              type="number"
              value={stateRev.regYearADA912}
              onChange={(e) => handleInputChange(e, setStateRev, 'regYearADA912')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Summer School ADA (K-8)</label>
            <div className="summer-school-container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>2024-25</label>
            <input
              type="number"
              value={stateRev.lastSummerSchoolADA_K8}
              onChange={(e) => handleInputChange(e, setStateRev, 'lastSummerSchoolADA_K8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>2025-26</label>
            <input
              type="number"
              value={stateRev.thisSummerSchoolADA_K8}
              onChange={(e) => handleInputChange(e, setStateRev, 'thisSummerSchoolADA_K8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            </div>
            </div>
            <label>Summer School ADA (9-12)</label>
            <div className="summer-school-container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>2024-25</label>
            <input
              type="number"
              value={stateRev.lastSummerSchoolADA_912}
              onChange={(e) => handleInputChange(e, setStateRev, 'lastSummerSchoolADA_912')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>2025-26</label>
            <input
              type="number"
              value={stateRev.thisSummerSchoolADA_912}
              onChange={(e) => handleInputChange(e, setStateRev, 'thisSummerSchoolADA_912')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            </div>
            </div>
            <label>Regular Year PK ADA</label>
            <input
              type="number"
              value={stateRev.regYearPKADA_K8}
              onChange={(e) => handleInputChange(e, setStateRev, 'regYearPKADA_K8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>September Membership K-8</label>
            <input
              type="number"
              value={stateRev.septMemberK8}
              onChange={(e) => handleInputChange(e, setStateRev, 'septMemberK8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>September Membership 9-12</label>
            <input
              type="number"
              value={stateRev.septMember912}
              onChange={(e) => handleInputChange(e, setStateRev, 'septMember912')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>September Membership PreK</label>
            <input
              type="number"
              value={stateRev.septMemberPreK_K8}
              onChange={(e) => handleInputChange(e, setStateRev, 'septMemberPreK_K8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>January Membership K-8</label>
            <input
              type="number"
              value={stateRev.janMemberK8}
              onChange={(e) => handleInputChange(e, setStateRev, 'janMemberK8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>January Membership 9-12</label>
            <input
              type="number"
              value={stateRev.janMember912}
              onChange={(e) => handleInputChange(e, setStateRev, 'janMember912')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>January Membership PreK</label>
            <input
              type="number"
              value={stateRev.janMemberPreK_K8}
              onChange={(e) => handleInputChange(e, setStateRev, 'janMemberPreK_K8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Summer School Membership K-8</label>
            <input
              type="number"
              value={stateRev.summerSchoolK8}
              onChange={(e) => handleInputChange(e, setStateRev, 'summerSchoolK8')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Summer School Membership 9-12</label>
            <input
              type="number"
              value={stateRev.summerSchool912}
              onChange={(e) => handleInputChange(e, setStateRev, 'summerSchool912')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
          </div>
          
          <div key={1} className="state-revenue-column">
          <h3> Other state revenue </h3>
          <label>Special Education December Count</label>
            <input
              type="number"
              value={stateRev.specEdDecCount}
              onChange={(e) => handleInputChange(e, setStateRev, 'specEdDecCount')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>LEP October Count</label>
            <input
              type="number"
              value={stateRev.lepOctoberCount}
              onChange={(e) => handleInputChange(e, setStateRev, 'lepOctoberCount')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Assessed Valuation Dec. 31</label>
            <input
              type="number"
              value={stateRev.assessedValDec31}
              onChange={(e) => handleInputChange(e, setStateRev, 'assessedValDec31')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Fines, Escheats, and Overplus</label>
            <input
              type="number"
              value={stateRev.finesEscheats}
              onChange={(e) => handleInputChange(e, setStateRev, 'finesEscheats')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>State Adequacy Target</label>
            <input
              type="number"
              value={stateRev.stateAdeqTarget}
              onChange={(e) => handleInputChange(e, setStateRev, 'stateAdeqTarget')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
            <label>Adjustment Percentage</label>
            <input
              type="number"
              value={stateRev.adjustmentPercent}
              onChange={(e) => handleInputChange(e, setStateRev, 'adjustmentPercent')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
             <label>$ per ADA Classroom Trust</label>
            <input
              type="number"
              value={stateRev.perADAClassroomTrust}
              onChange={(e) => handleInputChange(e, setStateRev, 'perADAClassroomTrust')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
             <label>$ per WAM&WADA Prop C</label>
            <input
              type="number"
              value={stateRev.perWAMWADAPropC}
              onChange={(e) => handleInputChange(e, setStateRev, 'perWAMWADAPropC')}
              onBlur={() => handleBlur('stateRevenue', stateRev)}
            />
             <div className="total-local-revenue">Classroom Trust Total: {stateRev.classroomTrust}</div>
             <div className="total-local-revenue">Basic Formula Total: {stateRev.basicFormula}</div>
          </div>
          </div>
        }

        <div className="classroom-trust-container">
          <h4>Distribution of Classroom Trust (%)</h4>
          <label>General Fund</label>
          <input
            type="number"
            value={stateRev.CTF_GENERAL_FUND}
            onChange={(e) => handleInputChange(e, setStateRev, 'CTF_GENERAL_FUND')}
            onBlur={() => handleBlur('stateRevenue', stateRev)}
          />
          <label>Special Revenue</label>
          <input
            type="number"
            value={stateRev.CTF_SPECIAL_REVENUE}
            onChange={(e) => handleInputChange(e, setStateRev, 'CTF_SPECIAL_REVENUE')}
            onBlur={() => handleBlur('stateRevenue', stateRev)}
          />
          <label>Debt Service</label>
          <input
            type="number"
            value={stateRev.CTF_DEBT_SERVICE}
            onChange={(e) => handleInputChange(e, setStateRev, 'CTF_DEBT_SERVICE')}
            onBlur={() => handleBlur('stateRevenue', stateRev)}
          />
          <label>Capital Projects</label>
          <input
            type="number"
            value={stateRev.CTF_CAPITAL_PROJECTS}
            onChange={(e) => handleInputChange(e, setStateRev, 'CTF_CAPITAL_PROJECTS')}
            onBlur={() => handleBlur('stateRevenue', stateRev)}
          />
        </div>
       

    </div>
  );
};

export default RevenueCalc;